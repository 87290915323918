import React, { useState } from "react";
import brandLogo from "../../assets/parkingHeroLogo.svg";
import eyeIcon from "../../assets/icons/view.png"
import hideIcon from "../../assets/icons/hide.png"
import { useFormik } from "formik";
import "./styles.scss";
import { changePasswordSchema, changePasswordValidations } from "./validator";
import { AuthService } from "../../services/auth.service";
import { STATUS } from "../../common/constant";
import { ErrorDialog } from "../../components/errorDialog";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/Reducer";
import { SuccessDialogBox } from "../../components/successDialog";
import Loader from "../../components/Loader";
import { Link } from "react-router-dom";

export const ChangePasswordPage: React.FC = () => {
    const [show, setShow] = useState<boolean>(false)
    const [errorDialog, setErrorDialog] = useState<boolean>(false);
    const [message, setMessage] = useState<string>('')
    const authenticationService = new AuthService();
    const [loader, setLoader] = useState<boolean>(false);
    const [oldPassEye, setOldPassEye] = useState<boolean>(false)
    const [newPassEye, setNewPassEye] = useState<boolean>(false)
    const [conPassEye, setConPassEye] = useState<boolean>(false)
    const user_id = useSelector<RootState>((state) => state?.authData?.authData?.user_id.toString()) 

    const changePasswordSubmit = async (values: any): Promise<void> => {
        try {
            setLoader(true)
            const changePassword: any = {
                user_id: user_id,
                old_password: values?.password,
                new_password: values?.newpassword,
                confirm_new_password: values?.confirmPassword
            }
            setShow(true)
            const changePasswordResponse = await authenticationService.ChangePassword(changePassword);
            setLoader(false)
            if (changePasswordResponse.status === STATUS.SUCCESS) {
                setMessage(changePasswordResponse.message)
                setShow(true)
            } else {
                setShow(false)
                setErrorDialog(true)
                setMessage(changePasswordResponse.message)
            }
        }
        catch (error: any) {
            ////showNotification(STATUS.FAILURE, error.message)
        }
    }

    const handleEye = (event: any) => {
        // console.log(event.target.parentElement.parentElement)
        const parentDiv = event.target.parentElement.parentElement;
        // console.log(parentDiv.children.length)
        for (let tag of parentDiv.children) {
            if (tag.className !== "eye-icon") {
                if (tag.type === "password") {
                    tag.type = "text";
                    if (parentDiv.className === "new-pass") {
                        setNewPassEye(false)
                    }
                    else if (parentDiv.className === "con-pass") {
                        setConPassEye(false)
                    }
                    else {
                        setOldPassEye(false)
                    }
                } else {
                    tag.type = "password";
                    if (parentDiv.className === "new-pass") {
                        setNewPassEye(true)
                    }
                    else if (parentDiv.className === "con-pass") {
                        setConPassEye(true)
                    }
                    else {
                        setOldPassEye(true)
                    }
                }
            }
        }
    }

    const registerFormik = useFormik({ initialValues: changePasswordSchema, validationSchema: changePasswordValidations, onSubmit: changePasswordSubmit })
    
    return (
        <div className="change-password-page">
            <div className="logo-wrapper">
                <Link to={"/"}><img src={brandLogo} alt="" /></Link>
            </div>
            <div>
                <h1 className="heading-changePassword">Change Password</h1>
            </div>
            <div className="space-lg"></div>
            <form onSubmit={registerFormik.handleSubmit}>
                <div className="change-form">
                    <div className="old-pass">
                        <input className="change-input" type="password" placeholder="Enter Old Password"
                            {...registerFormik.getFieldProps('password')} />
                        <button className="eye-icon" type="button" onClick={(event: any) => { handleEye(event) }}><img src={oldPassEye ? eyeIcon : hideIcon} alt="icon" /></button>
                        {registerFormik.touched.password && registerFormik.errors.password ? (
                            <p className="warn">{registerFormik.errors.password}</p>
                        ) : null}
                    </div>
                    <br />
                    <div className="new-pass">
                        <input className="change-input" type="password" placeholder="Enter New Password"
                            {...registerFormik.getFieldProps('newpassword')} />
                        <button className="eye-icon" type="button" onClick={(event: any) => { handleEye(event) }}><img src={newPassEye ? eyeIcon : hideIcon} alt="icon" /></button>
                        {registerFormik.touched.newpassword && registerFormik.errors.newpassword ? (
                            <p className="warn">{registerFormik.errors.newpassword}</p>
                        ) : null}
                    </div>
                    <br />
                    <div className="con-pass">
                        <input className="change-input" type="password" placeholder="Confirm New Password"
                            {...registerFormik.getFieldProps('confirmPassword')} />
                        <button className="eye-icon" type="button" onClick={(event: any) => { handleEye(event) }}><img src={conPassEye ? eyeIcon : hideIcon} alt="icon" /></button>
                        {registerFormik.touched.confirmPassword && registerFormik.errors.confirmPassword ? (
                            <p className="warn">{registerFormik.errors.confirmPassword}</p>
                        ) : null}
                    </div>
                </div>
                <div className="space-lg"></div>
                <button className="btn" type="submit">Update Password</button>
            </form>
            <div className="space-lg"></div>
            {/* <Modal message="Password has been updated successfully." modal={modal} setModal={setModal}/>  */}
            {show && <SuccessDialogBox show={show} message={message} setShow={setShow} logout={true} />}
            {errorDialog && <ErrorDialog show={errorDialog} setShow={setErrorDialog} message={message} />}
            {loader && <Loader />}
        </div>
    )
}