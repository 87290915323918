import React, { useState } from "react";
import "./styles.scss";
import closeIcon from "../../assets/icons/x-circle.svg";
import { AuthService } from "../../services/auth.service";
import { STATUS } from "../../common/constant";
import { ErrorDialog } from "../../components/errorDialog";
import { useNavigate } from "react-router-dom";

type Tprop = {
    setShow: (open: boolean) => void;
    handleDeleteItem?: any;
    faqID?: number;
    setLoader?: any;
    apiRerender?: any
};

type T2prop = {
    setShow: any;
    status: string;
    handleClose?: any;
    apiRerender?: any
};

type T3prop = {
    state?: string;
    setShow: (open: boolean) => void;
    handleAdd?: any;
    handleEditItem?: any;
    editData?: any;
    faqID?: any;
    setLoader?: any;
    apiRerender?: any;
};

//delete

export const FAQDialogBox: React.FC<Tprop> = ({
    setShow,
    faqID,
    setLoader,
    apiRerender,
}) => {
    const message3 = "Do you want to delete the FAQ?";
    const [result, setResult] = useState<boolean>(false);
    const authenticationService = new AuthService();
    const [errorDialog, setErrorDialog] = useState(false);
    const [message, setMessage] = useState("");

    const handleClose = () => {
        setShow(false);
    };
    const deleteFaq = async (faqID: any) => {
        try {
            setLoader(true);
            const deleteData = {
                id: faqID,
            };
            const faqDeleteResponse: any =
                await authenticationService.adminFAQDelete(deleteData);
            setLoader(false);
            if (faqDeleteResponse.status === STATUS.SUCCESS) {
                setResult(true);
            } else {
                setErrorDialog(true);
                setMessage(faqDeleteResponse.message);
            }
        } catch (error) {}
    };

    return (
        <div className="faqdialog-box">
            <div className="box">
                <div className="close">
                    <div
                        className="dialog-btn-close"
                        onClick={() => setShow(false)}>
                        <img src={closeIcon} alt="not-found" />
                    </div>
                </div>
                <p className="text"> {message3}</p>
                <div className="faq-btn">
                    <div
                        className="dialog-btn"
                        onClick={() => {
                            setResult(true);
                            deleteFaq(faqID);
                        }}>
                        Yes
                    </div>
                    <div className="dialog-btn" onClick={() => setShow(false)}>
                        No
                    </div>
                </div>
            </div>
            {result && (
                <FAQDialogBoxResult
                    status={"delete"}
                    setShow={setResult}
                    handleClose={handleClose}
                    apiRerender={apiRerender}
                />
            )}
            {errorDialog && (
                <ErrorDialog
                    show={errorDialog}
                    setShow={setErrorDialog}
                    message={message}
                />
            )}
        </div>
    );
};

//result for delete

export const FAQDialogBoxResult: React.FC<T2prop> = ({
    status,
    setShow,
    handleClose,
    apiRerender,
}) => {
    const message1 = "The FAQ has been deleted successfully";
    const message2 = "FAQ has been added successfully";
    const message3 = "FAQ has been updated successfully";
    const navigate = useNavigate();

    return (
        <div className="faqdialog-box">
            <div className="box result">
                <div className="close">
                    <div
                        className="dialog-btn-close"
                        onClick={() => {
                            setShow(false);
                            handleClose();
                            navigate("/admin/faq");
                            apiRerender();
                        }}>
                        <img src={closeIcon} alt="not-found" />
                    </div>
                </div>
                <p className="text">
                    {" "}
                    {status === "add"
                        ? message2
                        : status === "delete"
                        ? message1
                        : status === "edit"
                        ? message3
                        : "Something Went Wrong"}
                </p>
                <div className="faq-btn">
                    <div
                        className="dialog-btn"
                        onClick={() => {
                            setShow(false);
                            handleClose();
                            apiRerender();
                        }}>
                        OK
                    </div>
                </div>
            </div>
        </div>
    );
};

//add & edit

export const FAQAddDialogBox: React.FC<T3prop> = ({
    state,
    setShow,
    handleAdd,
    handleEditItem,
    editData,
    faqID,
    setLoader,
    apiRerender,
}) => {
    const message1 = "Please enter the Question and Answer";
    const message2 = "Please update the Question and Answer";
    const [result, setResult] = useState<boolean>(false);
    const authenticationService = new AuthService();
    const [errorDialog, setErrorDialog] = useState(false);
    const [message, setMessage] = useState("");
    const [question, setQuestion] = useState(
        state === "edit" ? editData.title : ""
    );
    const [answer, setAnswer] = useState(
        state === "edit" ? editData.description : ""
    );
    const handleClose = () => {
        setShow(false);
    };
    const faqPostSubmit = async (e: any) => {
        try {
            setLoader(true);
            e.preventDefault();
            const postValues = {
                question: question,
                answer: answer,
            };
            const faqSendResponse: any =
                await authenticationService.adminFAQPost(postValues);
            setLoader(false);
            if (faqSendResponse.status === STATUS.SUCCESS) {
                setResult(true);
            } else {
                setErrorDialog(true);
                setMessage(faqSendResponse.message);
            }
        } catch (error) {}
    };
    const faqPatchSubmit = async (e: any) => {
        try {
            setLoader(true);
            e.preventDefault();
            const postValues = {
                id: faqID,
                question: question,
                answer: answer,
            };
            const faqSendResponse: any =
                await authenticationService.adminFAQPatch(postValues);
            setLoader(false);
            if (faqSendResponse.status === STATUS.SUCCESS) {
                setResult(true);
            } else {
                setErrorDialog(true);
                setMessage(faqSendResponse.message);
            }
        } catch (error) {}
    };

    return (
        <div className="faqdialog-box">
            <div className="box add-box">
                <div className="close add-close">
                    <p className="add-heading">
                        {" "}
                        {state === "edit" ? message2 : message1}
                    </p>
                    <div
                        className="dialog-btn-close"
                        onClick={() => setShow(false)}>
                        <img src={closeIcon} alt="not-found" />
                    </div>
                </div>
                <form
                    onSubmit={state === "edit" ? faqPatchSubmit : faqPostSubmit}
                    style={{ width: "100%" }}>
                    <div className="add-form">
                        <div className="add-form-field">
                            <p className="add-form-label"> Question:</p>
                            <input
                                className="add-form-input"
                                id="question"
                                name="question"
                                type="text"
                                onChange={(e: any) =>
                                    setQuestion(e.target.value)
                                }
                                value={question}
                            />
                        </div>
                        <div className="add-form-field">
                            <p className="add-form-label"> Answers:</p>
                            <input
                                className="add-form-input"
                                type="textarea"
                                id="answer"
                                name="answer"
                                onChange={(e: any) => setAnswer(e.target.value)}
                                value={answer}
                            />
                        </div>
                    </div>
                    <div className="faq-btn add-btn">
                        <button className={answer && question ? "dialog-btn" : "dialog-btn dialogueDisabled"} type="submit">
                            {state === "edit" ? "Update" : "Save"}
                        </button>
                    </div>
                </form>
            </div>
            {result && (
                <FAQDialogBoxResult
                    status={state ? "edit" : "add"}
                    setShow={setResult}
                    handleClose={handleClose}
                    apiRerender={apiRerender}
                />
            )}
            {errorDialog && (
                <ErrorDialog
                    show={errorDialog}
                    setShow={setErrorDialog}
                    message={message}
                />
            )}
        </div>
    );
};
