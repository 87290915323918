import { useState } from "react"
import { EmailPage } from "./EmailPage"
import { OtpPage } from "./OtpPage"
import { PasswordPage } from "./PasswordPage"
export const ForgotPasswordPage = () => {

    const [email, setEmail] = useState<string>("")
    const [otpVerified, setOtpVerified] = useState<boolean>(false)
    
    return (
        <>
            {!email.length && <EmailPage setEmail={setEmail} />}
            {email.length > 0 && !otpVerified && <OtpPage email={email} setOtpVerified={setOtpVerified} />}
            {otpVerified && <PasswordPage email={email} />}
        </>
    )
}