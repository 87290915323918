export const BASE_URL = "https://lbparkinghero.com/api/";
export const STATUS = {
    SUCCESS: "success",
    FAILURE: "failure"
};

export const gofundme = "https://gofund.me/ad8bcae3"
export const storeToParkingHero = "https://www.parkinghero.net/store-2-1"

export const Email_not_verified = {
    heading: "E-Mail Not Verified Popup Content",
    content: "Please verify your email to continue",
    linkContent: "Click here to send verification Mail"
}

export const Mobile_not_verified = {
    heading: "(Mobile Number Not Verified Popup Content",
    content: "Please verify your mobile number to continue ",
    linkContent: "Click here to send SMS Validation Message"
}

export const not_verified = {
    heading: "E-Mail and Mobile Number Not Verified Popup Content",
    content: "Please verify your E-Mail to continue ",
    linkContent: "Click here to send verification Mail",
    MobileLinkContent: "Click here to send SMS Validation Message"
}

export const OPTIONS = {
    heading1: "Report misuse of Parking Hero",
    message1: "My account has been misused by someone.Kindly block my Account",
    heading2: "I am having problems with my account ",
    message2: "I am unable to login to my Account",
    heading3: "I am having problems setting up my account ",
    message3: "I am not getting SMS,email in the registered Mail and Mobile Number while registering my Account.",
    heading4: "I would like to learn more about how I can use Parking Hero for my business ",
    message4: "Can you let me know the ways in which I can use Parking Hero for my Business",
    heading5: "Others",
    message5: "",

}

export const timeForAction_OPTIONS = {
    message1: "Street Sweeping",
    message2: "Expired Parking Meter",
    message3: "Car is being towed",
    message4: "Car is blocking path of travel",
    message5: "Car Interior/headlight left on",
    message6: "Accident*",
    message7: "Break-in*",
    message8: "Hit-and-Run*",
    message9: "Other*",
    message10:"Your work here is done. Thanks for using Parking Hero!",
}

export const numerOfSuccessFull_Options = [
    { message: "1  Successful Parking Hero Mission", value: 1 },
    { message: "5  Successful Parking Hero Missions", value: 5 },
    { message: "10 Successful Parking Hero Missions ", value: 10 },
    { message: "15 Successful Parking Hero Missions", value: 15 },
];
export const POLICY_CONTENT = {
    message1: "To sign up, please review and agree to terms of use by checking box",
    message2: `Every kid has his own vision of doing something unique in life. Sometimes parents also dream of 
    their kids to be at high professions like doctors, engineers, IAS or PCS officers, or any other high-level 
    professions. All such goals of kids or parents can be achieved by education only.In this competitive era, 
    everyone must have a good education and sound knowledge to achieve goals of life. Education does not only offer 
    a good job but also enhances the ability to understand life from a new perspective. Decent education produces 
    many paths to move ahead in life. It makes us intellectually, and ethically, powerful by improving our expertise 
    level, technical abilities, and excellent job.Also, some children are interested in other areas such as sports, 
    dance, music, and many more, they can do their additional education with their related degree, experience, 
    talents, and spirit. In India, there are various boards of education available like state wise boards 
    (Gujarat board, UP board, etc.), ICSE Board, CBSE Board, etc. And education is available in various languages 
    like a kid can study in their mother tongue or in Hindi medium or in English medium, it is the choice of parents 
    or kid to select a board or a language. This is the age where Education is very important and with the help of 
    it, anyone can change his/her life in a better way.`
}

export const ADMIN_SUCCESS_POP_UP = {
    message1: `The Message has been send successfuly`,
    message2: `The Broadcast Message has been send successfuly`,
    message3: `Hero "$" is successfuly blocked`,
}

export const DIALOG_CONTENT = {
    message1: `Do you really want to # <br /> Hero "David Cooper" ?`,
}

export const MESSAGE_BOX_HEADER = {
    message1: `Enter the Message`,
    message2: `Enter the Broadcast Message`,
}

export const DIALOG_BOX_CONTENT = {
    heading: "...ALMOST done!",
    message1: "Hero name,Phone or Email ID already exists for another user.Kindely enter unique hero name,Mobile Number and Mail id to proceed.",
    // const message2 = "Just verify your account by replaying to our text message and clicking the link in the email we just sent you. After that, you'll be a certifed Parking Hero.
    message2: "You should receive a text and a email from Parking&nbsp;Hero verifying your account.",
    message3: "Congratulations, your account has been successfully created. Now you can download your QR code from Your ",
    link1: "Click Here To login",
    link2: "Account Page",
}

export const DELETE_CONTENT ={
    deleteConfirmationMessage:'Are You Sure want to Delete Hero?',
    deleteMessage:'Hero Deleted Successfully',
}
export const DELETE_OFFER ={
    deleteConfirmationMessage:'Are You Sure want to Delete Offer?',
    deleteMessage:'Offer Deleted Successfully',
}

export const FILTERVALUES = [
    "YES",
    "NO",
    "BOTH"
]

export const TYPES = ["image/png", "image/jpeg", "image/GIF","video/mp4", "video/mov" ,"video/wmv", "video/webm"]

// eslint-disable-next-line no-useless-escape
export const emailRegex = /^([a-z0-9_\.\-])+\@(([a-z0-9\-])+\.)+([a-z0-9]{2,4})+$/
