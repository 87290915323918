import React from "react";
import "./style.scss";
import Icon from "../Icon";
import { faClose } from "@fortawesome/free-solid-svg-icons";
import { AuthService } from "../../services/auth.service";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/Reducer";
import { STATUS } from "../../common/constant";

type Tprop = {
    setShowDialog: any
    // setCurrentState: any
    isCurrentState: boolean
    // showDialog: boolean
    // displayContent?: string
    heroName: string
    userId?: string
    setSuccerrPopUp?: any
    setMessage?: any
    setCurrentState?: any
}

export const BlockDialog: React.FC<Tprop> = ({  setShowDialog, isCurrentState,setSuccerrPopUp, setMessage , heroName,userId,setCurrentState }) => {
    const authenticationService = new AuthService();
    const adminId = useSelector<RootState>((state) => state?.authData?.authData?.user_id);

    const blockapi = async () => {
        try {
            const blockData: any = {
                user_id: userId,
                admin_id: adminId,
                block: !isCurrentState
            }
            const blockResponse: any = await authenticationService.adminBlockUser(blockData)
            setCurrentState(!isCurrentState);
            if (blockResponse.status === STATUS.SUCCESS) {
                setMessage(`Hero ${heroName} has ${isCurrentState ? "Unblocked" : "Blocked"} successfully`);
            } else {
                setMessage(blockResponse.message)
            }
            setSuccerrPopUp(true);
        } catch (error) {
            
        }
    }
    return (
        <div className="blocking-confirmation-dialog">
            <div className="blocking-confirmation">
                <p>
                    Do you really want to {!isCurrentState ? "block" : "unblock" } <br /> Hero "{heroName}" ?
                </p>
                <div className="closeIcon" onClick={() => {setShowDialog(false)}}>
                    <Icon icon={faClose} />
                </div>
                <div className="confirmation-buttons-wrapper">
                    <button onClick={() => {setShowDialog(false)}}>No</button>
                    <button onClick={() => {
                        setShowDialog(false)
                        blockapi()
                    }}>Yes</button>
                </div>
            </div>
        </div>
    )
}