import React from "react";
import "./style.scss";
import Icon from "../Icon";
import { faClose } from "@fortawesome/free-solid-svg-icons";



type Tprop = {
    show: boolean
    setShow: (open: boolean) => void
    message: string
}

export const AdDialogBox: React.FC<Tprop> = ({ show, setShow, message }) => {
    
    const handleOk = () => {
        setShow(false)
    }

    return (
        <div className="forgot2-box-emailref">
            <div className="box2">
                <div className="close">
                    <div className="dialog-btn-close" onClick={() => { setShow(false) }}>
                    <Icon icon={faClose} />
                    </div>
                </div>
                <p className="text">{message}</p>
                <button className="dialog-btn" onClick={handleOk}>OK</button>
            </div>
        </div>
    );
};