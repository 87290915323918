/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Navbar } from "../../components/navbar";
import Arrow from "@material-ui/icons/ArrowBack"
import { RefDialogBox } from "../../components/refDialog";
import { ImgModalBox } from "../../components/imgModal";
import "./styles.scss";
import { useNavigate, useLocation } from "react-router-dom";
import { AuthService } from "../../services/auth.service";
import { STATUS } from "../../common/constant";
import Pagination from "../../components/Pagination";
import Loader from "../../components/Loader";
import jsPDF from "jspdf";
import autoTable from "jspdf-autotable";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/Reducer";
import LogCard from "../../components/PHRescueLog";

// type TProp = {
//     userid: string
//     username: string
// }
export const UserManagement: React.FC = () => {

    const [page, setPage] = useState<number>(1);
    const [data, setData] = useState<any>();
    const [heroStat, setHeroStat] = useState<any>()
    const [limit] = useState<number>(5);
    const [show, setShow] = useState<boolean>(false)
    const [showImg, setShowImg] = useState<boolean>(false)
    const [img, setImg] = useState<any | null>(null)
    const [videoSrc, setVideoSrc] = useState<any | null>(null);
    const authenticationService = new AuthService();
    const [count, setCount] = useState<number>(0)
    const [loader, setLoader] = useState<boolean>(false)
    const location: any = useLocation()
    const [rescueData, setRescueData] = useState<any>('')
    const [width, setWidth] = React.useState<number>(window.innerWidth);
    const userData: any = useSelector<RootState>((state) => state?.authData?.authData)

    const getAccount = async () => {
        try {
            setLoader(true)
            const accountResponse: any = await authenticationService.getAccount(location.state.user_id, page, limit);
            if (accountResponse.status === STATUS.SUCCESS) {
                setData(accountResponse.data.account_details)
                setHeroStat(accountResponse.data.herostat_details)
                setCount(accountResponse.data.num_page || 1)
                setLoader(false)
            }
        }
        catch (error) {

        }
    }

    useEffect(() => {
        getAccount()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [page])

    const navigate = useNavigate()

    const handleIncident = (eve: any, ele: any) => {
        if (!!eve.target?.src) {
            //pass
        } else {
            navigate("/admin/incidentDetails", { state: ele })
        }
    }

    const handleImgModal = () => {
        setShowImg(true)
    }
    // exporting logs
    // const exportRescue1 = () => {
    //     const input = document.getElementById("exportLog") as HTMLElement;
    //     html2canvas(input)
    //     .then((canvas: { toDataURL: (arg0: string) => any; }) => {
    //         const imgData = canvas.toDataURL('image/png');
    //         const pdf = new jsPDF('p', 'pt', 'letter', true);
    //         pdf.addImage(imgData, 'JPEG', 20, 20, 570, 250);
    //         pdf.save(`RescueLog-${location.state.hero_name}.pdf`);
    //     });
    // }
    const exportRescue = async () => {
         const accountResponse: any = await authenticationService.getAccount(location.state.user_id, 1, 20);
         setRescueData(accountResponse.data?.account_details?.length !== 0 && accountResponse.data.account_details);
            if (accountResponse.status === STATUS.SUCCESS) {
                const doc = new jsPDF();
                let arrayData = [];
                for (const data of rescueData) {
                    arrayData.push([
                        data.incident_details,
                        // data.attachments ? data.attachments : "no attachments",
                        data.comments ? data.comments : "no comments",
                        new Date(data.time).toLocaleDateString(),
                        data.reported_by_id,
                        data.status.replace("_", " "),
                        data.location,
                    ]);
                }
                if (rescueData.length) {
                    autoTable(doc, {
                        margin: 5,
                        head: [["Incident Details", "Comments", "Date", "Reported by", "Mission Status", "Address"]],
                        body: arrayData,
                        columnStyles: { 1: { cellWidth: 50 } },
                        headStyles: {
                            fontStyle: "bold",
                            font: "Helvetica",
                            fillColor: "#000000",
                            textColor: "#FFFFFF",
                            halign: "center",
                            valign: "middle",
                            //  cellPadding: 4,
                            fontSize: 8,
                        },
                        bodyStyles: {
                            halign: "center",
                            valign: "middle",
                            //  cellPadding: 4,
                            fontSize: 8,
                        },
                    });
                    doc.save(`${location.state.hero_name}-RescueLog.pdf`);
                }
            }
     };

    useEffect(() => {
        getAccount()
        exportRescue();
        window.scrollTo(0, 0);

        const handleResizeWindow = () => setWidth(window.innerWidth);
        window.addEventListener("resize", handleResizeWindow);
        return () => {
        window.removeEventListener("resize", handleResizeWindow);
        };

    }, [])

    return (
        <>
            <div className="user-management-page">
                <Navbar noAds Admin={true} />
                <h5 className="account-heading">USER MANAGEMENT</h5>
                <div className="account-con">
                   <div className="table-container" >
                    <div className="table-header-wraper">
                            <h5 className="account-table-heading">{location.state.hero_name} Rescue Log:</h5>
                        </div>
                           {width < 585 ? 
                           <>
                            {data?.length !== 0 ? <>
                       {data?.map((element:any, index:any)=>{
                            return(
                                 <LogCard data={element} key={index} setImg={setImg} handleImgModal={handleImgModal} setVideoSrc={setVideoSrc} onClick={(event:any)=> handleIncident(event,element)}/>
                            )
                        })}
                        </> :<LogCard data={undefined}   isNoRecords={true}/>}
                           </>:
                           <>
                            <div className="tables-responsive" id="exportLog">
                            <table className="table-wraperr">
                                <thead className="head-wraper">
                                    <tr>
                                        <th style={{ width: "15%" }} ><p>Incident Details</p></th>
                                        <th style={{ width: "15%" }}><p>Attachments</p></th>
                                        <th style={{ width: "15%" }}><p>Comments</p></th>
                                        <th style={{ width: "10%" }}><p>Date</p></th>
                                        <th style={{ width: "15%" }}><p>Reported by</p></th>
                                        <th style={{ width: "15%" }}><p>Mission Status</p></th>
                                        <th style={{ width: "15%" }}><p>Address</p></th>
                                    </tr>
                                </thead>
                                {data ? <> {data?.map((element: any, index: number) => {
                                    return (
                                        <tbody
                                            key={index}
                                            id={`${index}`}
                                            onClick={(event: any) => { handleIncident(event, element) }}
                                            className="body-wraper" style={index % 2 ? { backgroundColor: "#F1F0F0", cursor: "pointer" } : { backgroundColor: "white", cursor: "pointer" }}
                                        // onMouseOver={(event) => {console.log(event.target)}}
                                        >
                                            <tr style={{ width: "15%", display: "flex", justifyContent: "center" }}><td>{element.incident_details}</td></tr>
                                            <tr style={{ width: "15%", display: "flex", justifyContent: "center" }}>
                                                <td>
                                                    {element.attachments ?
                                                    element.attachments.includes("videos") ? 
                                                    <video src={element.attachments} className="attachment-img" onClick={() => { setVideoSrc(element.attachments); handleImgModal(); }} /> :
                                                        <img src={element.attachments} className="attachment-img" onClick={() => { setImg(element.attachments); handleImgModal(); }} alt="Not found" />
                                                        : <p>No Attachments Provided</p>
                                                    }
                                                </td>
                                            </tr>
                                            <tr style={{ display: "flex", justifyContent: "center", width: "15%" }}><td className="elementTd">{element.comments ? element.comments : <p>No comments</p>}</td></tr>
                                            <tr style={{ display: "flex", justifyContent: "center", width: "10%" }}><td>{new Date(element.time).toLocaleDateString()}</td></tr>
                                            <tr style={{ display: "flex", width: "15%", justifyContent: "center" }}><td>{element?.reported_by_id === userData?.hero_name ? "Me" : element?.reported_by_id}</td></tr>
                                            <tr style={{ display: "flex", width: "15%", justifyContent: "center" }}><td>{element.status.replace("_"," ")}</td></tr>
                                            <tr style={{ display: "flex", width: "15%", justifyContent: "center" }}><td>{element.location}</td></tr>
                                        </tbody>
                                    )
                                })} </> : <tbody><tr><td colSpan={7}>No Records</td></tr></tbody>}
                            </table>
                        </div>
                           </>}
                       
                        <div className="pagiContainer"><button className="btn" onClick={() => exportRescue()}>Export Rescue Log</button><Pagination setPage={setPage} page={page} count={count} /></div>
                        <div className="table-header-wraper">
                            <h5 className="account-table-heading" style={{ fontWeight: 700 }}>{location.state.hero_name} Stats:</h5>
                        </div>
                        <div className="tables-responsive">
                       <   table className={width < 585 ? "table-wraperr active" : "table-wraperr"}>
                            <thead>
                                <tr className="head-wraper">
                                    <th style={{ width: "25%" }} ><p>Hero Status</p></th>
                                     {width > 585 && <th style={{ width: "50%" }}></th>}
                                    <th style={{ width: "25%",maxWidth: "250px" }}><p>Number</p></th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr className="body-wraper" style={{ backgroundColor: "white", width: "100%" }}>
                                    <td className="body-content" style={{ display: "flex", justifyContent: "center", width: "25%" }} >
                                        <p>Total Parking Hero Missions</p>
                                    </td>
                                      {width > 585 &&<td style={{ width: "50%" }}></td>}
                                    <td style={{ textAlign: "center", width: "25%",maxWidth: "250px" }}>
                                        <p>{heroStat ? heroStat?.total_missions : 0}</p>
                                    </td>
                                </tr>
                                <tr className="body-wraper" style={{ backgroundColor: "#F1F0F0" }}>
                                    <td className="body-content" style={{ display: "flex", justifyContent: "center", width: "25%" }}>
                                        <p>Successful Parking Hero Missions</p>
                                    </td>
                                     {width > 585 && <td style={{ width: "50%" }}></td>}
                                    <td style={{ width: "25%",maxWidth: "250px" }}>
                                        <div className="sub-table" >
                                            <div className="sub-table-body">
                                                <table>
                                                    <tbody>
                                                        <tr style={{ width: "50%" }}><td>Send </td>
                                                            <td><Arrow style={{ transform: "rotate(90deg)" }} /></td><td>{heroStat ? heroStat.sent : 0}</td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                            <div className="sub-table-body">
                                                <table>
                                                    <tbody>
                                                        <tr style={{ width: "50%" }}><td>Received </td>
                                                            <td><Arrow style={{ transform: "rotate(-90deg)" }} /> </td><td>{heroStat ? heroStat.recieved : 0}</td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                                <tr className="body-wraper" style={{ backgroundColor: "white" }}>
                                    <td className="body-content" style={{ display: "flex", width: "25%", justifyContent: "center" }} >
                                        <p>Referrals </p>
                                    </td>
                                      {width > 585 &&<td style={{ width: "50%" }}></td>}
                                    <td style={{ width: "25%",maxWidth: "250px" }}><p>{heroStat ? heroStat.referals : 0}</p></td>
                                </tr>
                            </tbody>
                            </table>
                        </div>
                    </div >
                </div >
                <div className="add-con">
                               SPACE FOR GOOGLE AD SENSE AD
                </div>
                {show && <RefDialogBox show={show} setShow={setShow} />}
                {showImg && <ImgModalBox show={showImg} setShow={setShowImg} img={img} videoSrc={videoSrc} setImg={setImg} setVideoSrc={setVideoSrc} />}
                {loader && <Loader />}
            </div >
        </>
    )
}