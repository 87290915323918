import * as Yup from 'yup'

export const forgotPasswordSchema = {
  password: "",
  confirmPassword:""
}

// eslint-disable-next-line
const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#\$%\^&\*])(?=.{8,})/
export const forgotPasswordValidations = Yup.object().shape({
  password: Yup.string().trim().required('Please enter password').matches(passwordRegex, "Password Should contain a minimum of 8 characters which includes 1 Upper case, 1 Lower case, 1 special character, 1 number"),
  confirmPassword: Yup.string().trim().required('Please enter confirm password').oneOf([Yup.ref('password'), null], 'Passwords must match')
})

