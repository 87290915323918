import React, { useEffect } from 'react';

const GoogleAdComponent = () => {
  useEffect(() => {
    const script = document.createElement('script');
    script.src = "https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-9164437536844743";
    script.crossOrigin = "anonymous";
    script.async = true;
    document.head.appendChild(script);

    return () => {
      document.head.removeChild(script);
    };
  }, []);

  return <ins className="adsbygoogle" data-ad-client="ca-pub-9164437536844743" />;
};

export default GoogleAdComponent;
