import React from "react";
import "./styles.scss";
import completedIcom from "../../assets/icons/completed.png"
import closeIcon from "../../assets/icons/x-circle.svg"
import { Link, useNavigate } from "react-router-dom";
import checkedBox from "../../assets/svg/checkedBox.svg"
import { setAuthData } from "../../redux/Actions/actions";
import { useDispatch } from 'react-redux';

type Tprop = {
    status: string
    show: boolean
    setShow: (open: boolean) => void
    errorMessage?: string
    qrUrl?: string | null
}

export const EmailVerifyDialogBox: React.FC<Tprop> = ({ status, show, setShow, errorMessage, qrUrl}) => {
    const dispatch = useDispatch();
    const navigate = useNavigate()

    const close = () => {
        setShow(false);
        dispatch(setAuthData(undefined));
        localStorage.clear();
        navigate("/login");
    }


    const message1 = errorMessage || "Hero name,Phone or Email ID already exists for another user.Kindely enter unique hero name,Mobile Number and Mail id to proceed."
    const link1 = "Click Here To login"
    const link2 = "Account Page"
    const mobileMessage = `Please be sure to verify your phone number as well by replying "Y" or "YES"  to our text`

    return (
        <div className="emailverify-dialogbox">
            <div className="box">
                <div className="close">
                    <div className="dialog-btn-close" onClick={() => close()}>
                        <img src={closeIcon} alt="not-found" />
                    </div>
                </div>
                {status === "waiting" ? <img className="" src={checkedBox} alt={"not found"} /> : status === "success" && <img className="dialog-heading-icon" src={completedIcom} alt={"not found"} />}
                <p className="text">{message1}
                    {status === "success" ?
                        <Link to="/account" className="dialog-link">{link2}</Link>
                        : status === "waiting" ?
                            <div><p>{mobileMessage}</p><br ></br><p className="dialog-link" onClick={()=>navigate('/login', { state: { qrUserId: qrUrl} })} >{link1}</p></div>
                            : ""}
                </p>
                {status === "error" && <div className="dialog-btn" onClick={() => close()}>OK</div>}
            </div>
        </div>
    );
};