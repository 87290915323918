import { Checkbox, FormControlLabel } from "@mui/material";
import React, { useState, useEffect } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { useFormik } from 'formik';
import { loginSchema, loginValidations } from "./validator";
import { AccountVerifyDialogBox } from "../../components/accountVerificationDialog/index";
import brandLogo from "../../assets/parkingHeroLogo.svg";
import { AuthService } from "../../services/auth.service";
import "./styles.scss";
import TextFieldLogin from "../../components/TextFieldLogin";
import { STATUS } from "../../common/constant";
import { ErrorDialog } from "../../components/errorDialog";
import { useDispatch } from "react-redux";
import { setAuthData } from "../../redux/Actions/actions";
import Loader from "../../components/Loader";
import { RegisterBox } from "../../components/socialLoginDetail";
import { DialogBox } from "../../components/dialogBox";
import Social from "../../components/sl";

export const LoginPage: React.FC = () => {

    const [email, setEmail] = useState<string>('')
    const [show, setShow] = useState<boolean>(false);
    const [errorDialog, setErrorDialog] = useState<boolean>(false);
    const [contentToDisplay, setContentToDisplay] = useState<any>({});
    const authenticationService = new AuthService();
    const [loader, setLoader] = useState<boolean>(false);
    const [showRegister, setShowRegister] = useState<boolean>(false)
    const[showConfirm,setShowConfirm]=useState<boolean>(false)
    const [dialogueErrorMsg,setDialogueErrorMsg] = useState<string>("");
    const [dialogueStatus,setDialogueStatus] = useState<string>("");
    const [socialMail,setSocialmail] = useState<string>()
    const dispatch = useDispatch();
    const navigate = useNavigate()
    const location: any = useLocation();
    const [firstTimeLogin, setFirstTimeLogin] = useState<boolean>(false)
    const [userName, setUserName] = useState<string>("Hero");
    const [loginDetails, setLoginDetails] = useState<any>()

    const loginSubmit = async (values: any) => {
        try {
            setLoader(true)
            const logindata: typeof loginSchema = {
                email: values?.email.toLowerCase(),
                password: values?.password
            }
            setEmail(values?.email.toLowerCase());
            const loginResponse: any = await authenticationService.Login(logindata);
            loginResponse && setLoader(false)
            if (loginResponse.status === STATUS.SUCCESS) {
                setFirstTimeLogin(loginResponse.data.first_login)
                setUserName(loginResponse?.data?.hero_name);
                if (!loginResponse.data.first_login) {
                    dispatch(setAuthData(loginResponse.data));
                }
                else if(loginResponse.data.first_login===true && location?.state?.qrUserId){
                    dispatch(setAuthData(loginResponse.data));
                }
                else if(loginResponse.data.first_login===true){
                    setShow(true)
                }
                setLoginDetails(loginResponse.data)
                localStorage.setItem("accessToken", loginResponse.data.token.access)
                localStorage.setItem("refreshToken", loginResponse.data.token.refresh)
                if (location?.state?.qrUserId) {
                    const URL = "/timeForAction/" + location?.state?.qrUserId;
                    navigate(URL)
                    return
                }
            } else {
                if (loginResponse.data?.is_email_verified !== undefined && loginResponse.data?.is_phone_verified !== undefined) {
                    if (!loginResponse.data?.is_email_verified && loginResponse.data?.is_phone_verified) {
                        setContentToDisplay({ email: true })
                        setDialogueErrorMsg("Email is not verified")
                        setShow(true);
                        return
                    }
                    else if (!loginResponse.data?.is_phone_verified && loginResponse.data?.is_email_verified) {
                        setContentToDisplay({ mobile: true })
                        setDialogueErrorMsg("Number is not verified")
                        setShow(true);
                        return
                    }
                    else if (!loginResponse.data?.is_email_verified && !loginResponse.data?.is_phone_verified) {
                        setContentToDisplay({ email_mobile: true })
                        setShow(true);
                        return
                    }
                }
                else {
                    setContentToDisplay(loginResponse.message)
                    setDialogueErrorMsg("Number is not verified")
                    setErrorDialog(true)
                }
            }
        }
        catch (error: any) {
            //showNotification(STATUS.FAILURE, error.message)
            setLoader(false)
            console.log('login fail', error)

        }
    }
    const socialLogin=async(payload:any)=>{

        try{
            if(!payload.email){
                setErrorDialog(true)
                setContentToDisplay("Your Account doesn't have a email Please use a account with email")
            }
            else{ 
            const loginResponse=await authenticationService.SocialLogin(payload)
            if (loginResponse.status === STATUS.SUCCESS) {
                setFirstTimeLogin(loginResponse.data.first_login)
                setUserName(loginResponse?.data?.hero_name);
                if (!loginResponse.data.register) {
                    setDialogueStatus("waiting")
                    setShowRegister(true)
                }
                else if (!loginResponse.data.first_login) {
                    dispatch(setAuthData(loginResponse.data));
                }
                else if(loginResponse.data.first_login===true){
                    setDialogueStatus("error")
                    setShow(true)
                }
                setSocialmail(loginResponse?.data?.email)
                // setShowRegister(true)
                setLoginDetails(loginResponse.data)
                localStorage.setItem("accessToken", loginResponse.data.token.access)
                localStorage.setItem("refreshToken", loginResponse.data.token.refresh)
                if (location?.state?.qrUserId) {
                    const URL = "/timeForAction/" + location?.state?.qrUserId;
                    navigate(URL)
                    return
                }
            } else {
                if (loginResponse.data?.is_email_verified !== undefined && loginResponse.data?.is_phone_verified !== undefined) {
                    if (!loginResponse.data?.is_email_verified && loginResponse.data?.is_phone_verified) {
                        setContentToDisplay({ email: true })
                        setShow(true);
                        return
                    }
                    else if (!loginResponse.data?.is_phone_verified && loginResponse.data?.is_email_verified) {
                        setContentToDisplay({ mobile: true })
                        setShow(true);
                        return
                    }
                    else if (!loginResponse.data?.is_email_verified && !loginResponse.data?.is_phone_verified) {
                        setContentToDisplay({ email_mobile: true })
                        setShow(true);
                        return
                    }
                }
                else {
                    
                    setContentToDisplay(loginResponse.message)
                    setErrorDialog(true)
                }
            }}
        }
        catch(err:any){
            console.log(err.message)
        }
        }
    const loginFormik = useFormik({ initialValues: loginSchema, validationSchema: loginValidations, onSubmit: loginSubmit })

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <>
            <div className="login-page">
                <div className="logo-wrapper">
                    <Link to={"/"} > <img src={brandLogo} alt="" /></Link>
                </div>
                <div className="loginSec">
                    <h1 className="heading heading-space space-reduce">Login</h1>
                    <h4 className="text-xl ">Welcome to Parking Hero!</h4>
                    <Social signin={(val:any)=>socialLogin(val)}/>
                    {/* <div className="social-login-icons"> */}
                        {/* <GoogleLogin id={"1004683485189-htpprl9r4t0544pk4fvnqoohtqs2rqv8.apps.googleusercontent.com"} login={(val:any)=>socialLogin(val)}/> */}
                        {/* <FaceBookLogin id={'5153119011450538'} login={(val: any) => socialLogin(val)} /> */}
                    {/* </div>
                    <h6 className="orStyles">( OR )</h6> */}
                    <div className="space-lg"></div>
                    <div className="login-form">
                        <form onSubmit={loginFormik.handleSubmit}>
                            <div className="main-container">
                                <TextFieldLogin
                                    label='Email'
                                    formik={{ ...loginFormik.getFieldProps('email') }}
                                    error={loginFormik.touched.email && loginFormik.errors.email}
                                    errorText={loginFormik.errors.email}
                                />
                                <TextFieldLogin
                                    type='password'
                                    label='Password'
                                    formik={{ ...loginFormik.getFieldProps('password') }}
                                    error={loginFormik.touched.password && loginFormik.errors.password}
                                    errorText={loginFormik.errors.password}
                                />
                                <div className="forgot-pass">
                                    <p onClick={() => { navigate("/forgotPassword") }} id="forgot-pass" className="forgot-pass forgot-label">Forgot password ?</p>
                                </div><br />
                            </div>
                            <div className="check-box">
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            sx={{
                                                '& .MuiSvgIcon-root': { fontSize: 26 },
                                                color: "#ffffff",
                                                '&.Mui-checked': {
                                                    color: "#ffffff",
                                                }
                                            }}
                                        />
                                    }
                                    label="Keep me logged in"
                                    sx={{
                                        fontWeight: 600,
                                        color: "#ffff",
                                    }}
                                />
                            </div><br />
                            <button type="submit" className="btn">Login</button>
                        </form>
                        <div className="or">
                            <p className="or-option">or</p>
                        </div>
                        <button className="btn" onClick={() => { navigate("/signup") }} >Signup</button>
                    </div>
                </div>
                {showConfirm && <DialogBox status={dialogueStatus} show={showConfirm} setShow={setShowConfirm} errorMessage={dialogueErrorMsg} userId={URL} email={email} sl={false}/>}
                {show && <AccountVerifyDialogBox loginDetails={loginDetails} show={show} setShow={setShow} contentToDisplay={contentToDisplay} email={email} firstTimeLogin={firstTimeLogin} setFirstTimeLogin={setFirstTimeLogin} userName={userName} />}
                {errorDialog && <ErrorDialog show={errorDialog} setShow={setErrorDialog} message={contentToDisplay} forLogin={true} />}
                {loader && <Loader />}
                {showRegister && <RegisterBox setShow={setShowRegister} show={showRegister} email={socialMail} setlogin={setShowConfirm}/>}
            </div>
        </>
    );
};