/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
// import googleIMG from "../../assets/icons/googleAD.png"
import "./styles.scss";
import { STATUS } from "../../common/constant";
import { AuthService } from "../../services/auth.service";
import { ProfileDialogBox } from "../../components/profileDialog";
import { ErrorDialog } from "../../components/errorDialog";
import { useFormik } from "formik";
import { editProfileSchema, profileValidater } from "./validator";
import { RootState } from "../../redux/Reducer";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../components/Loader";
import { setAuthData } from "../../redux/Actions/actions";
import { Navbar } from "../../components/navbar";
import editIcon from "../../assets/icons/edit.png";

type TProp = {
    hero_name: string
    license_plate: string
    phone_number: string
    email: string
    zip_code: string
}

export const ProfilePage: React.FC = () => {

    const authenticationService = new AuthService();
    const [edit, setEdit] = useState(false)
    const [errorDialog, setErrorDialog] = useState<boolean>(false)
    const [errorMessage, setErrorMessage] = useState<string>('')
    const [fieldName, setFieldName] = useState<string>('input-field')
    const [show, setShow] = useState<boolean>(false)
    const user_id = useSelector<RootState>((state) => state?.authData?.authData?.user_id?.toString())
    const userData: any = useSelector<RootState>((state) => state?.authData?.authData)
    const [loader, setLoader] = useState<boolean>(false);
    const dispatch = useDispatch();
    const role = useSelector<RootState>((state) => state?.authData?.authData?.role);

    const [profileDetails, setProfileDetails] = useState<TProp>({
        hero_name: "",
        license_plate: "",
        phone_number: "",
        email: "",
        zip_code: ""
    });
    const isCredential = "False"
    const getProfileDeatails = async (): Promise<void> => {
        try {
            setLoader(true)
            const profileResponse: any = await authenticationService.ProfileGet(user_id, isCredential);
            setLoader(false)
            if (profileResponse.status === STATUS.SUCCESS) {
                setProfileDetails(profileResponse.data)
            }
            else if (profileResponse.statusCode === 401) {
                return
            }
            else {
                setErrorDialog(true)
                setErrorMessage(profileResponse.message)
            }
        } catch (error) {

        }
    }

    const handleEdit = () => {
        setEdit(!edit)
        updateFormik.resetForm()
        getProfileDeatails()
        setFieldName(edit ? "input-field" : "input-field2")
    }


    const handleSave = async (data: any) => {
        try {
            if (profileDetails.hero_name === data?.name && profileDetails.zip_code === data?.zipcode && profileDetails.email === data?.email && profileDetails.phone_number === data?.phone && profileDetails.license_plate === data?.license_plate) {
                return
            }
            const profileData: any = {
                user_id: user_id,
                hero_name: data?.name,
                zip_code: data?.zipcode,
                email: data?.email,
                phone_number: data?.phone,
                license_plate: data?.license_plate.trim() || null
            }
            setLoader(true)
            const profileResponse: any = await authenticationService.EditProfile(profileData);
            setLoader(false)

            if (profileResponse.status === STATUS.SUCCESS) {
                handleEdit()
                setShow(true)
                userData.hero_name = data?.name
                userData.zip_code = data?.zipcode
                userData.email = data?.email
                userData.phone_number = data?.phone
                dispatch(setAuthData(userData));

            }
            else {
                setShow(false)
                setErrorDialog(true)
                setErrorMessage(profileResponse.message)
            }
        }
        catch (error: any) {
            console.log(error)
        }
    }

    const handleCancel = () => {
        setEdit(false)
        updateFormik.resetForm()
        getProfileDeatails()
        setFieldName('input-field')
    }

    useEffect(() => {
        getProfileDeatails()
    }, [])

    const updateFormik= useFormik({ enableReinitialize: true, initialValues: editProfileSchema(profileDetails), validationSchema: profileValidater.PROFILEVALIDATER, onSubmit: handleSave })

    return (

        <div className="profile-page">
            <Navbar trueEdit={edit} editButton={true} noAds={role === 'ADMIN' ? true : false} Admin={role === 'ADMIN' ? true : false} />
            <div className="profile-edit-btn-group">
                <h1 className="heading">PROFILE</h1>
                <button type="submit" onClick={handleEdit} className="edit-icon">
                                <img style={{filter: "invert(81%) sepia(8%) saturate(749%) hue-rotate(88deg) brightness(86%) contrast(83%)"}} src={editIcon} alt="edit-icon" />
                </button>
            </div>
            <div className="profile">
                <>
                    <form className={edit ? "form" : "form1"} onSubmit={updateFormik.handleSubmit}>
                        <div className="field">
                            <h4 className="lable-field">Username</h4>
                            <div className="text-field">
                                <div>
                                    <input disabled={!edit} type="text" className={fieldName} {...updateFormik.getFieldProps('name')} />
                                    {updateFormik.touched.name && updateFormik.errors.name ? (
                                        <p className="warn">{updateFormik.errors.name}</p>
                                    ) : null}
                                </div>
                            </div>
                        </div>
                        <div className="field">
                            <h4 className="lable-field">License Plate</h4>
                            <div className="text-field">
                                <div>
                                    <input disabled={!edit} type="text" className={fieldName} {...updateFormik.getFieldProps('license_plate')} />
                                    {updateFormik.touched.license_plate && updateFormik.errors.license_plate ? (
                                        <p className="warn">{updateFormik.errors.license_plate}</p>
                                    ) : null}
                                </div>
                            </div>
                        </div>
                        <div className="field">
                            <h4 className="lable-field">Phone Number</h4>
                            <div>
                                <input disabled={!edit} type="text" className={fieldName} {...updateFormik.getFieldProps('phone')} />
                                {updateFormik.touched.phone && updateFormik.errors.phone ? (
                                    <p className="warn">{updateFormik.errors.phone}</p>
                                ) : null}
                            </div>
                        </div>
                        <div className="field">
                            <h4 className="lable-field">Email</h4>
                            <div>
                                <input disabled={!edit} type="email" className={fieldName} {...updateFormik.getFieldProps('email')} />
                                {updateFormik.touched.email && updateFormik.errors.email ? (
                                    <p className="warn">{updateFormik.errors.email}</p>
                                ) : null}
                            </div>
                        </div>
                        <div className="field">
                            <h4 className="lable-field">Zip Code</h4>
                            <div>
                                <input disabled={!edit} type="number" className={fieldName} {...updateFormik.getFieldProps('zipcode')} />
                                {updateFormik.touched.zipcode && updateFormik.errors.zipcode ? (
                                    <p className="warn">{updateFormik?.errors?.zipcode}</p>
                                ) : null}
                            </div>
                        </div>
                        <div className="space"></div><br />
                        {edit &&
                            <div className="field">
                                <button className="btn" type="button" onClick={() => handleCancel()}>Cancel</button>
                                <button type="submit" className="btn" >Save</button>
                            </div>}
                    </form >
                </>
            </div>
            {/* <div className={!edit ? "googleAd" : "googleAd1"}>
                <img className="googleAdimg" src={googleIMG} alt="GoogleAD" />
            </div> */}
            {show && <ProfileDialogBox show={show} setShow={setShow} />}
            {errorDialog && <ErrorDialog show={errorDialog} setShow={setErrorDialog} message={errorMessage} navigationData={'profile'} />}
            {loader && <Loader />}
        </div>
    )
}
