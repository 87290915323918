import { BaseService } from "./base.service";
import { IErrorResponse, ISuccessResponse } from "./interface/ApiResponse.interface";
import { Iadvertisement, IblockUser, Ibroadcast, IChangePassword, IContact, IconvertUrl, IDelete, IEmail, IfaqDelete, IfaqModify, IfaqPost, IForgotPassword, IForgotPassword3, IindividualMessage, Ilogin, IOtp, IProfile, IReferal, IReferalSms, IsellID, ISignUp, IToken } from "./interface/auth.interface";
export class AuthService extends BaseService {
    public async Signup(signup: ISignUp): Promise<ISuccessResponse | IErrorResponse> {
        try {
            const { data } = await this.httpClient.post("auth/register/", signup);
            return data;
        } catch (error: any) {
            return error.response.data;
        }
    }
    public async Login(signin: Ilogin) {
        try {
            const { data } = await this.httpClient.post("auth/login/", signin);
            return data;
        }
        catch (error: any) {
            return error.response.data;
            // return parseAxiosError(error as AxiosError)
        }
    }
    public async SocialLogin(signin:any) {
        try {
            const { data } = await this.httpClient.post("auth/SocialLogin/",signin);
            return data;
        }
        catch (error: any) {
            return error.response.data;
            // return parseAxiosError(error as AxiosError)
        }
    }
    public async SocialRegister(signin:any) {
        try {
            const { data } = await this.httpClient.post("auth/SocialRegister/",signin);
            return data;
        }
        catch (error: any) {
            return error.response.data;
            // return parseAxiosError(error as AxiosError)
        }
    }
    public async ForgotPassword(forgotPassword: IForgotPassword): Promise<ISuccessResponse | IErrorResponse> {
        try {
            const { data } = await this.httpClient.post("/auth/requestForgotPassword/", forgotPassword);
            return data;
        }
        catch (error: any) {
            return error.response.data;
        }
    }
    public async OtpVerification(otp: IOtp): Promise<ISuccessResponse | IErrorResponse> {
        try {
            const { data } = await this.httpClient.post("/auth/forgotPasswordOTPCheck/", otp);
            return data;
        }
        catch (error: any) {
            return error.response.data;
        }
    }
    public async ForgotPassword3(forgotPassword: IForgotPassword3): Promise<ISuccessResponse | IErrorResponse> {
        try {
            const { data } = await this.httpClient.patch("/auth/setForgotPassword/", forgotPassword);
            return data;
        }
        catch (error: any) {
            return error.response.data;
        }
    }
    public async ChangePassword(changePassword: IChangePassword): Promise<ISuccessResponse | IErrorResponse> {
        try {
            const { data } = await this.httpClient.patch("/auth/changePassword/", changePassword);
            return data;
        }
        catch (error: any) {
            return error.response.data;
        }
    }
    public async EmailVerification(token: IToken): Promise<ISuccessResponse | IErrorResponse> {
        try {
            const { data } = await this.httpClient.get("auth/emailVerify/", { headers: { Authorization: `Bearer ${token.token}` } });
            return data;
        }
        catch (error: any) {
            return error.response.data;
        }
    }
    public async EmailResendVerification(verifyEmailData: IEmail): Promise<ISuccessResponse | IErrorResponse> {
        try {
            const { data } = await this.httpClient.post("/auth/resendEmailVerificationLink/", verifyEmailData);
            return data;
        }
        catch (error: any) {
            return error.response.data;
        }
    }
    public async MobileResendVerification(verifyEmailData: IEmail): Promise<ISuccessResponse | IErrorResponse> {
        try {
            const { data } = await this.httpClient.post("/auth/resendSMSVerification/", verifyEmailData);
            return data;
        }
        catch (error: any) {
            return error.response.data;
        }
    }
    public async DeleteAccount(Token: IDelete): Promise<ISuccessResponse | IErrorResponse> {
        try {
            const { data } = await this.httpClient.patch("/auth/deleteAccount/", Token);
            return data;
        }
        catch (error: any) {
            return error.response.data;
        }
    }
    public async GetRefreshToken(Token: IToken): Promise<ISuccessResponse | IErrorResponse> {
        try {
            const { data } = await this.httpClient.patch("/auth/getAccessToken/", Token);
            return data;
        }
        catch (error: any) {
            return error.response.data;
        }
    }
    public async ProfileGet(user_id: unknown, is_crud: string) {
        try {
            const { data } = await this.httpClient.get(`/hero/getProfile/?user_id=${user_id}&is_confidential=${is_crud}`);
            return data;

        }
        catch (error: any) {
            return error.response.data;

        }
    }
    public async GetAllLicensePlate(search: string) {
        try {
            const { data } = await this.httpClient.get(`/hero/search/othersLicensePlate?license_plate=${search}`);
            return data;
        } catch (error: any) {
            return error.response.data;
        }
    }
    public async getLicensePlate(user_id: any) {
        try {
            const { data } = await this.httpClient.get(`/hero/getProfile/?user_id=${user_id}&is_confidential=False`);
            return data;
        } catch (error: any) {
            return error.response.data;
        }
    }
    public async getLicensePlateDetails(license_plate: string) {
        try {
            const { data } = await this.httpClient.get(`/hero/search/othersLicensePlate?license_plate=${license_plate}`)
            return data;
        } catch (error: any) {
            return error.response.data;
        }
    }
    public async EditProfile(profile: IProfile) {
        try {
            const { data } = await this.httpClient.post("/hero/editProfile/", profile);
            return data;

        }
        catch (error: any) {
            return error.response.data;

        }
    }
    public async ContactUs(contact: IContact): Promise<ISuccessResponse | IErrorResponse> {
        try {
            const { data } = await this.httpClient.post("/hero/contactUS/", contact);
            return data;
        }
        catch (error: any) {
            return error.response.data;
        }
    }
    public async FaqContent() {
        try {
            const { data } = await this.httpClient.get(
                `/hero/FAQ/`
            );
            return data;

        }
        catch (error: any) {
            return error.response.data;

        }
    }
    public async ReferEmail(referal: IReferal): Promise<ISuccessResponse | IErrorResponse> {
        try {
            const { data } = await this.httpClient.patch("/hero/shareReferalCodeInMail/", referal);
            return data;

        }
        catch (error: any) {
            return error.response.data;

        }
    }
    public async ReferSms(referal: IReferalSms): Promise<ISuccessResponse | IErrorResponse> {
        try {
            const { data } = await this.httpClient.patch("/hero/shareReferalCodeInSMS/", referal);
            return data;

        }
        catch (error: any) {
            return error.response.data;
        }
    }

    public async QrGet(user_id: string) {
        try {
            const { data } = await this.httpClient.post(`/hero/getQRCode/`, user_id);
            return data;

        }
        catch (error: any) {
            return error.response.data;

        }
    }
    public async timeForAction(timeForAction: any): Promise<ISuccessResponse | IErrorResponse> {
        try {
            const { data } = await this.httpClient.post("/hero/timeForAction/", timeForAction, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });
            return data;
        }
        catch (error: any) {
            return error.response.data;
        }
    }
    public async getAccount(user_id: unknown, page: number, page_limit: number) {
        try {
            const { data } = await this.httpClient.get(`/hero/getAccountDetails/?user_id=${user_id}&page=${page}&page_limit=${page_limit}`);
            return data;

        }
        catch (error: any) {
            return error.response.data;

        }
    }
    public async Offers() {
        try {
            const { data } = await this.httpClient.get(`/hero/yourOffers/`);
            return data;

        }
        catch (error: any) {
            return error.response.data;

        }
    }
    public async uploadOffers(uploadOffers: any): Promise<ISuccessResponse | IErrorResponse> {
        try {
            const { data } = await this.httpClient.post(`/admin/uploadOffer/`, uploadOffers, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });
            return data;

        }
        catch (error: any) {
            return error.response.data;
        }
    }
    public async userManagement(page: number, limit: number, search: string, sell: string) {
        try {
            const { data } = await this.httpClient.get(`/hero/userManagement/?page=${page}&page_limit=${limit}&search=${search}&sell=${sell}`);
            return data;
        }
        catch (error: any) {
            return error.response.data;

        }
    }
    public async adminFAQDelete(deleteID: IfaqDelete): Promise<ISuccessResponse | IErrorResponse> {
        try {
            const { data } = await this.httpClient.delete(`/admin/faq/`, { data: deleteID });
            return data;
        }
        catch (error: any) {
            return error.response.data;

        }
    }
    public async adminFAQPatch(modifyFAQ: IfaqModify): Promise<ISuccessResponse | IErrorResponse> {
        try {
            const { data } = await this.httpClient.patch(`/admin/faq/`, modifyFAQ);
            return data;
        }
        catch (error: any) {
            return error.response.data;

        }
    }
    public async adminFAQPost(addFaq: IfaqPost): Promise<ISuccessResponse | IErrorResponse> {
        try {
            const { data } = await this.httpClient.post(`/admin/faq/`, addFaq);
            return data;
        }
        catch (error: any) {
            return error.response.data;

        }
    }
    public async adminAdvertisementPost(addData: Iadvertisement): Promise<ISuccessResponse | IErrorResponse> {
        try {
            const { data } = await this.httpClient.post(`/admin/advertisement/`, addData);
            return data;
        }
        catch (error: any) {
            return error.response.data;

        }
    }
    public async adminAdvertisement() {
        try {
            const { data } = await this.httpClient.get(`/admin/advertisement/`);
            return data;
        }
        catch (error: any) {
            return error.response.data;

        }
    }
    public async adminBroadcastPost(broadcastData: Ibroadcast): Promise<ISuccessResponse | IErrorResponse> {
        try {
            const { data } = await this.httpClient.patch(`/admin/broadcastMessage/`, broadcastData);
            return data;
        }
        catch (error: any) {
            return error.response.data;

        }
    }
    public async adminIndividualMessage(individualMessage: IindividualMessage): Promise<ISuccessResponse | IErrorResponse> {
        try {
            const { data } = await this.httpClient.patch(`/admin/IndividualMessage/`, individualMessage);
            return data;
        }
        catch (error: any) {
            return error.response.data;

        }
    }
    public async adminMessageLog() {
        try {
            const { data } = await this.httpClient.get(`/admin/MessageLog/`);
            return data;
        }
        catch (error: any) {
            return error.response.data;

        }
    }
    public async adminBlockUser(patchData: IblockUser): Promise<ISuccessResponse | IErrorResponse> {
        try {
            const { data } = await this.httpClient.patch(`/admin/blockUser/`, patchData);
            return data;
        }
        catch (error: any) {
            return error.response.data;

        }
    }
    public async sellAccount(postData: IsellID): Promise<ISuccessResponse | IErrorResponse> {
        try {
            const { data } = await this.httpClient.post(`/hero/SellMyNumber/`, postData);
            return data;
        }
        catch (error: any) {
            return error.response.data;

        }
    }
    public async adminAdDelete() {
        try {
            const { data } = await this.httpClient.patch(`/admin/advertisement/`);
            return data;
        }
        catch (error: any) {
            return error.response.data;

        }
    }
    public async adminAddConvertFile(addConvert: IconvertUrl): Promise<ISuccessResponse | IErrorResponse> {
        try {
            const { data } = await this.httpClient.post(`/hero/UploadImage/`, addConvert);
            return data;
        }
        catch (error: any) {
            return error.response.data;

        }
    }

    public async adminDeleteUser(userId: any): Promise<ISuccessResponse | IErrorResponse> {
        try {
            const { data } = await this.httpClient.post(`/auth/deleteUser/`, userId);
            return data;
        }
        catch (error: any) {
            return error.response.data;

        }
    }

}