import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import { AccountPage } from '../pages/accountPage/index';
import { ChangePasswordPage } from "../pages/changePassword";
import { ContactPage } from "../pages/contactPage";
import { EmailPage } from "../pages/emailPage";
import { EmailVerification } from "../pages/emailVerifyPage";
import { FAQPage } from "../pages/faqPage";
import { IncidentDetailsPage } from "../pages/incidentDetailsPage";
import { ProfilePage } from "../pages/profile";
import { QrPage } from "../pages/qrPage";
import { SettingsPage } from "../pages/settingsPage";
import { SupportPage } from "../pages/supportPage";
import { TimeForActionPage } from "../pages/timeForActionPage";
import { useSelector } from "react-redux";
import { RootState } from "../redux/Reducer";
import { AdminRoutes } from "./adminRoutes";

export const PrivateRoutes = () => {
    const location: any = useLocation();
    const role = useSelector<RootState>((state) => state?.authData?.authData?.role);
    
    return (
        <>
            {role==="ADMIN"?<AdminRoutes />:
        <Routes>
            <Route path="/*" element={location?.state?.qrUserId ? <Navigate replace to={`/timeForAction/${location?.state?.qrUserId}`} /> : <Navigate replace to={"/account"} />} />
            <Route path="/account" element={<AccountPage />} />
            <Route path="/profile" element={<ProfilePage />} />
            <Route path="/contact" element={<ContactPage />} />
            <Route path="/settings" element={<SettingsPage />} />
            <Route path="/changePassword" element={<ChangePasswordPage />} />
            <Route path="/support" element={<SupportPage />} />
            <Route path="/faq" element={<FAQPage />} />
            <Route path="/shareByEmail" element={<EmailPage />} />
            <Route path="/qrCode" element={<QrPage />} />
            <Route path="/timeForAction/:qrUserId" element={<TimeForActionPage />} />
            {/* <Route path="/offers" element={<OffersPage />} /> */}
            <Route path="/incidentDetails" element={<IncidentDetailsPage />} />
            {/* <Route path="/adPage" element={<AdPage />} /> */}
            {/* <Route path="/admin" element={<LandingPage />} /> */}
            {/* <Route path="/admin/faq" element={<FAQPage role={"admin"} />} /> */}
            {/* <Route path="/admin/offers" element={<OffersPage />} /> */}
            <Route path="/emailVerify/:token" element={<EmailVerification />} />
            </Routes>
        }
            </>
    );
};