import React from 'react'
import "./styles.scss"
import DoubleArrow from "@material-ui/icons/DoubleArrowSharp"
type TProp = {
  setPage: any
  page: number
  count: number
}

const Pagination: React.FC<TProp> = ({ setPage, page, count }) => {
  return (
    <div className="pagination">

      <div className="acc-page-btn">
        <button className={page === 1 ? "acc-page-btn-btn-disable" : "acc-page-btn-btn"} disabled={page === 1 ? true : false} style={page === 1 ? { cursor: "not-allowed" } : { cursor: "pointer" }} onClick={() => setPage(page - 1)}  ><DoubleArrow style={{ transform: "rotate(180deg)" }} /> Previous</button>
      </div>

      <div className="page-number" style={{ width: " 30px", height: "30px", fontSize: "20px" }}>
        {page}
      </div>
      <div className="acc-page-btn">
        <button className={page === count ? "acc-page-btn-btn-disable" : "acc-page-btn-btn"} disabled={page === count ? true : false} style={page === count ? { cursor: "not-allowed" } : { cursor: "pointer" }} onClick={() => setPage(page + 1)} >Next <DoubleArrow /></button>
      </div>
    </div>
  )
}

export default Pagination