/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from "react";
import { Navbar } from "../../components/navbar";
import './style.scss'
import { useSelector } from "react-redux";
import { RootState } from "../../redux/Reducer";
import qrLogo from "../../assets/qrLogo.png";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import { QRCode } from "react-qrcode-logo";
import Loader from "../../components/Loader";
import { storeToParkingHero } from "../../common/constant";
// import stopLogo from "../../assets/stopLogo.jpg";
 
export const QrPage: React.FC = () => {
    const userData: any = useSelector<RootState>((state) => state?.authData?.authData)
    const [status, setStatus] = useState<boolean>(false)
    const [loader, setLoader] = useState<boolean>(false)
    const downloadImg = async () => {
        setLoader(true)
        setTimeout(() => {
            const input = document.getElementById('divToPrint') as HTMLElement;
            html2canvas(input)
                .then((canvas: { toDataURL: (arg0: string) => any; }) => {
                    const imgData = canvas.toDataURL('image/png');
                    const pdf = new jsPDF('p', 'pt', 'letter', true);
                    pdf.addImage(imgData, 'JPEG', 20, 0, 575, 750);
                    pdf.save("PH_QRcode.pdf");
                    setLoader(false)
                    setStatus(false)
                });
        }, 100)
    }

    return (
        <div >
            <div className="qrPage ">
                <div className="no-print"><Navbar noAds={true} /></div>
                <div className="qrContent no-print ">
                    <h5 className="qr-heading no-print">QR CODE</h5>
                    <h5 className="qr-heading1 no-print ">WELCOME {userData?.hero_name || ""}!</h5>
                    <div className="support-container no-print">
                        <div className="qr no-print" >
                            <div className="qr-img no-print ">
                                <QRCode
                                    value={`https://lbparkinghero.com/timeforAction/${userData.user_id}`}
                                    size={240}
                                    logoImage={qrLogo}
                                    logoHeight={60}
                                    logoWidth={60}
                                    logoOpacity={1}
                                    enableCORS={true}
                                    qrStyle="dots"
                                    eyeRadius={10}
                                />
                            </div>
                        </div>
                        <div className="btn-wraper no-print" >
                            <div className="qr-btn">
                                <button className="btn" onClick={() => {
                                    setStatus(true)
                                    downloadImg()
                                }} >Free Download</button>
                            </div>
                            <div className="qr-btn">
                                <button className="btn" onClick={() => { window.open(storeToParkingHero) }}>Order Sticker</button>
                            </div>
                            {/* <div className="qr-btn">
                                <button className="btn" onClick={(e) => {
                                    setStatus(true)
                                    setTimeout(() => {
                                        window.print()
                                        setStatus(false)
                                    }, 100)
                                }}  >Print</button>
                            </div> */}
                        </div>
                    </div>
                </div>

                <div className="divToPrint" style={status ? { display: "flex", overflow: "hidden" } : { display: "none" }}>
                    <div className="footer no-print">
                        All rights reserved by Parking Hero ,LLC - this information is confidential
                    </div>
                    <div className="Download" id="divToPrint" >
                        <div>
                            {/* <p>- QR Code Generation with ReactJS POC by Jayasin Prabhu C</p> */}
                            {/* <img className='logo' src={qrLogo} alt="" />
                            <h1>SCAN ME!!!</h1>
                            <p>To Notify Drivers that their Car is about</p>
                            <p>to Get a parking ticket </p> */}
                           {/* <h1 className="stopLogo">F <img src={stopLogo} /> ck</h1>
                            <h2> PARKING TICKETS</h2> */}
                            <div style={{height: '100px'}}></div>
                            <div className="download-qr">
                                <QRCode
                                    value={`https://lbparkinghero.com/timeforAction/${userData.user_id}`}
                                    size={340}
                                    logoImage={qrLogo}
                                    logoHeight={68}
                                    logoWidth={68}
                                    logoOpacity={1}
                                    enableCORS={true}
                                    qrStyle="dots"
                                    eyeRadius={10}
                                />
                            </div>
                        </div>
                        <div style={{height: '100px'}}></div>
                        {/* <h1 className="footer">Scan & BECOME  </h1>
                        <h1 className="footer">A PARKING HERO </h1> */}
                    </div>
                </div>
                {loader && <Loader />}
            </div>
        </div >
    )
}


