import React from "react";
import "./styles.scss";
import closeIcon from "../../assets/icons/x-circle.svg"

type Tprop = {
    show: boolean
    setShow: (open: boolean) => void,
    link:string,
}

export const PdfViewDialogBox: React.FC<Tprop> = ({ show, setShow,link }) => {;

    return (
        <div className="pdfViewr">
        <div className="box">
            <div className="close">
                <div className="dialog-btn-close" onClick={() => setShow(false)}>
                    <img src={closeIcon} alt="not-found" />
                </div>
            </div>
            <iframe className="pdf-body" 
               src={link +"#toolbar=0"} 
            />
        </div>
    </div>
    );
};