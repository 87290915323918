import { Navigate, Route, Routes } from "react-router-dom";
import LandingPage from "../pages/Admin/LandingPage";
import { AdPage } from "../pages/adPage";
import { FAQPage } from "../pages/faqPage";
import { IncidentDetailsPage } from "../pages/incidentDetailsPage";
import { OffersPage } from "../pages/offersPage";
import { ProfilePage } from "../pages/profile";
import { UserManagement } from "../pages/userManagement";

export const AdminRoutes = () => {
    return (
        <Routes>
            <Route path="/*" element={<Navigate replace to="/admin" />} />
            <Route path="/admin" element={<LandingPage />} />
            <Route path="/admin/adPage" element={<AdPage />} />
            <Route path="/admin/faq" element={<FAQPage role={"admin"} />} />
            <Route path="/admin/offers" element={<OffersPage />} />
            <Route path="/profile" element={<ProfilePage />} />
            <Route path="/admin/incidentDetails" element={<IncidentDetailsPage />} />
            <Route path="/admin/userManagement" element={<UserManagement />} />
        </Routes>
    );
};
