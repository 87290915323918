import * as Yup from 'yup'

export const loginSchema = {
  email: '',
  password: '',
}
// eslint-disable-next-line
const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#\$%\^&\*])(?=.{8,})/
export const loginValidations = Yup.object().shape({
    email: Yup.string().required("Please enter valid email").lowercase().max(100,"can exceed more than 100 characters")
    .test('test-name', 'Please enter valid email',
      function (value: any) {
        // eslint-disable-next-line no-useless-escape
        const emailRegex = /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;
        let isValidEmail = emailRegex.test(value);
        if (!isValidEmail) {
          return false;
        }
        return true;
      }),
  password: Yup.string().trim().required('Please enter password').matches(passwordRegex, "Passwords must contain letters, numbers, and symbols")
})
