import * as Yup from 'yup'

export const registerSchema = {
  hero_name: "",
  phoneNumber: "",
  countryCode: '+1',
  zip_code: "",
}

// eslint-disable-next-line no-useless-escape
// const emailRegex = /^([a-z0-9_\.\-])+\@(([a-z0-9\-])+\.)+([a-z0-9]{2,4})+$/
// const passwordRegex = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/
const heroNameRegex = /^\S*$/;


export const registerValidations = Yup.object().shape({
  hero_name: Yup.string().trim().required('Please enter your name').matches(heroNameRegex, "Please don't enter space").max(30, "Name must be maximum 30 characters").min(3, "Name must be minimum 3 characters "),
  phoneNumber: Yup.string().trim().required('Please enter mobile number').max(10, "Please enter valid number ").min(10, "Please enter valid number "),
  zip_code: Yup.string().trim().required('Please enter zipcode').min(5, "Zip code length must be greater than 4")
})




