import * as Yup from 'yup'

export const contactSchema = (heroname: string, emailId: string) => {

    return {
        name: heroname || "",
        email: emailId || "",
        message: '',
        selectedOption: "",
    }

}


export const contactValidations = Yup.object().shape({
    name: Yup.string().trim().required('Please enter your name').max(30, "Name must be maximum 30 characters").min(3, "Name must be minimum 3 characters "),
    email: Yup.string().required("Please enter valid email").lowercase().max(100, "can exceed more than 100 characters")
        .test('test-name', 'Please enter valid email',
            function (value: any) {
                // eslint-disable-next-line no-useless-escape
                const emailRegex = /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;
                let isValidEmail = emailRegex.test(value);
                if (!isValidEmail) {
                    return false;
                }
                return true;
            }),
    message: Yup.string().trim().required('Please enter your message').max(200).min(3),
    selectedOption: Yup.string().trim().required('Please choose drop down menu').max(200).min(3),

})
