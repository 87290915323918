import React from "react";
import "./styles.scss";
import { useNavigate } from "react-router-dom"
import closeIcon from "../../assets/icons/x-circle.svg"

type Tprop = {
    show: boolean
    setShow: (open: boolean) => void
    userId?: string
}

export const TFADialogModal: React.FC<Tprop> = ({ show, userId, setShow }) => {
    
    const navigate = useNavigate()

    return (
        <div className="tfa-modal">
            <div className="modal-box">
                <div className="close">
                    <div className="dialog-btn-close" onClick={() => {
                        navigate("/signup")
                        setShow(false)
                    }}>
                        <img src={closeIcon} alt="not-found" />
                    </div>
                </div>
                <p className="text">Oops! Looks like you are not logged in</p>
                <div className="space"></div>
                <div className="buttons">
                    <button className="btn" onClick={() => {
                        // navigate("/login") 
                        navigate('/login', { state: { qrUserId: userId } })
                    }}>Login</button>
                    <button className="btn" onClick={() => {
                        //navigate("/signup") 
                        navigate('/signup', { state: { qrUserId: userId } })
                    }}>Signup</button>
                </div>
                <div className="space"></div>
                <p className="text-lg"><a href="https://parkinghero.net/" className="link">parkinghero.net</a> to learn more about parking hero</p>
            </div>
        </div>
    )
}