import "./style.scss";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFilePdf } from '@fortawesome/free-solid-svg-icons';
import closeIcon from "../../assets/icons/x-circle.svg"
import { useEffect, useState } from "react";
import { AuthService } from "../../services/auth.service";
import { DELETE_OFFER, STATUS } from "../../common/constant";
import Loader from "../Loader";
import { DeleteDialogBox } from "../deleteConfirmationDialogBox";
import { AdDialogBox } from "../adPageDialog";


type Tprop = {
    url: any
    selectedValue?: any,
    setapiFile?: any
    count?:boolean
}

export const DownloadPdf: React.FC<Tprop> = ({ url, selectedValue, setapiFile,count }) => {
    const [fileName, setFileName] = useState<any>();
    const authenticationService = new AuthService();
    const [loader, setLoader] = useState(false)
    const [deleteBox, setDeleteBox] = useState<boolean>(false);
    const [confirm, setConfirm] = useState<boolean>(false);
    const [status, setStatus] = useState<boolean>(false);

    const handleDownload = () => {
        window.open(url, '_blank');
    };

    const deleteConfirm = () => {
        !count &&
        setDeleteBox(true)
    }

    const clossFile=()=>{

    }

    const deleteFile = async () => {
        try {
            setLoader(true);
            const formData = new FormData();
            formData.append("missions", selectedValue);
            const uploadDataResponse: any = await authenticationService.uploadOffers(formData);
            setLoader(false);
            if (uploadDataResponse.status === STATUS.SUCCESS) {
                setStatus(true)
            }
            else {
            }
        } catch (error) {

        }
    }
    const nameShow = () => {
        if(!count){            
        const path = url.split('/');
        const f_Name = path[path.length - 1];
        const decodedFileName = decodeURIComponent(f_Name)        
        setFileName(decodedFileName);        
        }else{
            setFileName(url);
        }
    }

    useEffect(() => {
        url &&
            nameShow();
    }, [url])

    useEffect(() => {
        if (confirm) {
            deleteFile()
        }
        if(status && !confirm){
            setapiFile(undefined)
        }
    }, [confirm])

    return (
        <div className="relative">
            <div className="pdfDownload">
                <div>
                    <FontAwesomeIcon className='downloadIcon' icon={faFilePdf} onClick={handleDownload} />
                    <p onClick={handleDownload} className="font">{fileName}</p>
                </div>
            </div>
            <img className="icon" src={closeIcon} alt="not-found" onClick={count ? clossFile: deleteConfirm } />   
            {loader && <Loader />}
            {deleteBox && <DeleteDialogBox show={deleteBox} setShow={setDeleteBox} confirm={confirm} setConfirm={setConfirm} message={DELETE_OFFER.deleteConfirmationMessage} />}
            {confirm && <AdDialogBox show={confirm} setShow={setConfirm} message={DELETE_OFFER.deleteMessage} />}

        </div>
    );
};