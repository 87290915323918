import * as React from 'react';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Grow from '@mui/material/Grow';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import Stack from '@mui/material/Stack';
import { useRef, useEffect, useState } from 'react';
import eclipse from '../../assets/icons/eclipse.png'
import { FILTERVALUES } from '../../common/constant'

type Tprops = {
  show: boolean
  setShow: any
  anchorRef: any
  sell: string
  setSell: (val: string) => void
}

export const AdminMenuListComponent: React.FC<Tprops> = ({ show, setShow, anchorRef, sell, setSell }) => {

  let list: Array<string> = []
  const [filterValue, setFilterValue] = useState<string>(sell)

  list = FILTERVALUES


  const handleClose = (event: Event | React.SyntheticEvent, e?: string) => {
    if (
      anchorRef.current &&
      anchorRef.current.contains(event.target as HTMLElement)
    ) {
      return;
    }
    if (e) {
      setFilterValue(e as string)
      return
    }
    setShow(false);
  };

  function handleListKeyDown(event: React.KeyboardEvent) {
    if (event.key === 'Tab') {
      event.preventDefault();
      setShow(false);
    } else if (event.key === 'Escape') {
      setShow(false);
    }
  }

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = useRef(show);
  useEffect(() => {
    if (prevOpen.current === true && show === false) {
      anchorRef.current!.focus();
    }

    prevOpen.current = show;
  }, [anchorRef, show]);

  useEffect(() => {
    setSell(filterValue); // eslint-disable-next-line
  }, [filterValue])

  return (
    <Stack direction="row" spacing={2}>
      <div  >
        <Popper
          open={show}
          anchorEl={anchorRef.current}
          role={undefined}
          placement="bottom-end"
          transition
          disablePortal 
        >
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{
                backgroundColor: "black",
                color: "white",
                transformOrigin: 'right top',
                width: '85px',
                marginTop: "20px"
              }}
            >
              <Paper>
                <ClickAwayListener onClickAway={(e) => handleClose(e)}>
                  <MenuList
                    autoFocusItem={show}
                    id="composition-menu"
                    aria-labelledby="composition-button"
                    onKeyDown={handleListKeyDown}
                  >
                    {
                      list.map((e, index) => <MenuItem key={index} onClick={(event) => handleClose(event, e)}>{filterValue === e && <img src={eclipse} alt="" width={"5px"} height={"5px"} />} &nbsp;&nbsp;&nbsp; {`${e}`}</MenuItem>)
                    }
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </div>
    </Stack >
  );
}