import React, { useRef, useState } from "react";
import brandLogo from "../../assets/parkingHeroLogo.svg";
import menuIcon from "../../assets/icons/menu-icon.png"
import "./styles.scss";
import { Link, useNavigate } from 'react-router-dom';
import { MenuListComponent } from "../../components/menuList";

export const LandindPage: React.FC = () => {
  const history = useNavigate();
  const [show, setShow] = useState<any>(false)
  const anchorRef = useRef<any>(null);

  return (
    <div className="landing-page">
      <div className="logo-wrapper">
        <div className="nullDiv"></div>
        <div className="logo" >
          <Link to={"/"} className="logoLink"> <img src={brandLogo} alt="" /></Link>
        </div>
        <div className="right-side-icons">
          <img className="menu-icon" ref={anchorRef} onClick={() => setShow(!show)} src={menuIcon} alt={"not dound"} />
          <MenuListComponent show={show} setShow={setShow} anchorRef={anchorRef} profile="landing" />
        </div>
      </div>
      <div className="margTop">
        <h1 className="heading heading-space uppercase">Stop parking tickets</h1>
        <h1 className="heading heading-space uppercase">Become a Parking Hero</h1>
        <h6 className="description-text">
          The app that prevents parking tickets and more!...
        </h6>
      </div>
      <div className="container">
        <h3 className="marg30">
          Becoming a Parking Hero is quick and easy.<br />your neighbors will thank
          you.
        </h3>
       <div className="sign-log">
          <button className="btn" onClick={() => { history("/signup") }}>Signup</button>
          <div className="or">
            <p className="or-option">or</p>
          </div>
          <button className="btn" onClick={() => { history("/login") }}>Login</button>
       </div>
      </div>
    </div>
  );
};
