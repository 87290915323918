import React from "react";
import "./style.scss";
import Icon from "../Icon";
import { faClose } from "@fortawesome/free-solid-svg-icons";



type Tprop = {
    show: boolean
    setShow: (open: boolean) => void
    confirm: boolean
    setConfirm: (open: boolean) => void
    message: string

}

export const DeleteDialogBox: React.FC<Tprop> = ({ show, setShow, message, confirm, setConfirm }) => {
    
    const handleOk = () => {
        setShow(false)
        setConfirm(true)
    }
    const handleCancel = () => {
        setShow(false)
        setConfirm(false)
    }
    return (
        <div className="forgot2-box-emailref">
            <div className="box2">
                <div className="close">
                    <div className="dialog-btn-close" onClick={() => { setShow(false) }}>
                    <Icon icon={faClose} />
                    </div>
                </div>
                <p className="text">{message}</p>
                <div className="delete-buttons">
                    <button className="delete-btn" onClick={handleOk}>OK</button>
                    <button className="delete-btn" onClick={handleCancel}>cancel</button>
                </div>
               
            </div>
        </div>
    );
};