import { Navigate, Route, Routes } from "react-router-dom";
import { LandindPage } from "../pages/landingPage";
import { LoginPage } from "../pages/loginPage";
import { ContactPage } from "../pages/contactPage";
import { SignUpPage } from '../pages/signUpPage/index';
import { FAQPage } from '../pages/faqPage/index';
import { TimeForActionPage } from "../pages/timeForActionPage";
import { SupportPage } from "../pages/supportPage";
import { QrPage } from "../pages/qrPage";
import { EmailVerification } from "../pages/emailVerifyPage";
import { ForgotPasswordPage } from "../pages/forgotPasswordPage";


export const PublicRoutes = () => {

    return (
        <Routes>
            <Route path="/*" element={<Navigate replace to="/" />} />
            <Route path='/' element={<LandindPage />} />
            <Route path="/login" element={<LoginPage />} />
            <Route path="/forgotPassword" element={<ForgotPasswordPage />} />
            {/* <Route path="/changePassword" element={<ChangePasswordPage />} /> */}
            <Route path="/contact" element={<ContactPage />} />
            <Route path="/signup" element={<SignUpPage />} />
            <Route path="/faq" element={<FAQPage />} />
            {/* <Route path="/profile" element={<ProfilePage />} /> */}
            {/* <Route path="/settings" element={<SettingsPage />} /> */}
            {/* <Route path="/shareByEmail" element={<EmailPage />} /> */}
            {/* <Route path="/account" element={<AccountPage />} /> */}
            {/* <Route path="/incidentDetails" element={<IncidentDetailsPage />} /> */}
            <Route path="/timeForAction/:qrUserId" element={<TimeForActionPage />} />
            <Route path="/support" element={<SupportPage />} />
            <Route path="/qrCode" element={<QrPage />} />
            {/* <Route path="/adPage" element={<AdPage />} /> */}
            <Route path="/emailVerify/:token" element={<EmailVerification />} />
            {/* Admin Screens */}
            {/* <Route path="/admin" element={<LandingPage />} /> */}
            {/* <Route path="/admin/faq" element={<FAQPage role={"admin"} />} /> */}
            {/* <Route path="/admin/offers" element={<OffersPage/>} /> */}
        </Routes>
    );
};