import * as Yup from 'yup'
import { emailRegex } from '../../../common/constant';

export const forgotPasswordSchema = {
  email: ""
}

export const forgotPasswordValidations = Yup.object().shape({
  email: Yup.string().required("Please enter valid email").lowercase().max(100,"can exceed more than 100 characters")
  .test('test-name', 'Please enter valid email',
    function (value: any) {
      let isValidEmail = emailRegex.test(value);
      if (!isValidEmail) {
        return false;
      }
      return true;
    }),
})
