import React from "react";
import "./styles.scss";
import closeIcon from "../../assets/icons/x-circle.svg"



type Tprop = {
    message:string
    show: boolean
    setShow: (open: boolean) => void
}

export const CommonMessageDialog: React.FC<Tprop> = ({ show, setShow,message }) => {

    return (
        <div className="common-box-ref">
            <div className="box">
                <div className="close">
                    <div className="dialog-btn-close" onClick={() => { setShow(false) }}>
                        <img src={closeIcon} alt="not-found" />
                    </div>
                </div>
                <div className="msg-container">
                    <p className="text">{message}</p>
                </div>
            </div>
        </div>
    );
};