import React, { useEffect, useState } from "react";
import "./styles.scss";
import { BlockDialog } from "../blockDialog/index"
import { SuccerrPopUp } from "../successPopUp";

type ToggleButtonProps = {
  value: boolean;
  onChange?: (value: boolean) => void;
  heroName: string
  userId?: string
};

const ToggleButton: React.FC<ToggleButtonProps> = ({ value, onChange, heroName,userId }) => {
  const [isActive, setIsActive] = useState<boolean>(false);
  const [showDialog, setShowDialog] = useState<boolean>(false);
  const [succerrPopUp, setSuccerrPopUp] = useState<boolean>(false);
  const [message, setMessage] = useState<string>("")

  // useEffect(() => {
  //   setIsActive(!isActive)
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [isCurrentState])

  useEffect(() => {
    setIsActive(value);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value])

  return (
    <>
      <div
        className={!isActive ? "btn-toggle active" : "btn-toggle inactive"}
        onClick={() => {
          setShowDialog(true);
        }}
      >
        <div
          className={!isActive ? "toggle-circle active" : "toggle-circle inactive"}
          // onClick={() => setShowDialog(true)}
        />
      </div>
      {showDialog ? <BlockDialog setShowDialog={setShowDialog} isCurrentState={isActive} setSuccerrPopUp={setSuccerrPopUp} setMessage={setMessage} heroName={heroName} userId={userId} setCurrentState={setIsActive} /> : <></>}
      {succerrPopUp && <SuccerrPopUp showDialog={showDialog} setSuccerrPopUp={setSuccerrPopUp} diplayContent={message} />}
    </>
  );
};

export default ToggleButton;
