import React, { useState } from "react";
import brandLogo from "../../../assets/parkingHeroLogo.svg";
import eyeIcon from "../../../assets/icons/view.png"
import hideIcon from "../../../assets/icons/hide.png"
import { useFormik } from "formik";
import "./styles.scss";
import { ForgotPasswordDialogBox } from "../../../components/forgotpasswordDialog";
import { forgotPasswordSchema, forgotPasswordValidations } from "./validator";
import { AuthService } from "../../../services/auth.service";
import { STATUS } from "../../../common/constant";
import { ErrorDialog } from "../../../components/errorDialog";
import { Link } from "react-router-dom";
import Loader from "../../../components/Loader";

type TProp = {
    email: string
}

export const PasswordPage: React.FC<TProp> = ({ email }) => {

    const [show, setShow] = useState<boolean>(false)
    const [message, setMessage] = useState('')
    const [status, setStatus] = useState<boolean>(false)
    const [newPassEye, setNewPassEye] = useState<boolean>(true)
    const [conPassEye, setConPassEye] = useState<boolean>(true)
    const authenticationService = new AuthService();
    const [loader, setLoader] = useState<boolean>(false);

    const forgotPasswordSubmit = async (values: any): Promise<void> => {
        try {
            const forgotPassword: any = {
                new_password: values?.password,
                confirm_password: values?.password,
                email: email
            }
            setLoader(true)
            const forgotPasswordResponse = await authenticationService.ForgotPassword3(forgotPassword);
            if (forgotPasswordResponse.status === STATUS.SUCCESS) {
                setLoader(false)
                setShow(true)
                setMessage(forgotPasswordResponse.message)
            } else {
                setLoader(false)
                setStatus(true)
                setShow(false)
                setMessage(forgotPasswordResponse.message)
            }
        }
        catch (error: any) {
            console.log(error);

        }
    }
    const handleEye = (event: any) => {
        // console.log(event.target.parentElement.parentElement)
        const parentDiv = event.target.parentElement.parentElement;
        // console.log(parentDiv.children.length)
        for (let tag of parentDiv.children) {
            if (tag.className !== "eye-icon") {
                if (tag.type === "password") {
                    tag.type = "text";
                    if (parentDiv.className === "new-pass") {
                        setNewPassEye(false)
                    }
                    else {
                        setConPassEye(false)
                    }
                } else {
                    tag.type = "password";
                    if (parentDiv.className === "new-pass") {
                        setNewPassEye(true)
                    }
                    else {
                        setConPassEye(true)
                    }
                }
            }
        }
    }
    const registerFormik = useFormik({ initialValues: forgotPasswordSchema, validationSchema: forgotPasswordValidations, onSubmit: forgotPasswordSubmit })
    return (
        <div className="forgot-password-page3">
            <div className="logo-wrapper">
                <Link to={"/"}><img src={brandLogo} alt="" /></Link>
            </div>
            <div>
                <h1 className="heading">Change Password</h1>
            </div>
            <div className="space-lg"></div>
            <form onSubmit={registerFormik.handleSubmit}>
                <div className="change-form">
                    <div className="new-pass">
                        <input className="change-input" type="password" placeholder="Enter New Password"
                            {...registerFormik.getFieldProps('password')} />
                        <button type="button" className="eye-icon" onClick={(event: any) => { handleEye(event) }}>
                            <img src={newPassEye ? eyeIcon : hideIcon} alt="icon" />
                        </button>
                        {registerFormik.touched.password && registerFormik.errors.password ? (
                            <p className="warn">{registerFormik.errors.password}</p>
                        ) : null}
                    </div>
                    <br />
                    <div className="confirm-pass">
                        <input className="change-input" type="password" placeholder="Confirm New Password"
                            {...registerFormik.getFieldProps('confirmPassword')} />
                        <button type="button" className="eye-icon" onClick={(event: any) => { handleEye(event) }}>
                            <img src={conPassEye ? eyeIcon : hideIcon} alt="icon" />
                        </button>
                        {registerFormik.touched.confirmPassword && registerFormik.errors.confirmPassword ? (
                            <p className="warn">{registerFormik.errors.confirmPassword}</p>
                        ) : null}
                    </div>
                </div>
                <div className="space-lg"></div>
                <button className="btn" type="submit">Update Password</button>
            </form>
            <div className="space-lg"></div>
            {/* <Modal message="Password has been updated successfully." modal={modal} setModal={setModal}/>  */}
            {show && <ForgotPasswordDialogBox show={show} setShow={setShow} message={message} logout={true} />}
            {status && <ErrorDialog show={status} setShow={setStatus} message={message} />}
            {loader && <Loader />}
        </div>
    )
}