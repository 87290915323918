import React from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/Reducer";
import "./styles.scss";

type Tprop = {
  data: any;
  setVideoSrc?: any;
  handleImgModal?: any;
  setImg?: any;
  onClick?: any;
  isNoRecords?:boolean;
};

const LogCard: React.FC<Tprop> = ({
  data,
  setVideoSrc,
  handleImgModal,
  setImg,
  onClick,
  isNoRecords,
}) => {
  const userData: any = useSelector<RootState>((state) => state?.authData?.authData);

  return (
    <div className="logCard" onClick={(event: any) => onClick(event)}>
      {isNoRecords ?
        <div className="logCard-noRecord">
         <p>No Records</p>
      </div> :
      <>
       <div className="logCard-row">
        <p>Date:</p>
        <p className="logCard-value">
          {new Date(data?.time).toLocaleDateString()}
        </p>
      </div>
      <div className="logCard-row">
        <p>Incident Details:</p>
        <p className="logCard-value">{data?.incident_details}</p>
      </div>
      <div className="logCard-row">
        <p>Attachments:</p>

        {data.attachments ? (
          data.attachments.includes("videos") ? (
            <video
              src={data.attachments}
              className="attachment-img"
              onClick={() => {
                setVideoSrc(data.attachments);
                handleImgModal();
              }}
            />
          ) : (
            <img
              src={data.attachments}
              className="attachment-img"
              onClick={() => {
                setImg(data.attachments);
                handleImgModal();
              }}
              alt="Not found"
            />
          )
        ) : (
          <p className="logCard-value">No Attachments Provided</p>
        )}
      </div>
      <div className="logCard-row">
        <p>Comments:</p>
        {data?.comments ? (
          <p className="logCard-value">{data?.comments}</p>
        ) : (
          <p className="logCard-value">No comments</p>
        )}
      </div>
      <div className="logCard-row">
        <p>Reported by:</p>
        {data?.reported_by_id === userData?.hero_name ? (
          <p className="logCard-value">Me</p>
        ) : (
          <p className="logCard-value"> {data?.reported_by_id}</p>
        )}
      </div>
      <div className="logCard-row">
        <p>Mission Status:</p>
        <p className="logCard-value">
         {data.status.replace("_"," ")}
        </p>
      </div>
      <div className="logCard-row">
        <p>Address:</p>
        <p className="logCard-value">
        {data.location}
        </p>
      </div>
      </>}
    </div>
  );
};

export default LogCard;
