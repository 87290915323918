import { USER_DETAILS } from "../Actions/actions";


const initialState = {
    authData: []
};

export const AuthenticationReducer = (state: any = initialState, action: any) => {

    if (action.type === USER_DETAILS) {
        return {
            ...state,
            authData: action.payload
        }
    } else {
        return state;
    }
}
