
import React, { useCallback, useEffect, useState } from 'react'
import facebookLogo from "../../assets/icons/facebook-logo.png"
import googleLogo from "../../assets/icons/google-logo.png"
import appleLogo from "../../assets/icons/apple-logo.png"
import "./styles.scss"
// component display user (see detail on /example directory)
import {
    LoginSocialGoogle,
    LoginSocialFacebook,
    LoginSocialApple,
    IResolveParams,
} from 'reactjs-social-login'
import { decodeToken } from 'react-jwt'

const REDIRECT_URI = window.location.href;
type socialLoginProp = {
    signin: (val: any) => void
}
const Social: React.FC<socialLoginProp> = ({ signin }) => {
    const [provider, setProvider] = useState('')
    const [profile, setProfile] = useState<any>()

    const onLoginStart = useCallback(() => {
        // alert('login start')
    }, [])


    useEffect(() => {
        let token: any
        if(provider && profile){
            if(provider==='apple'){
               const id_token: any = decodeToken(profile?.authorization?.id_token)
               signin({ email: id_token?.email })
            }
            else{
            token = profile
            signin({ email: token.email })
        }
        } // eslint-disable-next-line
    }, [provider, profile]) 
    return (
        <>
            <div className='flexALign'>
                <LoginSocialFacebook
                    appId={process.env.REACT_APP_FB_APP_ID || ''}
                    onLoginStart={onLoginStart}
                    onResolve={({ provider, data }: IResolveParams) => {
                        setProvider(provider)
                        setProfile(data)
                    }}
                    onReject={(err) => {
                        console.log(err)
                    }}
                >
                    <button ><img src={facebookLogo} width='50px' height='50px' alt='' /></button>
                </LoginSocialFacebook>

                <LoginSocialGoogle
                    scope={'email'}
                    client_id={process.env.REACT_APP_GG_APP_ID || ''}
                    onLoginStart={onLoginStart}
                    onResolve={({ provider, data }: IResolveParams) => {
                        setProvider(provider)
                        setProfile(data)
                    }}
                    onReject={(err) => {
                        console.log(err)
                    }}
                >
                    <button ><img src={googleLogo} width='50px' height='50px' alt=''/></button>
                </LoginSocialGoogle>

                <LoginSocialApple
                    client_id={process.env.REACT_APP_APPLE_ID || ''}
                    scope={'name email'}
                    redirect_uri={REDIRECT_URI}
                    onLoginStart={onLoginStart}
                    onResolve={({ provider, data }: IResolveParams) => {
                        setProvider(provider);
                        setProfile(data);
                    }}
                    onReject={err => {
                        console.log(err);
                    }}
                >
                    <button ><img src={appleLogo} width='50px' height='50px' alt=''/></button>
                </LoginSocialApple>

            </div>
        </>
    )

}
export default Social