import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse } from "axios";
import { useDispatch } from "react-redux";
import * as toaster from 'toastr'
import { BASE_URL } from "../common/constant";
import { setAuthData } from "../redux/Actions/actions";
import { IErrorResponse, ISuccessResponse } from "./interface/ApiResponse.interface";
export class BaseService {

    httpClient: AxiosInstance = axios.create({ baseURL: BASE_URL });

    constructor() {

        // This interceptor is used for sending token in headers for Authorization.

        this.httpClient.interceptors.request.use((request: AxiosRequestConfig) => {
            if (!request.headers?.authorization) {
                const token = localStorage.getItem("accessToken") ? String("Bearer " + localStorage.getItem("accessToken")) : '';
                request.headers = { Authorization: token, ...request.headers };
            }
            return request;
        });

        this.httpClient.interceptors.response.use((response: AxiosResponse) => {
            return response;
        }, (error: any) => {
            const err = Promise.reject(error);
            if (error.response.status === 401) {
                toaster.error('session expired or invalid')
                localStorage.clear()
                this.autoLogout();
            }

            return err;
        });
    }

    public async getIp(): Promise<ISuccessResponse | IErrorResponse>  {
        try {
            const { data } = await this.httpClient.get("https://ipapi.co/json/");
            return data;
        }
        catch (error: any) {
            return error.response.data;
            // return parseAxiosError(error as AxiosError)
        }
    }
    async autoLogout(): Promise<void> {
        localStorage.clear();
        setTimeout(() => {
            window.open(window.location.origin, "_self");
            const dispatch = useDispatch()
            dispatch(setAuthData(undefined));
        }, 3000)
    }
}