import React, { useState } from "react";
import brandLogo from "../../../assets/parkingHeroLogo.svg";
import "./styles.scss";
import TextFieldLogin from "../../../components/TextFieldLogin";
import { useFormik } from "formik";
import { forgotPasswordSchema, forgotPasswordValidations } from "./validators";
import { AuthService } from "../../../services/auth.service";
import { STATUS } from "../../../common/constant";
import Loader from "../../../components/Loader";
import { ErrorDialog } from "../../../components/errorDialog";
import {useNavigate} from 'react-router-dom';


type TProp = {
    setEmail: any
}

export const EmailPage: React.FC<TProp> = ({ setEmail }) => {
    const navigate = useNavigate()
    const authenticationService = new AuthService();
    const [loader, setLoader] = useState<boolean>(false);
    const [show, setShow] = useState<boolean>(false)
    const [errorMessage, setErrorMessage] = useState('')

    const forgotPasswordSubmit = async (values: any): Promise<void> => {
        setLoader(true)
        try {
            const forgotPassword: any = {
                email: values?.email.toLowerCase()
            }
            const forgotPasswordResponse: any = await authenticationService.ForgotPassword(forgotPassword);
            setLoader(false)
            if (forgotPasswordResponse.status === STATUS.SUCCESS) {
                setShow(false)
                setEmail(values?.email.toLowerCase())
            } else {
                setShow(true)
                setErrorMessage(forgotPasswordResponse.message)
            }
        }
        catch (error: any) {
            setLoader(false)
        }
    }

    const registerFormik = useFormik({ initialValues: forgotPasswordSchema, validationSchema: forgotPasswordValidations, onSubmit: forgotPasswordSubmit });
    
    return (
        <div className="forgotPassword-page">
            <div className="logo-wrapper">
              <button onClick={()=>navigate('/')}><img src={brandLogo} alt="" /></button>
            </div>
            <div>
                <h1 className="heading">Forgot Password?</h1>
                <h6 className="heading-space">Please enter your email </h6>
            </div>
            <div className="email">
                <form onSubmit={registerFormik.handleSubmit}>
                    <div className="forget-email">
                        <TextFieldLogin
                            label='Email'
                            formik={{ ...registerFormik.getFieldProps('email') }}
                            error={registerFormik.touched.email && registerFormik.errors.email}
                            errorText={registerFormik.errors.email}
                        />
                    </div>
                    {/* <div className="field">
                <h4 className="lable-field">Email</h4>
                <input type="email" className="input-field"/>
            </div> */}
                    <button className="btn margin" type="submit">Submit</button>
                </form>
            </div>
            {show && <ErrorDialog show={show} setShow={setShow} message={errorMessage} />}
            {loader && <Loader />}
        </div>
    );
};
