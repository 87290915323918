import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import googleIMG from "../../assets/icons/googleAD.png"
import checkBox from "../../assets/icons/empty-checkbox.png"
import checkedBox from "../../assets/icons/checkbox.png"
import { SettingDialogBox } from "../../components/settingsDialog";
import "./styles.scss";
import { Navbar } from "../../components/navbar";
import {WarningDialogBox} from "../../components/warning_dialog"
import { useSelector } from "react-redux";
import { RootState } from "../../redux/Reducer";
import { STATUS } from "../../common/constant";
import { AuthService } from "../../services/auth.service";

export const SettingsPage: React.FC = () => {
    const navigate = useNavigate()
    const sellNumber: any = useSelector<RootState>((state) => state?.authData?.authData.sell_number)
    const [show, setShow] = useState<boolean>(false)
    const [show1, setShow1] = useState<boolean>(false)
    const [check, setCheck] = useState<boolean>(sellNumber);
    const authenticationService = new AuthService()
    const user_id = useSelector<RootState>((state) => state?.authData?.authData?.user_id?.toString())
    const isCredential = "False";
    const getProfileDeatails = async (): Promise<void> => {
        try {
            const profileResponse: any = await authenticationService.ProfileGet(
                user_id,
                isCredential
            );
            if (profileResponse.status === STATUS.SUCCESS) {
                setCheck(profileResponse.data.sell);
            } else if (profileResponse.statusCode === 401) {
                return;
            }
        } catch (error) {}
    };
    useEffect(() => {
        getProfileDeatails(); // eslint-disable-next-line
    }, [])
    
    return (
        <>
            <Navbar />
            <div className="settings-page">
                <div className="heading">
                    <h1>Settings</h1>
                </div>
                <br />
                <div className="main-container">
                    <button className="custom-btn" onClick={() => navigate("/changePassword")}>Change Password</button>
                    <button className="custom-btn " onClick={() => setShow(true)}>Delete My Account</button>
                    <div className="checkbox" onClick={() => setShow1(true)}>
                        <div className="checkbox-img">
                            {check ?
                                <img src={checkedBox} alt="" className="checked" />
                                : <img src={checkBox} alt="" className="check" />
                            }
                        </div>
                        <input type="checkbox" name="DSMPI" id="DSMPI" />
                        <label htmlFor="DSMPI">Do Not Sell My Personal Info</label>
                    </div>
                </div>
                <div className="space-lg"></div>
                <div className="googleAd">
                    <img src={googleIMG} alt="google-ads" className="google-ad" />
                </div>
            </div>
            {show && <SettingDialogBox show={show} setShow={setShow} />}
            {show1&& <WarningDialogBox setShow={setShow1} message={"Do you want to confirm ?"} check={check} setCheck={setCheck}/>}
        </>
    )
}