import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'


type IconProps = {
    icon: IconProp
}

const Icon: React.FC<IconProps> = ({icon}) => {
  return <FontAwesomeIcon className='action-icon' icon={icon}/>
}

export default Icon
