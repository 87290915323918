/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from "react";
import Icon from "../../../components/Icon";
import { Navbar } from "../../../components/navbar";
import {
  faSearch,
  faCommentDots,
  faQrcode,
  faClose,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import qrLogo from "../../../assets/qrLogo.png";
import filter from "../../../assets/icons/filter.png"
import "./styles.scss";
import ToggleButton from "../../../components/ToggleButton";
import Pagination from "../../../components/Pagination";
import { AuthService } from "../../../services/auth.service";
import { STATUS, MESSAGE_BOX_HEADER, FILTERVALUES, DELETE_CONTENT } from "../../../common/constant";
import { QRCodeCanvas } from "qrcode.react";
import { useNavigate } from "react-router-dom";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import { AdminMenuListComponent } from "../../../components/adminMenu"
import { MessageDialog } from "../../../components/messageDialog/messageDialog";
import Tooltip from '@mui/material/Tooltip';
import Loader from "../../../components/Loader";
import downBtn from "../../../assets/icons/up-arrow.png";
import * as XLSX from "xlsx";
import { saveAs } from "file-saver";
import autoTable from "jspdf-autotable";
import moment from "moment";
import UserDetailsLog from "../../../components/UserDetailsLog";
import { AdDialogBox } from "../../../components/adPageDialog";
import { DeleteDialogBox } from "../../../components/deleteConfirmationDialogBox";

type UserDetailsProp = {
  user_id: string
  hero_name: string
  email_id: string
  phone_number: string
  zip_code: number
  is_active: boolean
  last_logged_on: string
  blocked: boolean
  license_plate: string
  sell: boolean
}

const LandingPage: React.FC = () => {
  const [userData, setUserData] = useState<Array<UserDetailsProp>>();
  const [userId, setUserId] = useState<string>('')
  const [name, setName] = useState<string>('')
  const [userNumber, setUserNumber] = useState<string>('')
  const [filterData, setFilterData] = useState<boolean>(false);
  const [sell, setSell] = useState<string>(FILTERVALUES[2]);
  const anchorRef = useRef<any>(null);
  const [page, setPage] = useState<number>(1)
  const [limit] = useState<number>(10)
  const [count, setCount] = useState<number>(1)
  const [showQR, setShowQR] = useState<boolean>(false);
  const [sendMessageDialog, setSendMessageDialog] = useState<boolean>(false);
  const [loader, setLoader] = useState<boolean>(false);
  const navigate = useNavigate()
  const authenticationService = new AuthService();
  const [searchValue, setSearchValue] = useState<string>("")
  const [exportToggle, setExportToggle] = useState<boolean>(false)
  const [getLog, setGetLog] = useState<any>()
  const [succesfullMissions, setSuccesfullMissions] = useState<string>("")
  const [width, setWidth] = React.useState<number>(window.innerWidth);
  const [deleteBox, setDeleteBox] = useState<boolean>(false);
  const [confirm, setConfirm] = useState<boolean>(false);
  const [deleteUserId, setDeleteUserId] = useState<string>('')

  const userDetails = async () => {
    setLoader(true)
    const userResponse: any = await authenticationService.userManagement(page, limit, searchValue, sell);
    setLoader(false)
    if (userResponse.status === STATUS.SUCCESS) {
      setUserData(userResponse.data.user_details);
      setCount(userResponse.data.num_page);
      setSuccesfullMissions(userResponse.data.successful_missions);
    }
  }

  const downloadImg = async () => {
    const input = document.getElementById('divToPrint') as HTMLElement;
    html2canvas(input)
      .then((canvas: { toDataURL: (arg0: string) => any; }) => {
        const imgData = canvas.toDataURL('image/png');
        const pdf = new jsPDF('p', 'pt', 'letter', true);
        pdf.addImage(imgData, 'JPEG', 160, 150, 300, 420);
        pdf.save(`QRcode-${name}.pdf`);
      });
  }

  const getMessageLog = async () => {
    try {
      setLoader(true)
      const logResponse: any = await authenticationService.adminMessageLog()
      setGetLog(logResponse.data.message_log);
      setLoader(false)
    } catch (error) {

    }
  }

  const deleteUser = async (id: string) => {
    setDeleteBox(true)
    setDeleteUserId(id)
  }

  const confirmDeleteUser = async (id: string) => {
    try {
      const payload = {
        "user_id": id
      }
      const res = await authenticationService.adminDeleteUser(payload)
      res.status === "success" && userDetails();
      setLoader(false)
    } catch (error) {

    }
  }

  useEffect(() => {
    if (confirm) {
      setLoader(true)
      confirmDeleteUser(deleteUserId);
    }
  }, [confirm])



  const saveAsPDF = () => {
    const doc = new jsPDF();
    let arrayData = []
    for (const log of getLog) {
      arrayData.push([moment(log.Date).format('L'),
      log["Type of message"],
      log["Receipient of message"], log.Content
      ])
    }
    autoTable(doc, {
      head: [["Date", "Type of message", "Recipient", "Content"]],
      body: arrayData,
      columnStyles: { 3: { cellWidth: 75 } },
      headStyles: {
        fontStyle: "bold",
        font: "Helvetica",
        fillColor: "#000000",
        textColor: "#FFFFFF",
        halign: "center",
        valign: "middle",
        cellPadding: 4,
        fontSize: 12,
      },
      bodyStyles: {
        halign: "center",
        valign: "middle",
        cellPadding: 4,
        fontSize: 12,
      },
    });
    doc.save("Message_log.pdf");
  }

  const saveAsExcel = () => {
    let logs = getLog
    for (const x of logs) {
      x.Date = moment(x?.Date).format('L');
    }
    const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const worksheet = XLSX.utils.json_to_sheet(logs);
    const workBook = {
      Sheets: {
        data: worksheet,
      },
      SheetNames: ["data"],
    };
    const excelBuffer = XLSX.write(workBook, {
      bookType: "xlsx",
      type: "array",
    });
    const data = new Blob([excelBuffer], { type: EXCEL_TYPE });
    saveAs(data, "Message_log.xlsx");
  }

  useEffect(() => {
    userDetails();
  }, [page, searchValue, sell]);

  useEffect(() => {
    getMessageLog();

    const handleResizeWindow = () => setWidth(window.innerWidth);
    window.addEventListener("resize", handleResizeWindow);
    return () => {
      window.removeEventListener("resize", handleResizeWindow);
    };
  }, [])

  return (
    <>
      <div className="admin-landing-page" style={false ? { display: "none" } : { overflow: 'auto' }}>
        <Navbar noAds Admin={true} />
        <div className="admin-page-content" >
          <h1 className="heading">Welcome admin</h1>
          <div className="table-heading-and-search">
            <h1 className="heading">user management</h1>
            <div className="search-container">
              <p className="parking-missions">
                Number of Successful Parking Missions: {succesfullMissions}{" "}
              </p>
              <div className="search-input">
                <input type="text" placeholder="Search Hero Names" value={searchValue} onChange={(e: any) => {
                  setSearchValue(e.target.value)
                }} />
                <Icon icon={faSearch} />
              </div>
            </div>
          </div>
          {width < 585 ?
            <>
              {userData?.length !== 0 ? <>{userData?.map((user: UserDetailsProp, index: number) => {
                return (
                  <UserDetailsLog
                    key={index}
                    user={user}
                    onClick={() => {
                      setUserId(user.user_id)
                      setName(user.hero_name)
                      setUserNumber(user.phone_number);
                    }}
                    setSendMessageDialog={setSendMessageDialog}
                    setShowQR={setShowQR}
                  />
                )
              })}</> : <>
                <UserDetailsLog
                  user={undefined}
                  isNoRecords={true}
                /></>}
            </> :
            <>
              <div className="user-table">
                <table>
                  <thead>
                    <tr>
                      <th>Hero Name</th>
                      <th>Email id</th>
                      <th>QR Code</th>
                      <th>Phone</th>
                      <th>Zip Code</th>
                      <th>Last Logged On</th>
                      <th>Send Message</th>
                      <th>Block</th>
                      <th className="filter">Opt Out of Sale <img src={filter} onClick={() => { setFilterData(!filterData) }} ref={anchorRef} alt="" /></th>
                      <th>License Plate</th>
                      <th>Delete</th>
                    </tr>
                  </thead>
                  <tbody>
                    {filterData && <AdminMenuListComponent show={filterData} setShow={setFilterData} anchorRef={anchorRef} sell={sell} setSell={setSell} />}
                    {userData?.length !== 0 ? <>{userData?.map((user: UserDetailsProp, index) => {
                      return (
                        <tr key={index} onClick={() => {
                          setUserId(user.user_id)
                          setName(user.hero_name)
                          setUserNumber(user.phone_number);
                        }}>
                          <td className="ellipuserName" onClick={() => { navigate("/admin/userManagement", { state: user }) }}><abbr title={user.hero_name}>{user.hero_name}</abbr></td>
                          <td className="ellipEmail" onClick={() => { navigate("/admin/userManagement", { state: user }) }}><abbr title={user.email_id}>{user.email_id}</abbr></td>
                          <td onClick={() => setShowQR(true)}>
                            <Icon icon={faQrcode} />
                          </td>
                          <td onClick={() => { navigate("/admin/userManagement", { state: user }) }}>{user.phone_number}</td>
                          <td onClick={() => { navigate("/admin/userManagement", { state: user }) }}>{user.zip_code}</td>
                          <td onClick={() => { navigate("/admin/userManagement", { state: user }) }}>{new Date(user.last_logged_on).toLocaleDateString()}</td>
                          <td onClick={() => setSendMessageDialog(true)}>
                            <Tooltip className="tooltip" title="Click on this button to send Individual text message to Heroes" placement="bottom-end" arrow >
                              <span >
                                <Icon icon={faCommentDots} />
                              </span>
                            </Tooltip>
                          </td>
                          <td>
                            <ToggleButton value={user.blocked} heroName={user.hero_name} userId={user.user_id} />
                          </td>
                          <td className="switch-field">
                            <p>{user.sell ? "YES" : "NO"}</p>
                          </td>
                          <td onClick={() => { navigate("/admin/userManagement", { state: user }) }}>{user.license_plate || '-'}</td>
                          <td onClick={() => deleteUser(user.user_id)}>
                            <Icon icon={faTrash} />
                          </td>
                        </tr>
                      );
                    })}</> :
                      <tr>
                        <td colSpan={9}>No Records</td></tr>}
                  </tbody>
                </table>
              </div>
            </>}
          <div className="buttons-and-pagination">
            <div className="buttons">
              <button className="btn" onClick={() => setExportToggle(!exportToggle)}>Export Message Log <img src={downBtn} alt="arrow" style={exportToggle ? { transform: "none" } : { transform: "rotate(180deg)" }} />
              </button>
              {exportToggle && <div className="btnWrap">
                <button className="btn" onClick={() => saveAsPDF()}>Export as PDF</button>
                <button className="btn" onClick={() => saveAsExcel()}>Export as Excel</button>
              </div>}
              <button className="btn" onClick={() => { navigate("/admin/offers") }}>Upload Offers PDF</button>
            </div>
            <div className="pagination-wrapper">
              <Pagination setPage={setPage} page={page} count={count} />
            </div>
          </div>
        </div>

        <div className={showQR ? "view-qr-code-dialog" : "hide-element"}>
          <div className="dialog">
            <div className="header">
              <h6>{name}</h6>
              <div className="closeIcon" onClick={() => setShowQR(false)}>
                <Icon icon={faClose} />
              </div>
            </div>
            <div className="qrcode">
              <QRCodeCanvas
                value={`https://lbparkinghero.com/timeforAction/${userId}`}
                size={250}
                bgColor={"#ffffff"}
                fgColor={"#000000"}
                level={"H"}
                includeMargin={false}
                imageSettings={{
                  src: qrLogo,
                  height: 60,
                  width: 60,
                  excavate: true,
                }}
              />
            </div>
            <button onClick={() => { downloadImg() }}>Download</button>
          </div>
        </div>
        <div className="ToPrint">
          <div className="Download" id="divToPrint" >
            <h1>STOP PARKING TICKETS</h1>
            <div>
              {/* <p>- QR Code Generation with ReactJS POC by Jayasin Prabhu C</p> */}
              {/* <div className="text">
                <img className='logo' src={brandLogo} alt="" />
                <h1>SCAN ME!!!</h1>
                <p>To Notify Drivers that their Car is about</p>
                <p>to Get a parking ticket </p>
                <h1>QR Code</h1>
              </div> */}
              <div className="download-qr">
                <QRCodeCanvas
                  value={`https://lbparkinghero.com/timeforAction/${userId}`}
                  size={330}
                  bgColor={"#ffffff"}
                  fgColor={"#000000"}
                  level={"H"}
                  includeMargin={false}
                  imageSettings={{
                    src: qrLogo,
                    height: 60,
                    width: 60,
                    excavate: true,
                  }}
                />
              </div>
            </div>
            <h1> Scan Me! </h1>
            {/* <h1> Become A </h1>
            <h1> Parking Hero </h1> */}
          </div>
        </div>
      </div>
      {sendMessageDialog && <MessageDialog userNumber={userNumber} setSendMessageDialog={setSendMessageDialog} displayContent={MESSAGE_BOX_HEADER.message1} />}
      {loader && <Loader />}
      {deleteBox && <DeleteDialogBox show={deleteBox} setShow={setDeleteBox} confirm={confirm} setConfirm={setConfirm} message={DELETE_CONTENT.deleteConfirmationMessage} />}
      {confirm && <AdDialogBox show={confirm} setShow={setConfirm} message={DELETE_CONTENT.deleteMessage} />}

    </>
  );
};

export default LandingPage;
