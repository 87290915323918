import React from "react";
import "./style.scss";
import Icon from "../Icon";
import { faClose } from "@fortawesome/free-solid-svg-icons";

type Tprop = {
    diplayContent: string
    setSuccerrPopUp: any
    showDialog: boolean
}



export const SuccerrPopUp: React.FC<Tprop> = ({ showDialog, setSuccerrPopUp, diplayContent }) => {
    return (
        <div className="blocking-confirmation-dialog">
            <div className="blocking-confirmation">
                <p>
                    {diplayContent}
                </p>
                <div className="closeIcon" onClick={() => {setSuccerrPopUp(false)}}>
                    <Icon icon={faClose} />
                </div>
                <div className="confirmation-buttons-wrapper">
                    <button onClick={() => {
                        setSuccerrPopUp(false)
                    }}>Ok</button>
                </div>
            </div>
        </div>
    )
}