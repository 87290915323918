/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Navbar } from "../../components/navbar";
import "./styles.scss";
import { Dropdown } from "../../components/contactDropdownMenu";
import { numerOfSuccessFull_Options, STATUS } from "../../common/constant";
import closeIcon from "../../assets/icons/upload.png"
import { FileUploader } from "react-drag-drop-files";
import { AuthService } from "../../services/auth.service";
import Loader from "../../components/Loader";
import { SuccessDialogBox } from "../../components/successDialog";
import { ErrorDialog } from "../../components/errorDialog";
import CompletedIMG from "../../assets/icons/completed.png"
import { DownloadPdf } from "../../components/downloadPdf";


export const OffersPage: React.FC = () => {
    const authenticationService = new AuthService();
    const [sucessDialog, setSucessDialog] = useState(false);
    const [errorDialog, setErrorDialog] = useState(false)
    const [message, setMessage] = useState("")
    const [loader, setLoader] = useState(false)
    const [selectedOption, setSelectedOption] = useState<any>();
    const [selectedValue, setSelectedValue] = useState<any>();
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const [file, setFile] = useState<any>()
    const [count, setCount] = useState(false)
    const [apiFile, setapiFile] = useState<any>();

    const fileTypes = ["PDF"];
    const handlechange = (file: any) => {
        setFile(file);
        if (file.length > 0) {
            setCount(true)
        }
        else {
            setCount(false)
        }
    }
    const handleSubmit = async () => {
        try {
            setLoader(true)
            const formData = new FormData();
            formData.append("upload", file[0] || "");
            formData.append("missions", selectedValue || "");

            const uploadDataResponse: any = await authenticationService.uploadOffers(formData);
            setLoader(false)
            if (uploadDataResponse.status === STATUS.SUCCESS) {
                setSucessDialog(true);
                setMessage(uploadDataResponse.message);

            }
            else {
                setErrorDialog(true);
                setMessage(uploadDataResponse.message);
            }
        } catch (error) {

        }
    };

    useEffect(() => {
        setCount(false);
        setFile(null);
    }, [selectedOption])


    return (
        <>
            <Navbar Admin={true} noAds />
            <div className="offer-page">
                <div className="heading">
                    <h1>Number of Successful Parking Hero Missions</h1>
                </div>
                <div className="space"></div>
                <div className="space"></div>
                <div className="dropdown">
                    <Dropdown isOpen={isOpen} setIsOpen={setIsOpen} selectedOption={selectedOption}
                        setSelectedOption={setSelectedOption} setSelectedValue={setSelectedValue} options={numerOfSuccessFull_Options} values={true}
                        setapiFile={setapiFile}  setLoader={setLoader}/>
                </div>
                <div className="space"></div>
                <div className="hidden-container">
                    <div className="containers important flex-center">
                        {apiFile ?
                         <DownloadPdf url={apiFile}  selectedValue={selectedValue} setapiFile={setapiFile}/> :
                            <div className="items drag-area">
                                <FileUploader
                                    multiple={true}
                                    handleChange={handlechange}
                                    name="file"
                                    types={fileTypes}
                                >
                                    {!count ? <><div className="close">
                                        <div className="btn-close" >
                                            <img src={closeIcon} alt="not-found" />
                                        </div>
                                    </div>
                                        <p className="description">Drag your file here</p>
                                        <p className="or">or</p>
                                        <button className="btns">Browse</button></>
                                        : <>
                                            {/* <DownloadPdf url={file[0]?.name}  selectedValue={selectedValue} count={count} /> : */}
                                            <img src={CompletedIMG} alt="" width={100} height={100} />
                                            <p style={{ color: "#000", fontSize: "22px", marginBottom: "20px" }}>{file[0]?.name}</p>
                                        </>}
                                </FileUploader>
                            </div> 
                           }
                    </div>
                    {(file && selectedOption) ?
                        <div>
                            <button className="btn"
                                onClick={handleSubmit}
                                disabled={file ? false : true}
                                style={!file ? { cursor: "not-allowed" } : { cursor: "pointer" }}
                            >Upload</button>
                        </div> : <></>
                    }
                </div>
            </div>
            {loader && <Loader />}
            {sucessDialog && <SuccessDialogBox show={sucessDialog} message={message} setShow={setSucessDialog} logout={false} />}
            {errorDialog && <ErrorDialog show={errorDialog} setShow={setErrorDialog} message={message} />}
        </>
    )
}
