import React, { useState } from "react";
import "./styles.scss";
import Icon from "../Icon";
import { faClose } from "@fortawesome/free-solid-svg-icons";
import { AuthService } from "../../services/auth.service"
import { STATUS } from "../../common/constant";
import Loader from "../Loader"
import { SuccessDialogBox } from "../successDialog";

type Tprop = {
    displayContent: string;
    setSendMessageDialog: any;
    setMessageForward?: any;
    messageForward?: any;
    broadcast?: boolean;
    userNumber?: string;
};

export const MessageDialog: React.FC<Tprop> = ({ setSendMessageDialog, displayContent, setMessageForward, broadcast,userNumber }) => {
  const [message, setMessage] = useState<string>("");
  const [loader, setLoader] = useState<boolean>(false)
  const [show, setShow] = useState<boolean>(false)
  const authenticationService = new AuthService();
    
  const broadcastFunc = async () => {
    try {
        setLoader(true)
        const broadcastData = {
          role: "admin",
          message: message
        }
        const broadcastResponse: any = await authenticationService.adminBroadcastPost(broadcastData)
        setLoader(false)
        if (broadcastResponse.status === STATUS.SUCCESS) {
          setShow(true)
        }
    } catch (error) {
        
    }
}
const individualMessage = async () => {
    try {
        setLoader(true)
        const individualUserMessage = {
            phone: userNumber,
            message: message,
        };
        const broadcastResponse: any = await authenticationService.adminIndividualMessage(individualUserMessage)
        setLoader(false)
        if (broadcastResponse.status === STATUS.SUCCESS) {
          setShow(true)
        }
    } catch (error) {
      
    }
  }
    
    return (
        <div className="send-message-dialog">
            <div className="send-message">
                <div
                    className="closeIcon"
                    onClick={() => setSendMessageDialog(false)}>
                    <Icon icon={faClose} />
                </div>
                <p
                    style={{
                        marginBottom: "9px",
                        fontWeight: "bold",
                        float: "left",
                    }}>
                    {displayContent}
                </p>
                <textarea
                    value={message}
                    onChange={(event) => setMessage(event.target.value)}
                />
                <div className="buttons">
                    <button onClick={() => setSendMessageDialog(false)}>
                        Cancel
                    </button>
                    <button
                        onClick={() =>
                            broadcast ? broadcastFunc() : individualMessage()
                        }>
                        Send
                    </button>
                </div>
            </div>
            {loader && <Loader />}
            {show && (
                <SuccessDialogBox
                    show={show}
                    message={
                        broadcast ? "The  Broadcast Message has been sent successfully" : "The Message has been sent successfully"
                    }
                    setShow={setSendMessageDialog}
                />
            )}
        </div>
    );
}
