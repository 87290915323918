import React, { useState } from "react";
import "./styles.scss";
import AlertIcon from "../../assets/icons/warning.png"
import { STATUS } from "../../common/constant";
import { AuthService } from "../../services/auth.service";
import { SuccessDialogBox } from "../../components/successDialog/index"
import { ErrorDialog } from "../errorDialog";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/Reducer";
import Loader from "../../components/Loader";

type Tprop = {
    show: boolean
    setShow: (open: boolean) => void
}

export const SettingDialogBox: React.FC<Tprop> = ({ show, setShow }) => {
    const [loader, setLoader] = useState<boolean>(false)
    const userData: any = useSelector<RootState>((state) => state?.authData?.authData)
    const authenticationService = new AuthService();
    const message = "Do You Really Want To Delete Your Account?"
    const [successDialog, setSuccessDialog] = useState<boolean>(false)
    const [messages, setMessages] = useState<string>('')
    const [errorDialog, setErrorDialog] = useState<boolean>(false);
    const detailedMessage = "Deleting your account will remove all your information from the application. You need to re register again as a new User"
    

    const Delete = async () => {
        try {
            const DeleteAccountData: any = {
                user_id: userData.user_id.toString(),
                is_active: false
            }
            setLoader(true)
            const DeleteAccount: any = await authenticationService.DeleteAccount(DeleteAccountData);
            setLoader(false)
            if (DeleteAccount.status === STATUS.SUCCESS) {
                setSuccessDialog(true)
                setMessages(DeleteAccount.message)
            }
            else {
                setErrorDialog(true)
                setMessages(DeleteAccount.message)
            }
        } catch (error) {
            
        }
    }
    
    return (
        <div>
            <div className="settingsDialog">
                <div className="box">
                    <img className="dialog-heading-icon" src={AlertIcon} alt={"not found"} />
                    <p className="text">{message}</p>
                    <p className="text">{detailedMessage}</p>
                    <div className="space"></div>
                    <div className="btns">
                        <button className="dialog-btn" onClick={() => { setShow(false) }}>No</button>
                        <button className="dialog-btn" onClick={() => { Delete() }}>Yes</button>
                    </div>
                </div>
            </div>

            {successDialog && <SuccessDialogBox show={successDialog} setShow={setSuccessDialog} message={messages}
                logout={true} />}
            {errorDialog && <ErrorDialog show={errorDialog} setShow={setErrorDialog} message={messages} />}
            {loader && <Loader/>}
        </div>
    );
};