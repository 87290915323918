/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from "react";
import { Navbar } from "../../components/navbar";
import ExclamationMark from "../../assets/icons/exclamation-mark.png";
import Arrow from "@material-ui/icons/ArrowBack"
import IIcon from "../../assets/icons/i-icon.png"
import { RefDialogBox } from "../../components/refDialog";
import { ImgModalBox } from "../../components/imgModal";
import "./styles.scss";
import { HeroStatMenuList } from "../../components/heroStatMenuList";
import { useNavigate } from "react-router-dom";
import { AuthService } from "../../services/auth.service";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/Reducer";
import { STATUS } from "../../common/constant";
import Pagination from "../../components/Pagination";
import Loader from "../../components/Loader";
import LogCard from "../../components/PHRescueLog";
import { CommonMessageDialog } from "../../components/CommonMessageDialog";
import GoogleAdComponent from "../../components/GoogleAdComponent";
import { LicensePlatePopUp } from "../../components/LicensePlateDialog";
import * as toastr from "toastr";

export const AccountPage: React.FC = () => {

    const messages = ["This is the total number of incidents that you have reported and the incidents that have been reported to you",
        "This is the total number of missions, both reported by you and reported to you that has resulted in either the driver/you being able to avoida parking ticket",
        "This is the total number of people who have used your referral code during registration",
        "You can use your Rescue Log to keep track of Parking Hero rescue operations. When you send another user a notifcation or someone else sends you a notifcation it is logged here"]
    const [page, setPage] = useState<number>(1);
    const [data, setData] = useState<any>([]);
    const [limit] = useState<number>(5);
    const [show1, setShow1] = useState<any>(false)
    const [show2, setShow2] = useState<any>(false)
    const [show3, setShow3] = useState<any>(false)
    const [show4, setShow4] = useState<any>(false)
    const [show5, setShow5] = useState<boolean>(false)
    const [show6, setShow6] = useState<boolean>(false)
    const anchorRef1 = useRef<any>(null);
    const anchorRef2 = useRef<any>(null);
    const anchorRef3 = useRef<any>(null);
    const anchorRef4 = useRef<any>(null);
    const anchorRef5 = useRef<any>(null);
    const anchorRef6 = useRef<any>(null);
    const [show, setShow] = useState<boolean>(false)
    const [licensePlatePopUp, setLicensePlatePopUp] = useState<boolean>(false)
    const [showImg, setShowImg] = useState<boolean>(false)
    const [img, setImg] = useState<any | null>(null)
    const [videoSrc, setVideoSrc] = useState<any | null>(null);
    const authenticationService = new AuthService();
    const userData: any = useSelector<RootState>((state) => state?.authData?.authData)
    const [heroStatDetails,setHeroStatDetails] = useState<any>([])
    const [count, setCount] = useState<number>(0)
    const [loader, setLoader] = useState<boolean>(false)
    const [width, setWidth] = React.useState<number>(window.innerWidth);
    const [offersPopup, setOffersPopup] = useState<boolean>(false)
    const [licensePlateError, setLicensePlateError] = useState<boolean>(false);
    const [licensePlateValue, setLicensePlateValue] = useState<string>('');

    const messageForAccount = `Welcome ${userData?.hero_name} ! You can use the buttons below to Access your QR Code, Referral Code and see your offers`
    const messageForLicensePlate = `Hey ${userData?.hero_name}! Enter the parked car's license plate here to send them a DM.`;

    const getAccount = async () => {
        try {
            setLoader(true)
            const accountResponse: any = await authenticationService.getAccount(userData.user_id, page, limit);
            setLoader(false)
            if (accountResponse.status === STATUS.SUCCESS) {
                setData(accountResponse?.data?.account_details?.sort((a:any,b:any)=> a?.time < b?.time ? 1 : a?.time > b?.time ? -1 : 0 ))
                setHeroStatDetails(accountResponse.data.herostat_details)
                setCount(accountResponse.data.num_page || 1) 
            }
        }
        catch (error) {
            
        }
    }


    const handleInputChange = (event: any) => {
        setLicensePlateValue(event.target.value);
    };

    const checkLicensePlate = async () => {
        try {
            if (licensePlateValue) {
                setLoader(true);
                const licensePlateResponse: any = await authenticationService.getLicensePlateDetails(licensePlateValue.trim());
                if (licensePlateResponse.status === STATUS.SUCCESS) {
                    setLoader(false);
                    setLicensePlateError(false);
                    if (licensePlateResponse.data.user_id !== '0') {
                        const userId = licensePlateResponse.data.user_id;
                        navigate(`/timeForAction/${userId}`);
                    } else {
                        toastr.error('You cannot choose your own license plate');
                    }
                } else {
                    setLicensePlateError(true);
                    setLoader(false);
                }
            }
        } catch (error) {

        }
    }

    const offers = async () => {
        try {
            const offerResponse: any = await authenticationService.Offers();
            if (offerResponse.status === STATUS.SUCCESS) {
                if(offerResponse.data === "No offers available for you"){
                    setOffersPopup(true)
                }else{
                    window.open(offerResponse.data, '_blank')
                }
            }
        } catch (error) {

        }
    }

    useEffect(() => {
        getAccount()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [page])

    const navigate = useNavigate()

    const handleIncident = (eve: any, ele: any) => {
        if (!!eve.target?.src) {
            //pass
        } else {
            navigate("/incidentDetails", { state: ele })
        }
    }

    const handleImgModal = () => {
        setShowImg(true)
    }

    useEffect(() => {
        getAccount()
        window.scrollTo(0, 0)
        
     const handleResizeWindow = () => setWidth(window.innerWidth);
        window.addEventListener("resize", handleResizeWindow);
        return () => {
        window.removeEventListener("resize", handleResizeWindow);
        };
    }, [])

    return (
        <>
            <div className="account-page">
                <Navbar account={true} />
                <div className="table-header-wrapper">
                    <div className="table-head-sidebar"></div>
                    <div className="table-account-head">
                                <div className="heading-group">
                                    <div className="license-plate-input-group">
                                        <div className="license-plate-search-head">
                                            <h5 className="license-plate-search">Enter License Plate</h5>
                                            <img src={ExclamationMark} className="license-plate-info" onMouseEnter={() => setShow6(true)} onMouseLeave={() => setShow6(false)} ref={anchorRef6} alt="not found" />
                                            <HeroStatMenuList show={show6} setShow={setShow6} position={"left top"} anchorRef={anchorRef6} message={messageForLicensePlate} />
                                        </div>
                                        <div className="license-plate-search-field">
                                            <input type="text" className="input-field input-field-license-plate" value={licensePlateValue} onChange={handleInputChange} />
                                            <button type="button" className="license-plate-btn" onClick={() => checkLicensePlate()}>Submit</button>
                                        </div>
                                        {licensePlateError && <div className="error-message-container">
                                            <p className="license-plater-error-text">This license plate is not registered with Parking Hero yet! ☹️ Please try again later.</p>
                                       </div>}
                                    </div>
                                    <div className="your-account-head">
                                        <h5 className="account-heading">YOUR ACCOUNT</h5>
                                        <img src={ExclamationMark} className="license-plate-info" onMouseEnter={() => setShow5(true)} onMouseLeave={() => setShow5(false)} ref={anchorRef5} alt="not found" />
                                        <HeroStatMenuList show={show5} setShow={setShow5} position={"left top"} anchorRef={anchorRef5} message={messageForAccount} />
                                    </div>
                                </div>
                    </div>
                </div>
                <div className="account-con">
                    <div className="btn-wraper" >
                        <div className="acc-btn">
                            <button className="btn" onClick={() => { navigate("/qrCode") }} >YOUR QR Code</button>
                        </div>
                        <div className="acc-btn">
                            <button className="btn" onClick={() => setShow(true)} >Share Referral code</button>
                        </div>
                        <div className="acc-btn">
                            <button className="btn" onClick={() => {
                                offers()
                            }} >Your Offers</button>
                        </div>
                        <div className="acc-btn">
                            <button className="btn" onClick={() => setLicensePlatePopUp(true)} >Your License Plate</button>
                        </div>
                    </div>
                    <div className="table account_table" >
                        <div className="table-header-wraper">
                        <div className="only_header">
                                <h5 className="account-table-heading">PH Rescue Log</h5>
                                <img src={ExclamationMark} className="license-plate-info" onMouseEnter={() => setShow4(true)} onMouseLeave={() => setShow4(false)} ref={anchorRef4} alt="not found" />
                            </div>
                            <HeroStatMenuList show={show4} setShow={setShow4} position={"left top"} anchorRef={anchorRef4} message={messages[3]} />
                        </div>
                        {width < 585 ? 
                        <>
                        {data?.length !== 0 ? <>
                       {data?.map((element:any, index:any)=>{
                            return(
                                 <LogCard data={element} key={index} setImg={setImg} handleImgModal={handleImgModal} setVideoSrc={setVideoSrc} onClick={(event:any)=> handleIncident(event,element)}/>
                            )
                        })}
                        </> :<LogCard data={undefined}   isNoRecords={true}/>}
                        </> :
                        <>
                         <div className="table-responsive">
                            <table className="table-wraper">
                                <thead className="head-wraper">
                                    <tr>
                                        <th style={{ width: "15%" }} ><p>Incident Details</p></th>
                                        <th style={{ width: "25%" }}><p>Attachments</p></th>
                                        <th style={{ width: "25%" }}><p>Comments</p></th>
                                        <th style={{ width: "20%" }}><p>Date</p></th>
                                        <th style={{ width: "15%" }}><p>Reported by</p></th>
                                    </tr>
                                </thead>
                                {data?.length !== 0 ? <> {data?.map((element: any, index: number) => {
                                    return (
                                        <tbody
                                            key={index}
                                            id={`${index}`}
                                            onClick={(event: any) => { handleIncident(event, element) }}
                                            className="body-wraper" style={index % 2 ? { backgroundColor: "#F1F0F0", cursor: "pointer" } : { backgroundColor: "white", cursor: "pointer" }}
                                        // onMouseOver={(event) => {console.log(event.target)}}
                                        >
                                            <tr style={{ width: "15%", display: "flex", justifyContent: "center" }}><td>{element.incident_details}</td></tr>
                                            <tr style={{ width: "25%", display: "flex", justifyContent: "center" }}>
                                                <td>
                                                    {element.attachments ?
                                                      element.attachments.includes("videos") ? 
                                                      <video src={element.attachments} className="attachment-img" onClick={() => { setVideoSrc(element.attachments); handleImgModal(); }} /> :
                                                        <img src={element.attachments} className="attachment-img" onClick={() => { setImg(element.attachments); handleImgModal(); }} alt="Not found" />
                                                        : <p>No Attachments Provided</p>
                                                    }
                                                </td>
                                            </tr>
                                            <tr style={{ display: "flex", justifyContent: "center", width: "25%" }}><td className="elementTd">{element.comments ? element.comments : <p>No comments</p>}</td></tr>
                                            <tr style={{ display: "flex", justifyContent: "center", width: "20%" }}><td>{new Date(element.time).toLocaleDateString()}</td></tr>
                                            <tr style={{ display: "flex", width: "15%", justifyContent: "center" }}><td>{element.reported_by_id === userData?.hero_name ? "Me" : element.reported_by_id}</td></tr>
                                        </tbody>
                                    )
                                })}</> : <tbody><tr><td colSpan={5}>No Records</td></tr></tbody>}
                            </table>
                        </div>
                        </>}
                        <Pagination setPage={setPage} page={page} count={count} />
                        <div className="table-header-wraper">
                            <h5 className="account-table-heading" style={{ fontWeight: 700 }}>Hero Stats</h5>
                        </div>
                        <div  className="table-responsive">
                        <   table className={width < 585 ? "table-wraper active" : "table-wraper"}>
                            <thead>
                                <tr className="head-wraper">
                                    <th style={{ width: "25%" }} ><p>Hero Status</p></th>
                                     {width > 585 && <th style={{ width: "50%" }}></th>}
                                    <th style={{ width: "25%",maxWidth: "250px" }}><p>Number</p></th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr className="body-wraper" style={{ backgroundColor: "white", width: "100%" }}>
                                    <td className="body-content" style={{ display: "flex", justifyContent: "center", width: "25%" }} >
                                        <p>Total Parking Hero Missions</p>
                                        <img ref={anchorRef1} onMouseEnter={() => setShow1(true)} onMouseLeave={() => setShow1(false)} src={IIcon} alt="not found" />
                                        <HeroStatMenuList show={show1} setShow={setShow1} anchorRef={anchorRef1} message={messages[0]} />
                                    </td>
                                     {width > 585 && <td style={{ width: "50%" }}></td>}
                                    <td style={{ textAlign: "center", width: "25%",maxWidth: "250px" }}>
                                        <p>{heroStatDetails?.total_missions || 0}</p>
                                    </td>
                                </tr>
                                <tr className="body-wraper" style={{ backgroundColor: "#F1F0F0" }}>
                                    <td className="body-content" style={{ display: "flex", justifyContent: "center", width: "25%" }}>
                                        <p>Successful Parking Hero Missions</p>
                                        <img ref={anchorRef2} onMouseEnter={() => setShow2(true)} onMouseLeave={() => setShow2(false)} src={IIcon} alt="not found" />
                                        <HeroStatMenuList show={show2} setShow={setShow2} anchorRef={anchorRef2} message={messages[1]} />
                                    </td>
                                    {width > 585 && <td style={{ width: "50%" }}></td>}
                                    <td style={{ width: "25%",maxWidth: "250px" }}>
                                        <div className="sub-table" >
                                            <div className="sub-table-body">
                                                <table>
                                                    <tbody>
                                                        <tr style={{ width: "50%" }}><td>Send </td>
                                                            <td><Arrow style={{ transform: "rotate(90deg)" }} /></td><td> {heroStatDetails?.sent || 0}</td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                            <div className="sub-table-body">
                                                <table>
                                                    <tbody>
                                                        <tr style={{ width: "50%" }}><td>Received </td>
                                                            <td><Arrow style={{ transform: "rotate(-90deg)" }} /> </td><td> {heroStatDetails?.recieved || 0}</td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                                <tr className="body-wraper" style={{ backgroundColor: "white" }}>
                                    <td className="body-content" style={{ display: "flex", width: "25%", justifyContent: "center" }} >
                                        <p>Referrals </p>
                                        <img ref={anchorRef3} onMouseEnter={() => setShow3(true)} onMouseLeave={() => setShow3(false)} src={IIcon} alt="not found" />
                                        <HeroStatMenuList show={show3} setShow={setShow3} anchorRef={anchorRef3} message={messages[2]} />
                                    </td>
                                    {width > 585 && <td style={{ width: "50%" }}></td>}
                                    <td style={{ width: "25%",maxWidth: "250px" }}>
                                        <p>{heroStatDetails?.referals|| 0}</p>
                                    </td>
                                </tr>
                            </tbody>
                            </table>
                        </div>
                    </div >
                </div >
                <div className="add-con">
                   {<GoogleAdComponent />}
                </div>
                {show && <RefDialogBox show={show} setShow={setShow} />}
                {showImg && <ImgModalBox show={showImg} setShow={setShowImg} img={img} videoSrc={videoSrc} setImg={setImg} setVideoSrc={setVideoSrc} />}
                {licensePlatePopUp && <LicensePlatePopUp setLicensePlatePopUp={setLicensePlatePopUp} show={false}/>}
                {loader && <Loader />}
                {offersPopup && <CommonMessageDialog show={offersPopup} setShow={setOffersPopup} message ={`you haven’t earned any offers yet. The more people you help using Parking Hero the more coupons and gifts you can earn.`}/>}
            </div >
        </>
    )
}