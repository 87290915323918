import React, { useEffect, useState } from "react";
import { Navbar } from "../../components/navbar";
import "./style.scss";
import closed from "../../assets/icons/incorrect.png";
import { AdDialogBox } from "../../components/adPageDialog/index";
import { AuthService } from "../../services/auth.service";
import { STATUS } from "../../common/constant";
import { ErrorDialog } from "../../components/errorDialog";
import Loader from "../../components/Loader";

export const AdPage: React.FC = () => {
    const [file, setFile] = useState<any>();
    const [url, setUrl] = useState<string>("");
    const [fileUrl, setFileUrl] = useState<any>();
    const [show, setShow] = useState<boolean>(false);
    const [isValidUrl, setIsValidUrl] = useState<boolean>(false);
    const [isValidIMG, setIsValidIMG] = useState<boolean>(false);
    const authenticationService = new AuthService();
    const [errorDialog, setErrorDialog] = useState<boolean>(false);
    const [message, setMessage] = useState<string>("The  Ad  has  been  added  Successfully");
    const [loader, setLoader] = useState<boolean>(false);
    const [validationMessage, setValidationMessage] = useState<string>("")
    const [firstUpdate, setFirstUpdate] = useState<boolean>(false)
    // getting previous Add
    const getAdd = async () => {
        try {
            const addResponse: any = await authenticationService.adminAdvertisement();
            if (addResponse.data.advertisement_details.link) {
                setUrl(addResponse.data.advertisement_details.link);
                setFileUrl(addResponse.data.advertisement_details.advertisement)
            } else {
                setFirstUpdate(true);
            }
        } catch (error) { }
    };
    // deleting previous Add
    const deleteAdd = async () => {
        try {
            setLoader(true);
            const deleteResponse: any =
                await authenticationService.adminAdDelete();
            setLoader(false);
            if (deleteResponse.status === STATUS.SUCCESS) {
                setShow(true);
                setMessage("Advertisement removed successfully");
            } else {
                setErrorDialog(true);
            }
        } catch (error) {

        }
    }
    // converting image file to S3 url
    const getFileUrl = async () => {
        try {
            setLoader(true)
            let data: any = new FormData();
            data.append("advertisement", file[0]);
            const convertUrlResponse: any = await authenticationService.adminAddConvertFile(data);
            setLoader(false)
            setFileUrl(convertUrlResponse.data);
        } catch (error) { }
    }

    const handleSend = async () => {
        try {            
            setLoader(true);
            let data: any = new FormData();
            data.append("advertisement", fileUrl);
            data.append("link", url);
            const postResponse: any = await authenticationService.adminAdvertisementPost(data);
            setLoader(false);
            if (postResponse.status === STATUS.SUCCESS) {
                setShow(true);
                setMessage(firstUpdate ? "Advertisement added successfully" : "Advertisement updated successfully");
                setFirstUpdate(false);
            } else {
                setErrorDialog(true);
                setMessage(postResponse.message);
            }
        } catch (error) { }
    };
    // URL validation
    useEffect(() => {
        const regEx =
            /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/;
        if (url.match(regEx)) {
            setIsValidUrl(true);
        } else {
            setIsValidUrl(false);
        }
    }, [url]);
    // image validation
    useEffect(() => {
        if (file) {
            if (file[0].size <= 350000) {
                let reader = new FileReader();
                reader.readAsDataURL(file[0]);
                reader.onload = function (e: any) {
                    let image = new Image();
                    image.src = e.target.result;
                    image.onload = function () {
                        const height = image.height;
                        const width = image.width;
                        if (height !== 90 || width !== 728) {
                            setFile(null);
                            setIsValidIMG(true);
                            setValidationMessage("image Dimension should be 728*90");
                            setFileUrl(null);
                            return false;
                        } else {
                            getFileUrl();
                            return true;
                        }
                    };
                };
                setIsValidIMG(false);
            } else {
                setFile(null);
                setIsValidIMG(true);
                setValidationMessage("image size sholud be less than 320kb");
                setFileUrl(null);
            }
        } else {
            setFileUrl(null);
        }
        // eslint-disable-next-line
    }, [file]); 

    useEffect(() => {
        getAdd(); // eslint-disable-next-line
    }, []);

    return (
        <div className="adPage">
            <Navbar noAds={true} Admin={true} />
            <div className="heading">
                <h1>ADD ADVERTISEMENT</h1>
            </div>
            <div className="containers">
                <div className="container1">
                    <div className="text">
                        <p className="title">Upload The Advertisement File</p>
                        <p className="red">
                            (Please Upload a File whose Dimension 728*90)
                        </p>
                        <p className="red">(File Type: Image Format)</p>
                        <p className="red">(File size 350KB)</p>
                    </div>
                    <div className="files">
                        {!fileUrl ? (
                            <div className="bulk-upload">
                                <label className="custom-file-upload">
                                    <input
                                        type="file"
                                        multiple={false}
                                        onChange={(e) => {
                                            e.target.files?.length && setFile(e.target.files);
                                        }}
                                    />
                                    <div className="upload-btn">
                                        <span className="txt"> Upload</span>
                                    </div>
                                </label>
                                {isValidIMG && (
                                    <p
                                        style={{
                                            color: "red",
                                            textAlign: "center",
                                        }}>
                                        {validationMessage}
                                    </p>
                                )}
                            </div>
                        ) : (
                            <div className="filename">
                                <img
                                    src={closed}
                                    alt="ad content"
                                    className="icon"
                                    onClick={() => {
                                        setFile(null)
                                        setFileUrl(null)
                                    }}
                                />
                                <span>{fileUrl && fileUrl.split("_")[1]}</span>
                            </div>
                        )}
                    </div>
                </div>
                <div className="container2">
                    <div className="text">
                        <p className="title">
                            Specify The Url to Which The User Has To
                        </p>
                        <p className="title">Navigate On Clicking The Ad</p>
                    </div>
                    <div className="link">
                        <input
                            type="url"
                            value={url}
                            onChange={(e: any) => setUrl(e.target.value)}
                            id="link"
                        />
                    </div>
                </div>
            </div>
            <div className="submit-btn">
                {fileUrl || url !== "" ? (
                    <button
                        className={
                            fileUrl && isValidUrl ? "btn" : "btn btnDisable"
                        }
                        onClick={handleSend}>
                        Upload
                    </button>
                ) : (
                    <></>
                )}
                {!fileUrl && url === "" && (
                    <button className={!firstUpdate ? "btn" : "btn btnDisable"} disabled={firstUpdate} onClick={() => deleteAdd()}>
                        Save
                    </button>
                )}
            </div>
            {loader && <Loader />}
            {show && <AdDialogBox show={show} setShow={setShow} message={message} />}
            {errorDialog && (
                <ErrorDialog
                    show={errorDialog}
                    setShow={setErrorDialog}
                    message={message}
                />
            )}
        </div>
    );
};
