import * as Yup from 'yup'

export const registerSchema = {
  heroName: "",
  phone: "",
  countryCode: '+1',
  email: "",
  zipCode: "",
  password: "",
  confirmPassword: "",
  referralCode: "",
  role: "USER",
  licensePlate: "",
}

// eslint-disable-next-line no-useless-escape
const emailRegex = /^([A-Za-z0-9_\.\-])+\@(([A-Za-z0-9\-])+\.)+([A-Za-z0-9]{2,4})+$/
// eslint-disable-next-line
const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#\$%\^&\*])(?=.{8,})+\S*$/
const heroNameRegex = /^\S*$/;


export const registerValidations = Yup.object().shape({
  heroName: Yup.string().trim().required('Please enter your name').matches(heroNameRegex, "Please don't enter space").max(30, "Name must be maximum 30 characters").min(3, "Name must be minimum 3 characters "),
  phone: Yup.string().trim().required('Please enter mobile number').max(10, "Please enter valid number ").min(10, "Please enter valid number "),
  email: Yup.string().trim().required('Please enter email').matches(emailRegex, "Please enter valid email").max(100, "email shoul not exeed 100 characters"),
  zipCode: Yup.string().trim().required('Please enter zipcode').min(5, "Zip code length must be greater than 4"),
  password: Yup.string().trim().required('Please enter password').matches(passwordRegex, "Password Should contain a minimum of 8 characters which includes 1 Upper case, 1 Lower case, 1 special character, 1 number, Without space"),
  confirmPassword: Yup.string().trim().required('Please enter confirm password').oneOf([Yup.ref('password'), null], 'Passwords must match'),
  referralCode: Yup.string().trim(),
  licensePlate: Yup.string().trim().min(7, 'License plate number must be at least 7 characters long')
})




