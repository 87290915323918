import React from "react";
import './style.scss'
import closeIcon from "../../assets/icons/x-circle.svg"

type Tprop = {
    show: boolean
    setShow: (open: boolean) => void
    message?: any
    navigationData?: string
    forLogin?: boolean
}
export const ErrorDialog: React.FC<Tprop> = ({ show, setShow, message, navigationData,forLogin }) => {
    const close = () => {
        setShow(false);
    }

    return (
        <div className="errordialog-box">
            <div className="box">
                <div className="close">
                    <div className="dialog-btn-close" onClick={() => close()}>
                        <img src={closeIcon} alt="not-found" />
                    </div>
                </div>
                <p className={forLogin ? "forLogin" : ""}>{message}</p>
            </div>
        </div>
    );
};