import React, { useEffect, useState } from "react";
import brandLogo from "../../assets/parkingHeroLogo.svg";
import { DialogBox } from "../../components/dialogBox";
import { useFormik } from 'formik';
import { registerSchema, registerValidations } from './validators';
import { AuthService } from "../../services/auth.service";
import "./styles.scss";
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import TextFieldLogin from "../../components/TextFieldLogin";
import { STATUS, POLICY_CONTENT } from "../../common/constant";
import Loader from "../../components/Loader";
import { MobileField } from "../../components/MobileField";
import { ErrorDialog } from "../../components/errorDialog";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { RegisterBox } from "../../components/socialLoginDetail";
import { AccountVerifyDialogBox } from "../../components/accountVerificationDialog";
import Social from "../../components/sl";
import { useDispatch } from "react-redux";
import { setAuthData } from "../../redux/Actions/actions";
import Privacy_Policy from "../../assets/termsOfUse/Privacy_Policy.pdf";
import Member_Agreement from '../../assets/termsOfUse/Member_Agreement.pdf';
import Community_Guidelines from '../../assets/termsOfUse/Community_Guidelines.pdf';
import California_Do_Not_Sell_my_Information from "../../assets/termsOfUse/California_Do_Not_Sell_my_Information.pdf";
import Business_Service_Terms from "../../assets/termsOfUse/Business_Service_Terms.pdf";
import Advertising_and_Cookie_Policy from "../../assets/termsOfUse/Advertising_and_Cookie_Policy.pdf";
import Trademark_and_Copyright_Policy from "../../assets/termsOfUse/Trademark_and_Copyright_Policy.pdf";
import { PdfViewDialogBox } from "../../components/PdfViewDialogBox";
// import { CommonMessageDialog } from "../../components/CommonMessageDialog";

export const SignUpPage: React.FC = () => {


    const [show, setShow] = useState<boolean>(false);
    const [isTermsAccepted, setIsTermsAccepted] = useState<boolean>(false);
    const [dialogueStatus, setDialogueStatus] = useState<string>("");
    const [dialogueErrorMsg, setDialogueErrorMsg] = useState<string>("");
    const [email, setEmail] = useState<string>("");
    const [loader, setLoader] = useState<boolean>(false);
    const[Loginshow,setLoginshow]=useState<boolean>(false)
    const [errorDialog, setErrorDialog] = useState<boolean>(false)
    const [showRegister, setShowRegister] = useState<boolean>(false)
    const [socialMail,setSocialmail] = useState<string>()
    const [firstTimeLogin, setFirstTimeLogin] = useState<boolean>(false)
    const [userName,setUserName] = useState<string>("Hero");
    const [loginDetails,setLoginDetails] = useState<any>()
    const [contentToDisplay] = useState<any>({});
    const navigate = useNavigate()
    const dispatch = useDispatch();
    const location: any = useLocation();
    const URL = location?.state?.qrUserId;
    const authenticationService = new AuthService();
    const [clickedTerms, setClickedTerms]=useState<string>('')
    const [openPdf, setOpenPdf]= useState<boolean>(false)
    const [mobileView, setMobileView]= useState<boolean>(false)
    const policy = [Privacy_Policy,Member_Agreement,Community_Guidelines, California_Do_Not_Sell_my_Information,Business_Service_Terms,Advertising_and_Cookie_Policy,Trademark_and_Copyright_Policy]
   
    // const [resendPopup, setResendPopup] = useState<boolean>(false);


    const registerSubmit = async (values: any): Promise<void> => {

        if (!isTermsAccepted) {
            setDialogueStatus("error")
            setDialogueErrorMsg(POLICY_CONTENT.message1)
            setShow(true)
            return
        }
        try {
            setEmail(values?.email.trim().toLowerCase());
            setLoader(true)
            const registerValues: any = {
                hero_name: values?.heroName,
                phone_number: values?.phone.toString(),
                country_code: values?.countryCode,
                email: values?.email.toLowerCase(),
                zip_code: values?.zipCode.toString(),
                password: values?.password,
                confirm_password: values?.confirmPassword,
                referal_code: values?.referralCode,
                role: values?.role ? values?.role : "USER",
                qrUserId: location?.state?.qrUserId ? location?.state?.qrUserId : "",
            }
            if (values.license_plate) {
                registerValues['license_plate'] = values?.licensePlate.trim();
            }
            const registerResponse = await authenticationService.Signup(registerValues);
            setLoader(false)
            if (registerResponse.status === STATUS.SUCCESS) {
                setDialogueStatus("waiting")
                setShow(true)
            } else {
                setDialogueStatus("error")
                setDialogueErrorMsg(registerResponse.message)
                setShow(true)
            }
        }
        catch (error: any) {
            console.log(error)
        }
    }
    const socialLogin = async (payload: any) => {
        try {
            const login = await authenticationService.SocialLogin(payload)
            if (login.status === STATUS.SUCCESS) {
                if (login?.data?.register && !login?.data?.token) {
                    setDialogueStatus("waiting")
                    setShow(true)
                }
                else if(login?.data?.token){
                    setFirstTimeLogin(login.data.first_login)
                    setUserName(login?.data?.hero_name);
                    if (!login.data.first_login) {
                        dispatch(setAuthData(login.data));
                    }
                    else if(login.data.first_login===true){
                        setLoginshow(true)
                    }
                    setLoginDetails(login.data)
                    localStorage.setItem("accessToken", login.data.token.access)
                    localStorage.setItem("refreshToken", login.data.token.refresh)
                    if (location?.state?.qrUserId) {
                        const URL = "/timeForAction/" + location?.state?.qrUserId;
                        navigate(URL)
                        return
                    }

                }
                else {
                    setSocialmail(login?.data?.email)
                    setDialogueStatus("waiting")
                    setShowRegister(true)
                }
            }
            else {
                if (!login?.data?.is_email_verified || !login?.data?.is_phone_verified) {
                    setSocialmail(login?.data?.email)
                    setDialogueStatus("waiting")
                    setShow(true)
                }
            }
        }
        catch (err: any) {
            console.log(err.message)
        }
    }

    const handleViewPdf =(data:any)=>{
        setClickedTerms(data)
        setOpenPdf(true)
    }

    const registerFormik = useFormik({ initialValues: registerSchema, validationSchema: registerValidations, onSubmit: registerSubmit });

    useEffect(() => {
        window.scrollTo(0, 0)
         window.innerWidth <= 760 ? setMobileView(true) :  setMobileView(false)
    }, [])

    return (
        <div className="SignUpPage-page" >
            <div className="logo-wrapper">
                <Link to={"/"}><img className="brand-logo" src={brandLogo} alt="" /></Link>
            </div>
            <div>
                <h1 className="SignUpPage-page-heading">Create your account</h1>
                <h6 className="SignUpPage-page-welcome">Welcome to Parking Hero!</h6>
                <h6 className="SignUpPage-page-content-1">Sign up with</h6>
            </div>
            <div className="flexAlignment">
                <Social signin={(val:any)=>socialLogin(val)}/>
            </div>
            {/* <div className="social-login-icons"> */}
               
                {/* <FaceBookLogin id={'5153119011450538'} login={(val: any) => socialLogin(val)} /> */}
                {/* <GoogleLogin id={"1004683485189-htpprl9r4t0544pk4fvnqoohtqs2rqv8.apps.googleusercontent.com"} login={(val: any) => socialLogin(val)} /> */}
                {/* <img src={facebookLogo} alt={"not Found"} />
                <img src={googleLogo} alt={"not Found"} /> */}
                {/* <img src={appleLogo} alt={"not Found"} /> */}

            {/* </div> */}
            <form onSubmit={registerFormik.handleSubmit} >
                <div className="form-data">
                    <TextFieldLogin
                        label='Create your hero name'
                        formik={{ ...registerFormik.getFieldProps('heroName') }}
                        error={registerFormik.touched.heroName && registerFormik.errors.heroName}
                        errorText={registerFormik.errors.heroName}
                    />
                     <h4 className="notice-field">Choose a fun hero name with NO_SPACES</h4>
                    <MobileField
                        label='Phone'
                        formikCode={{ ...registerFormik.getFieldProps('countryCode') }}
                        formikNumber={{ ...registerFormik.getFieldProps('phone') }}
                        error={registerFormik.touched.phone && registerFormik.errors.phone}
                        errorText={registerFormik.errors.phone}
                    />
                    <h4 className="notice-field">Your Phone Number And License Plate Will Remain Confidential And We Will Never Share It With Others.</h4>
                    <TextFieldLogin
                        label="License Plate"
                        formik={{...registerFormik.getFieldProps('licensePlate')}}
                        error={registerFormik.touched.licensePlate && registerFormik.errors.licensePlate}
                        errorText={registerFormik.errors.licensePlate}
                    />
                    <TextFieldLogin
                        label='Email'
                        formik={{ ...registerFormik.getFieldProps('email') }}
                        error={registerFormik.touched.email && registerFormik.errors.email}
                        errorText={registerFormik.errors.email}
                    />
                    <TextFieldLogin
                        label='Zipcode'
                        type={"number"}
                        formik={{ ...registerFormik.getFieldProps('zipCode') }}
                        error={registerFormik.touched.zipCode && registerFormik.errors.zipCode}
                        errorText={registerFormik.errors.zipCode}
                    />
                    <TextFieldLogin
                        label='Password'
                        type={'password'}
                        formik={{ ...registerFormik.getFieldProps('password') }}
                        error={registerFormik.touched.password && registerFormik.errors.password}
                        errorText={registerFormik.errors.password}
                    />
                    <TextFieldLogin
                        type='password'
                        label='Confirm Password'
                        formik={{ ...registerFormik.getFieldProps('confirmPassword') }}
                        error={registerFormik.touched.confirmPassword && registerFormik.errors.confirmPassword}
                        errorText={registerFormik.errors.confirmPassword}
                    />
                    <TextFieldLogin
                        label='Referral Code(Optional)'
                        formik={{ ...registerFormik.getFieldProps('referralCode') }}
                        error={registerFormik.touched.referralCode && registerFormik.errors.referralCode}
                        errorText={registerFormik.errors.referralCode}
                    />
                </div>
                <div className="terms">
                    <h4 className="lable-field">Please read our terms of use</h4>
                    <div className="text-box">
                        <div className="text">
                            {policy.length >0 && policy.map((data:any, index:number)=>{
                                const formatedStr =data.split('/').pop().split('.')[0];
                                return(
                                    <>
                                    {mobileView ? <a  key={index} href={data+"#toolbar=0"}   target='_blank' rel='noreferrer'>{ formatedStr.replaceAll("_", " ")}</a>
                                    // eslint-disable-next-line jsx-a11y/anchor-is-valid
                                    :<a  key={index} onClick={()=> handleViewPdf(data)}>{ formatedStr.replaceAll("_", " ")}</a> } 
                                     </>
                                )
                            }) }
                           
                        </div>
                        <div className="checkbox">
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        sx={{
                                            '& .MuiSvgIcon-root': { fontSize: 26 },
                                            color: "#ffffff",
                                            '&.Mui-checked': { color: "#ffffff" }
                                        }}
                                        onChange={(e) => { setIsTermsAccepted(e.target.checked) }}
                                    />
                                }
                                label="Agree to terms of use"
                                sx={{
                                    fontWeight: 600,
                                    fontFamily:"Classic-Comic !important",
                                }}
                            />
                        </div>
                    </div>
                </div>
                <div className="signup">
                    <button className="btn "
                        type="submit">Sign up</button>
                </div>
            </form>
            {/* <div className="end-content">
                <p className="lable-field">Thank you for joining! Please verify by replying to our email & text.</p>
            </div> */}
            {Loginshow && <AccountVerifyDialogBox loginDetails={loginDetails} show={show} setShow={setShow} contentToDisplay={contentToDisplay} email={email} firstTimeLogin={firstTimeLogin} setFirstTimeLogin={setFirstTimeLogin} userName={userName} />}
            {show && <DialogBox status={dialogueStatus}  show={true} setShow={setShow} errorMessage={dialogueErrorMsg} userId={URL} email={email} sl={socialMail?true:false}/>}
           
            {/* { resendPopup &&  <CommonMessageDialog show={resendPopup} setShow={setResendPopup} message ={`Verification Email has been resent. Please check your spam/junk folders if you don’t see it in your inbox.`}/>} */}
           
            {errorDialog && <ErrorDialog show={errorDialog} setShow={setErrorDialog} message={POLICY_CONTENT.message1} />}
            {loader && <Loader />}
            {showRegister && <RegisterBox setShow={setShowRegister} show={showRegister} email={socialMail} setlogin={setShow}/>}
            {openPdf && <PdfViewDialogBox show={openPdf} setShow={setOpenPdf} link={clickedTerms}/>}
        </div>
    );
};
