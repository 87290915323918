import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { EmailVerifyDialogBox } from "../../components/emailVerificationDialog/index";
import "./styles.scss";
import { STATUS } from "../../common/constant";
import { AuthService } from "../../services/auth.service";
import brandLogo from "../../assets/parkingHeroLogo.svg";

export const EmailVerification: React.FC = () => {

    const authenticationService = new AuthService();
    const params: any = useParams();
    const token = params?.token
    const [dialogueStatus, setDialogueStatus] = useState<string>("");
    const [qrUrl, setQrUrl] = useState<string>("");
    const [show, setShow] = useState<boolean>(false);
    const [dialogueErrorMsg, setDialogueErrorMsg] = useState<string>("");

    const verifyEmailAPI2 = async () => {
        try {
            const verifyEmailData: any = {
                token: token
            }

            const verifyEmailResponse: any = await authenticationService.EmailVerification(verifyEmailData);
            if (verifyEmailResponse.status === STATUS.SUCCESS) {
                setDialogueStatus("waiting")
                setQrUrl(verifyEmailResponse?.data?.qrUserId)
                setDialogueErrorMsg(verifyEmailResponse.message)
                setShow(true)
                return
            } else {
                setDialogueStatus("error")
                setDialogueErrorMsg(verifyEmailResponse.message)
                setShow(true)
                return;
            }
        } catch (error) {

        }
    }

    useEffect(() => {
        verifyEmailAPI2()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <>
            <div className="emailVerify-page">
                <div className="logo-wrapper">
                    <img src={brandLogo} alt="" />
                </div>
                <div className="email">
                    <div className="verify-email">
                        <p>EMAIL VERIFICATION</p>
                    </div>
                </div>
            </div>
            {show && <EmailVerifyDialogBox status={dialogueStatus} show={show} setShow={setShow} errorMessage={dialogueErrorMsg} qrUrl={qrUrl} />}
        </>
    )
}