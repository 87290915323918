import React, { useState } from "react";
import * as Yup from "yup";
import "./styles.scss";

import minusIcon from "../../assets/icons/icons8-minus-96.png"
import { RefEmailDialogBox } from "../refEmailDialog";
import closeIcon from "../../assets/icons/x-circle.svg"
import addIcon from "../../assets/icons/add.png"
import { ErrorMessage, Field, FieldArray, Form, Formik } from "formik";
import { AuthService } from "../../services/auth.service";
import { STATUS } from "../../common/constant";
import { IReferalSms } from "../../services/interface/auth.interface";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/Reducer";
import { ErrorDialog } from "../errorDialog";
import Loader from "../Loader"

type Tprop = {
    show: boolean,
    referralCode: string
    setShow: (open: boolean) => void
}

export const RefPhoneDialogBox: React.FC<Tprop> = ({ show, setShow, referralCode }) => {
    const [show1, setShow1] = useState(false)
    const [loader, setLoader] = useState<boolean>(false)
    const [showError, setShowError] = useState(false)
    const [errorMessage, setErrorMessage] = useState("")
    const userDetails: any = useSelector<RootState>((state) => state?.authData?.authData)
    const authService = new AuthService()
    const countryCode: string = "+1"
    const messages: string = `Join Parking Hero and start solving parking problems today! Learn more at Www.parkinghero.net Sign up at https://lbparkinghero.com/signup Use this referral code when you sign up to earn an instant reward! Code: ${referralCode}`
    // ReferSms

    const validationSchema = Yup.object().shape({
        mobileArray: Yup.array().of(
            Yup.object().shape({
                mobileNumber: Yup.string().required("Please enter mobile number").min(10, "Please enter valid number").max(10, "Please enter valid number"),
            }))
    });

    let initialValues = {
        mobileArray: [
            {
                mobileNumber: "",
            }
        ]

    };

    const onSubmit = async (values: any) => {
        try {
            const payload: IReferalSms = {
                user_id: userDetails?.user_id,
                mobile_numbers: values?.mobileArray.map((data: any) => `${countryCode + data.mobileNumber}`),
                message: messages
            }
            setLoader(true);
            const referSms = await authService.ReferSms(payload)
            setLoader(false);
            if (referSms.status === STATUS.SUCCESS) {
                setShow1(true);
            } else {
                setShowError(true);
                setErrorMessage(referSms.message)
            }
        }
        catch (err) {

        }
    }

    const addNewChildHandler = (values: any, setValues: any) => {
        const newData = [...values.mobileArray, {
            mobileNumber: "",
        }]
        setValues({ mobileArray: newData });
    }
    
    const removeElementHandler = (index: number, fields: any, setValues: any) => {
        const finalResult = fields.mobileArray;
        finalResult.splice(index, 1);
        setValues({ mobileArray: finalResult });
    }


    return (
        <div className="phone-dialog-box">
            {show1 && <RefEmailDialogBox show={show1} setShow={setShow1} />}
            <div className="box">
                <div className="header flex flex-row basis-3/4">
                    <div className="heading-text">
                        <h1 className="dialog-heading">Please enter the Mobile Number(s): </h1>
                    </div>
                    <div className="close1 flex flex-rowreverse basis-1/4">
                        <div className="dialog-btn-close-lg" onClick={() => { setShow(false) }}>
                            <img src={closeIcon} alt="not-found" />
                        </div>
                    </div>
                </div>
                <Formik
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    onSubmit={onSubmit}
                >
                    {({ errors, values, touched, setValues, setFieldValue, setErrors }) => {

                        return (
                            <Form>
                                <div className="mobile-no-field">
                                    <div className="label basis-1/2">
                                        <label htmlFor="mobile-no" className="mobile-label">Mobile Number:</label>
                                    </div>
                                    <div className="input basis-1/2 input-errors">
                                        <FieldArray name="mobileArray">
                                            {() =>
                                                values?.mobileArray.map((data: any, i: number) => {
                                                    let otherAttributesErrors: any =
                                                        (errors.mobileArray?.length &&
                                                            errors.mobileArray[i]) ||
                                                        {};
                                                    const otherAttributesTouched: any =
                                                        (touched.mobileArray?.length &&
                                                            touched.mobileArray[i]) ||
                                                        {};
                                                    return (
                                                        <>
                                                            <div className="field">
                                                                <div className="input add-input">
                                                                    <span>{countryCode}</span>
                                                                    <Field
                                                                        name={`mobileArray.${i}.mobileNumber`}
                                                                        type="number"
                                                                        className={
                                                                            (otherAttributesErrors?.mobileNumber && otherAttributesTouched?.mobileNumber
                                                                                ? " is-invalid"
                                                                                : "mobile-input")}>
                                                                    </Field>
                                                                    <button style={values?.mobileArray?.length > 1 ? {} : { display: "none" }} className="delete-btn" type="button" onClick={() => removeElementHandler(i, values, setValues)} ><img className="delete-img" src={minusIcon} alt="notFound" /> </button>
                                                                </div>
                                                                <ErrorMessage
                                                                    name={`mobileArray.${i}.mobileNumber`}
                                                                    component="div"
                                                                    className="invalid-feedback"
                                                                />
                                                            </div>

                                                        </>)
                                                })}
                                        </FieldArray>
                                        <div className="icon">
                                            <button className="add-btn" type="button" onClick={() => addNewChildHandler(values, setValues)}>
                                                <img src={addIcon} alt="not-found" />
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <div className="message-field flex flex-row">
                                    <div className="label basis-1/2">
                                        <label htmlFor="message" className="message-label">Message:</label>
                                    </div>
                                    <div className="input basis-1/2">
                                        <div className="msg-text">
                                            <h1 className="dialog-heading">Hey buddy,</h1>
                                            <div className="space"></div>
                                            <p>Join Parking Hero and start solving parking problems today!</p>
                                            <div className="space"></div>
                                             <p>Learn more at <a href="Www.parkinghero.net">Www.parkinghero.net</a></p>
                                            <div className="space"></div>
                                            <p>Sign up at <a href="https://lbparkinghero.com/signup">https://lbparkinghero.com/signup</a></p>
                                            <div className="space"></div>
                                            <p>Use this referral code when you sign up to earn an instant reward!</p>
                                            <div className="space"></div>

                                            <div className="ref-code">
                                                <p>Code:</p><h1 className="dialog-heading">{referralCode}</h1>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="button-field flex flex-row">
                                    <div className="label basis-1/2">
                                    </div>
                                    <div className="button basis-1/2">
                                        <button type="submit" className="dialog-btn">Send</button>
                                    </div>
                                </div>
                            </Form>
                        )
                    }}
                </Formik>
            </div>
            {loader && <Loader />}
            {showError && <ErrorDialog show={false} setShow={setShowError} message={errorMessage} navigationData={'ref-page'}/>}
        </div>
    );
};
