import React from "react";
import "./styles.scss";
import AlertIcon from "../../assets/icons/warning.png"
import { AuthService } from "../../services/auth.service";
import { STATUS } from "../../common/constant";

type Tprop = {
    setShow: (open: boolean) => void
    message: string
    setCheck: (open: boolean) => void
    check:boolean
}

export const WarningDialogBox: React.FC<Tprop> = ({ setShow, message, setCheck,check }) => {
    const authenticationService = new AuthService();

    const sellAccount = async () => {
        try {
            const data = {
                sell: !check
            }
            const sellAccountResponse: any = await authenticationService.sellAccount(data)
            if (sellAccountResponse.status === STATUS.SUCCESS) {
                setCheck(!check);
            }
        } catch (error) {
            
        }
    }

    return (
        <div>
            <div className="warningDialog">
                <div className="box">
                    <img className="dialog-heading-icon" src={AlertIcon} alt={"not found"} />
                    <p className="text">{message}</p>
                    
                    <div className="space"></div>
                    <div className="btns">
                        <button className="dialog-btn" onClick={() => { setShow(false)}}>No</button>
                        <button className="dialog-btn" onClick={() => {
                            sellAccount();
                            setShow(false)
                        }}>Yes</button>
                    </div>
                </div>
            </div>
        </div>
    );
};