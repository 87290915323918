import React, { useState } from "react";
import { Navbar } from "../../components/navbar";
import { RefEmailDialogBox } from "../../components/refEmailDialog";
import { useNavigate } from "react-router-dom";
import { useFormik } from 'formik';
import { referSchema, referValidater } from "./validator";
import "./styles.scss";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/Reducer";
import { AuthService } from "../../services/auth.service";
import { STATUS } from "../../common/constant";
import { ErrorDialog } from "../../components/errorDialog";
import Loader from "../../components/Loader";

export const EmailPage: React.FC = () => {
    const [show, setShow] = useState<boolean>(false)
    const [loader, setLoader] = useState<boolean>(false)
    const navigate = useNavigate()
    const [message, setMessage] = useState<string>("")
    const [errorDialog, setErrorDialog] = useState<boolean>(false);
    const authenticationService = new AuthService();
    const user_id: any = useSelector<RootState>((state) => state?.authData?.authData?.user_id?.toString())
    const Message = document.getElementById('message')?.innerHTML

    const handleSend = async (Value: any) => {
        try {
            setLoader(true);
            const StringArray = referFormik.getFieldProps('emailId').value
            const referal: any = {
                user_id: user_id.toString(),
                recipients: StringArray,
                subject: "Use my Referral Code to get Benefts!!!",
                message: Message,
            }
            const referalResponse: any = await authenticationService.ReferEmail(referal);
            setLoader(false);
            if (referalResponse.status === STATUS.SUCCESS) {
                setShow(true)
            }
            else {
                setErrorDialog(true)
                setMessage(referalResponse.message)
            }
        } catch (error) {

        }
    }

    const userdata: any = useSelector<RootState>((state) => state?.authData?.authData?.referal_code) || ""
    const referFormik = useFormik({ initialValues: referSchema, validationSchema: referValidater, onSubmit: handleSend })

    return (
        <>
            <Navbar />
            <div className="email-page">
                <div className="main-container">
                    <form onSubmit={referFormik.handleSubmit}>
                        <div className="recipients">
                            <label htmlFor="recipient">To</label>
                            <div className="text-field">
                                <input className="email-to" type="text" {...referFormik.getFieldProps('emailId')} placeholder="Enter Your Friends Emails separated by commas" />
                                {referFormik.touched.emailId && referFormik.errors.emailId ? (
                                    <p className="warn">{referFormik.errors.emailId}</p>
                                ) : null}
                            </div>
                        </div>

                        <div className="msg-container" id="message">
                            <div className="container-white">
                                <div className="welcome-msg">
                                    <h1 className="heading-normal-case">Hey Buddy,</h1>
                                    <p className="description">Parking Hero gives pedestrians a free way to notify drivers that their car is about to get a parking ticket!!!</p>
                                    <p className="description">Becoming a Parking Hero is quick and easy</p>
                                </div>

                                <div className="referral">
                                    <h1 className="heading-normal-case">Use my Referral Code to get Benefits!!!</h1>
                                    <div className="links">
                                        <p className="text">Referral Code: {userdata}</p>
                                        <p ><a href='https://lbparkinghero.com/signup' className="link" >Sign up by clicking here</a ></p>
                                        <p ><a href='https://parkinghero.net' className="link" >Click here to learn more</a ></p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="btn-container">
                            <div className="buttons">
                                <button className="btn" type="submit" >Send</button>
                                <button className="btn" onClick={() => navigate("/account")}>Cancel</button>
                            </div>
                        </div>
                    </form>
                </div>
                {loader && <Loader/>}
                {show && <RefEmailDialogBox show={show} setShow={setShow} />}
                {errorDialog && <ErrorDialog show={errorDialog} setShow={setErrorDialog} message={message} navigationData={'ref-page'} />}
            </div>
        </>
    )
}