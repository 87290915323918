import React from "react";
import "./styles.scss";
import completedIcom from "../../assets/icons/completed.png"
import closeIcon from "../../assets/icons/x-circle.svg"

type Tprop = {
    show: boolean
    setShow: (open: boolean) => void

}

export const ProfileDialogBox: React.FC<Tprop> = ({ show, setShow }) => {

    const message = "Your profile details has been updated successfully"
    return (
        <div className="forgot-box-profile">
            <div className="box">
                <div className="close">
                    <div className="dialog-btn-close" onClick={() => { setShow(false) }}>
                    <img src={ closeIcon } alt="not-found" />
                    </div>
                </div>
                <img className="dialog-heading-icon" src={completedIcom} alt={"not found"} /> 
                <p className="text">{message}</p>
            </div>
        </div>
    );
};