/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { AuthService } from "../../services/auth.service";
import googleIMG from "../../assets/icons/googleAD.png"
import { STATUS, OPTIONS } from "../../common/constant";
import { Dropdown } from "../../components/contactDropdownMenu"
import { useFormik } from 'formik';
import { contactSchema, contactValidations } from "./validator";
import { Navbar } from "../../components/navbar";
import { RootState } from "../../redux/Reducer";
import { ErrorDialog } from "../../components/errorDialog";
import "./styles.scss";
import Loader from "../../components/Loader";
import { SuccessDialogBox } from "../../components/successDialog";

export const ContactPage: React.FC = () => {
    const [show, setShow] = useState<boolean>(false)
    const token: any = useSelector<RootState>((state) => state?.authData?.authData?.token?.access);
    const userDetails: any = useSelector<RootState>((state: any) => state?.authData?.authData)
    const [loader, setLoader] = useState<boolean>(false)
    const [message, setMessage] = useState<string>('')

    const options = [
        OPTIONS.heading1,
        OPTIONS.heading2,
        OPTIONS.heading3,
        OPTIONS.heading4,
        OPTIONS.heading5,
    ];

    const [isOpen, setIsOpen] = useState<boolean>(false);
    const [selectedOption, setSelectedOption] = useState<string>("");
    const authenticationService = new AuthService();
    const [errorDialog, setErrorDialog] = useState<boolean>(false);
    const [contentToDisplay, setContentToDisplay] = useState<string>();

    const cancel = () => {
        contactFormik.resetForm()
        setSelectedOption("")
    }

    const send = async (values: any) => {
        try {
            setLoader(true)
            const ContactUsData: any = {
                name: values.name,
                email: values.email,
                subject: selectedOption,
                message: values.message
            }

            const ContactResponse = await authenticationService.ContactUs(ContactUsData);
            setLoader(false)
            if (ContactResponse.status === STATUS.SUCCESS) {
                setMessage(ContactResponse.message)
                setShow(true)
                contactFormik.resetForm()
                setSelectedOption("")
            }
            else {
                setShow(false)
                setErrorDialog(true)
                setContentToDisplay(ContactResponse.message)
            }

        } catch (error) {

        }
    }

    const contactFormik = useFormik({
        initialValues: contactSchema(userDetails?.hero_name, userDetails?.email), validationSchema: contactValidations, onSubmit: send
    })

    useEffect(() => {
        contactFormik.setFieldValue("selectedOption", selectedOption)
        if (selectedOption === OPTIONS.heading1) {
            contactFormik.setFieldValue("message", OPTIONS.message1)
        }
        else if (selectedOption === OPTIONS.heading2) {
            contactFormik.setFieldValue("message", OPTIONS.message2)
        }
        else if (selectedOption === OPTIONS.heading3) {
            contactFormik.setFieldValue("message", OPTIONS.message3)
        }
        else if (selectedOption === OPTIONS.heading4) {
            contactFormik.setFieldValue("message", OPTIONS.message4)
        }
        else {
            contactFormik.setFieldValue("message", OPTIONS.message5)
        }

    }, [selectedOption])

    return (
        <>
            <Navbar />
            <div className="contact-page">

                <div className="heading">
                    <h1>Contact Us</h1>
                </div>
                <form name="contact" onSubmit={contactFormik.handleSubmit}>
                    <div className="space-lg"></div>

                    <div className="contact main-container">
                        <div className="gridAlign name-field">
                            <div className="label">
                                <label htmlFor="name" className="contact-label">Name</label>
                            </div>
                            <div className="input">
                                <div>
                                    <input type="text" {...contactFormik.getFieldProps('name')} name="name" className="contact-input" disabled={token} id="name" />
                                    {contactFormik.touched.name && contactFormik.errors.name ? (
                                        <p className="warn">{contactFormik.errors.name}</p>
                                    ) : null}
                                </div>
                            </div>
                        </div>

                        <div className="space"></div>

                        <div className="gridAlign email-field">
                            <div className="label">
                                <label htmlFor="email" className="contact-label">Email</label>
                            </div>
                            <div className="input">
                                <div>
                                    <input type="text" {...contactFormik.getFieldProps('email')} name="email" className="contact-input" disabled={token} id="email" />
                                    {contactFormik.touched.email && contactFormik.errors.email ? (
                                        <p className="warn">{contactFormik.errors.email}</p>
                                    ) : null}
                                </div>
                            </div>
                        </div>

                        <div className="space"></div>

                        <div className="gridAlign dropdown-field">
                            <div className="label dropdown-label">
                                <label
                                    htmlFor="dropdown"
                                    className="contact-label"
                                >
                                    Tell us what your message is regarding
                                </label>
                            </div>
                            <div className="input dropdown-div">
                                <div>
                                    {/* <div id="drop-down" className="drop-down" > */}
                                    <Dropdown isOpen={isOpen} setIsOpen={setIsOpen} selectedOption={selectedOption} setSelectedOption={setSelectedOption} options={options} />
                                    {/* </div> */}
                                    {contactFormik.touched.selectedOption && !(selectedOption) && contactFormik.errors.selectedOption ? (
                                        <p className="warn">{contactFormik.errors.selectedOption}</p>
                                    ) : null}
                                </div>
                            </div>
                        </div>

                        <div className="space"></div>

                        <div className="gridAlign text-field">
                            <div className="label">
                                <label htmlFor="message" className="contact-label">Message</label>
                            </div>
                            <div className="input">
                                <div>
                                    <textarea className="contact-input"  {...contactFormik.getFieldProps('message')} name="message" id="message" cols={30} rows={5} ></textarea>
                                    {contactFormik.touched.message && contactFormik.errors.message ? (
                                        <p className="warn">{contactFormik.errors.message}</p>
                                    ) : null}
                                </div>
                            </div>
                        </div>

                        <div className="space-lg"></div>

                        <div className="gridAlign text-field">
                            <div className="label"></div>
                            <div className="input">
                                <button type="submit" className="btn">Send</button>
                                <button type="button"
                                    className="btn" onClick={() => {
                                        cancel()
                                    }}>Cancel</button>
                            </div>
                        </div>

                        <div className="space-lg"></div>

                        <div className="space"></div>
                    </div>

                    {show && <SuccessDialogBox show={show} setShow={setShow} message={message} />}
                    {errorDialog && <ErrorDialog show={errorDialog} setShow={setErrorDialog} message={contentToDisplay} />}
                    {loader && <Loader />}
                </form>
            </div>
            <div className="navbar .no-print">
                    <div className="head-container flex flex-row">
                        <div className="logo-wrapper basis-1/4 flex flex-row"></div>
                        <div className="ad-container basis-2/4 flex flex-row">
                            <img
                                className="googleAdimg"
                                src={googleIMG}
                                alt="GoogleAD"
                            />
                        </div>
                        <div className="end-icons basis-1/4 flex flex-row-reverse"></div>
                    </div>
                </div>
        </>
    )
}