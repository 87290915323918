import React, { useState } from "react";
import brandLogo from "../../../assets/parkingHeroLogo.svg";
import { OTPInputComponent } from "../../../components/otpInput"
import "./styles.scss";
import { AuthService } from "../../../services/auth.service";
import { STATUS } from "../../../common/constant";
import { IOtp } from "../../../services/interface/auth.interface";
import { ErrorDialog } from "../../../components/errorDialog";
import Loader from "../../../components/Loader";
import { useNavigate } from "react-router-dom";

type TProp = {
    setOtpVerified: any
    email: string
}

export const OtpPage: React.FC<TProp> = ({ email, setOtpVerified }) => {
    const [otp, setOtp] = useState<string>("")
    const [message, setMessage] = useState<string>("")
    const [show, setShow] = useState<boolean>(false)
    const [loader, setLoader] = useState<boolean>(false);
    const authenticationService = new AuthService();
    const navigate = useNavigate()

    const formsubmit = async () => {
        try {
            if (otp.length < 4) {
                setMessage("Enter The OTP")
                return
            }
            const forgotPassword: IOtp = {
                email: email,
                otp: otp

            }
            setLoader(true)
            const forgotPasswordResponse: any = await authenticationService.OtpVerification(forgotPassword);
            if (forgotPasswordResponse.status === STATUS.SUCCESS) {
                setLoader(false)
                setOtpVerified(true);
                setShow(false)
            } else {
                setLoader(false)
                setShow(true)
                setMessage(forgotPasswordResponse.message)
            }
        } catch (error) {
            setLoader(false)
        }
    }

    const resend = async () => {
        try {
            const forgotPassword: any = {
                email: email.toLowerCase()
            }
            setLoader(true)
            const forgotPasswordResponse: any = await authenticationService.ForgotPassword(forgotPassword);
            if (forgotPasswordResponse.status === STATUS.SUCCESS) {
                setLoader(false)
            }
            else {
                setLoader(false)
                setShow(true)
                setMessage(forgotPasswordResponse.message)
            }
        }
        catch (error: any) {
        }
    }

    return (
        <div className="forgotPassword-page-2">
            <div className="logo-wrapper">
                <img src={brandLogo} alt="brandlogo" onClick={()=>navigate('/')} />
            </div>
            <div >
                <h1 className="heading">Forgot Password?</h1>
                <div className="space-lg"></div>
                <h3 className="sub-heading highlight text-lg">Please check your email: {email}</h3><br />
                <h3 className="sub-heading text-lg">Enter the OTP below</h3><br />
                <div>
                    <OTPInputComponent
                        autoFocus
                        length={4}
                        className="otpContainer"
                        inputClassName="otpInput"
                        onChangeOTP={(otp) => {
                            setOtp(otp)
                            setMessage("")
                        }}
                        setPasteOtp={setOtp}
                    />
                    <p className="otpWarning">{message}</p>
                </div>
                <div className="space-lg"></div>
                <p className="description-text text-lg">Didn't receive Code? <u className="resend-link" onClick={resend}>Resend OTP</u></p>
            </div>
            <button className="btn margin" onClick={formsubmit}>Submit</button>
            {show && <ErrorDialog show={show} setShow={setShow} message={message} />}
            {loader && <Loader />}
        </div>
    );
};