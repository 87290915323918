import * as Yup from 'yup'

export const changePasswordSchema = {
    password: "",
    newpassword: "",
    confirmPassword: ""
}
// eslint-disable-next-line
const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#\$%\^&\*])(?=.{8,})/
export const changePasswordValidations = Yup.object().shape({
    newpassword: Yup.string().trim().required('Please enter new password').matches(passwordRegex, "Password Should contain a minimum of 8 characters which includes 1 Upper case, 1 Lower case, 1 special character, 1 number"),
    confirmPassword: Yup.string().trim().required('Please enter confirm password').oneOf([Yup.ref('newpassword'), null], 'Passwords must match'),
    password: Yup.string().trim().required('Please enter old password').matches(passwordRegex, "Password Should contain a minimum of 8 characters which includes 1 Upper case, 1 Lower case, 1 special character, 1 number").notOneOf([Yup.ref('newpassword')], "Old and New Passwords can't be same")
})

