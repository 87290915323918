import React, { useEffect, useState } from "react";
import "./styles.scss";
import closeIcon from "../../assets/icons/x-circle.svg"
import { useFormik } from 'formik';
import { registerSchema, registerValidations } from './validators';
import { RootState } from "../../redux/Reducer";
import { useSelector } from "react-redux";
import { AuthService } from "../../services/auth.service";
import Loader from "../Loader";
import { STATUS } from "../../common/constant";
import * as toastr from 'toastr';



type Tprop = {
    show: boolean
    setLicensePlatePopUp: (open: boolean) => void
}

export const LicensePlatePopUp: React.FC<Tprop> = ({ setLicensePlatePopUp, show }) => {

    const authenticationService = new AuthService();
    const [loader, setLoader] = useState<boolean>(false);
    const [licensePlateValue, setLicensePlateValue] = useState<string>('');
    const [licnesePlateInput, setLicnesePlateInput] = useState<boolean>(false);
    const user_id = useSelector<RootState>((state) => state?.authData?.authData?.user_id?.toString())

    const registerSubmit = async (value: any) => {
        try {
            const profileData: any = {
                user_id: user_id,
                license_plate: value.license_plate.trim()
            }
            setLoader(true);
            const profileResponse: any = await authenticationService.EditProfile(profileData);
            if (profileResponse.status === STATUS.SUCCESS) {
                setLicensePlatePopUp(false);
                setLoader(false);
                toastr.success('License plate updated successfully');
            } else {
                setLicensePlatePopUp(false);
                setLoader(false);
            }
        } catch (error) {

        }
    }

    const getLicensePlate = async (): Promise<void> => {
        try {
            setLoader(true);
            const licensePlateResponse: any = await authenticationService.getLicensePlate(user_id);
            if(licensePlateResponse.status === STATUS.SUCCESS) {
                if (licensePlateResponse.data.license_plate) {
                    setLicensePlateValue(licensePlateResponse.data.license_plate);
                    setLoader(false);
                    setLicnesePlateInput(false)
                } else {
                    setLoader(false);
                    setLicnesePlateInput(true);
                }
            }
        } catch (error) {

        }
    }

    useEffect(() => {
        getLicensePlate()
    }, [])

    const registerFormik = useFormik({ initialValues: registerSchema, validationSchema: registerValidations, onSubmit: registerSubmit });

    return (
        <div className="common-box-ref">
            <div className="box">
                <div className="close">
                    <div className="dialog-btn-close" onClick={() => { setLicensePlatePopUp(false) }}>
                        <img src={closeIcon} alt="not-found" />
                    </div>
                </div>
                <div className="msg-container">
                    <form onSubmit={registerFormik.handleSubmit}>
                        {licnesePlateInput && 
                        <div>
                        <h4 className="text">Please Enter Your License Plate</h4>
                        <input type="text" className="license-plate-input-field" { ...registerFormik.getFieldProps('license_plate') } />
                        <button type="submit" className="dialog-btn dailog-subimit-btn">Submit</button>
                        {registerFormik.touched.license_plate && registerFormik.errors.license_plate ? (
                                        <p className="warn">{registerFormik.errors.license_plate}</p>
                                    ) : null}
                        </div>}
                    </form>
                    {!licnesePlateInput &&
                        <div className="license-plate-text-container">
                            <h2 className="text">Your License Plate</h2>
                            <h3 className="license-text text">{licensePlateValue}</h3>
                        </div>
                    }
                </div>
            </div>
            {loader && <Loader />}
        </div>
    );
};