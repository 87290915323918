import * as React from 'react';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Grow from '@mui/material/Grow';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import Stack from '@mui/material/Stack';
import { useRef, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { setAuthData } from '../../redux/Actions/actions';
import { RootState } from '../../redux/Reducer';
import {storeToParkingHero } from "../../common/constant";
import './styles.scss'

type Tprops = {
  show: boolean
  setShow: any
  anchorRef: any
  profile: string
}

export const MenuListComponent: React.FC<Tprops> = ({ show, setShow, anchorRef, profile, }) => {
  const navigate = useNavigate()
  const dispatch = useDispatch();
  const token: any = useSelector<RootState>((state) => state?.authData?.authData?.token?.access);
  let list: Array<string> = []
  let url: { [key: string]: string } = {}
  if (profile === "profile") {
    list = ["My Profile", "Sign Out"]
    url = { "My Profile": "/profile", "Sign Out": "/login" }
  }
  else if(profile==="settings"){
    list = ["Add Advertisment", "Edit  FAQ"]
    url = { "Add Advertisment": "/admin/adPage", "Edit  FAQ": "/admin/faq" }
  }
  else if(profile === "Woprofile") {
    list = ["Sign Out"]
    url = { "Sign Out": "/login" }
  }
  else if (!token) {
    list = ["Shop", "Support", "Contact Us", "FAQ"]
    url = { "Shop": storeToParkingHero, "Support": "/support", "Contact Us": "/contact", "FAQ": "/faq" }
  }
  else {
    list = ["Shop", "Support", "Settings", "Contact Us", "FAQ"]
    url = { "Shop": storeToParkingHero, "Support": "/support", "Settings": "/settings", "Contact Us": "/contact", "FAQ": "/faq" }
  }

  const handleClose = (event: Event | React.SyntheticEvent, e?: string) => {
    if (
      anchorRef.current &&
      anchorRef.current.contains(event.target as HTMLElement)
    ) {
      return;
    }
    if (e) {
      if (e === "Logout" || e === "Sign Out") {
        dispatch(setAuthData(undefined));
        localStorage.clear()
        navigate(url[e])
        return;
      }
      else if(e==="Shop")
      {
        window.open(url[e])
      }
      navigate(url[e])
    }
    setShow(false);
  };

  function handleListKeyDown(event: React.KeyboardEvent) {
    if (event.key === 'Tab') {
      event.preventDefault();
      setShow(false);
    } else if (event.key === 'Escape') {
      setShow(false);
    }
  }

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = useRef(show);
  useEffect(() => {
    if (prevOpen.current === true && show === false) {
      anchorRef.current!.focus();
    }

    prevOpen.current = show;
  }, [anchorRef, show]);


  return (
    <Stack direction="row" spacing={2}>
      <div className='menuList'>
        <Popper
          open={show}
          anchorEl={anchorRef.current}
          role={undefined}
          placement="bottom-end"
          transition
          disablePortal >
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{
                transformOrigin: 'right top',
              }}
            >
              <Paper>
                <ClickAwayListener onClickAway={(e) => handleClose(e)}>
                  <MenuList
                    autoFocusItem={show}
                    id="composition-menu"
                    aria-labelledby="composition-button"
                    onKeyDown={handleListKeyDown}
                  >
                    {
                      list.map((e, index) => <MenuItem key={index} onClick={(event) => handleClose(event, e)}>{e}</MenuItem>)
                    }
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </div>
    </Stack>
  );
}