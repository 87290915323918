import React, { useState } from "react";
import "./styles.scss";
import CTC from "../../assets/icons/CTC.png"
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { RefPhoneDialogBox } from "../refPhoneDialog"
import closeIcon from "../../assets/icons/x-circle.svg"
import { RootState } from "../../redux/Reducer";
import { setAuthData } from "../../redux/Actions/actions";


type Tprop = {
    show: boolean
    setShow: (open: boolean) => void
}

export const RefDialogBox: React.FC<Tprop> = ({ show, setShow }) => {

    const [show1, setShow1] = useState<boolean>(false)
    const userData: any = useSelector<RootState>((state) => state?.authData?.authData)
    const message = `Hi ${userData.hero_name} Your referral code is`
    const referralCode = userData.my_referal_code
    const dispatch = useDispatch();
    const navigate = useNavigate()
    const [copyStateChange, setCopyStateChange] = useState(false)

    const handleEmail = () => {
        setShow(false)
        userData.referal_code = referralCode
        dispatch(setAuthData(userData));
        navigate("/shareByEmail")

    }
    
    const clipboard = () => {
        navigator.clipboard.writeText(referralCode);
        setCopyStateChange(true);
            setTimeout(() => {
            setCopyStateChange(false);
        }, 1000)
    }

    return (
        <div className="forgot-box-ref">

            <div className="box">
                <div className="close">
                    <div className="dialog-btn-close" onClick={() => { setShow(false) }}>
                        <img src={closeIcon} alt="not-found" />
                    </div>
                </div>

                <div className="msg-container">
                    <p className="text">{message} <strong>{referralCode}
                    <button onClick={clipboard}>
                        <img className="ctc" src={CTC} alt="not-found" />
                    </button>
                        <span className={copyStateChange ? "copy-text-show" : "copy-text-hide"} >Copied!</span>
                    </strong></p>
                </div>

                <p className="send-text text">send via:</p>
                <div className="btn-container">
                    <button className="dialog-btn" onClick={handleEmail}>E-mail</button>
                    <button className="dialog-btn" onClick={() => setShow1(true)}>Text Message</button>
                </div>
            </div>
            {show1 && <RefPhoneDialogBox show={show1} setShow={setShow1} referralCode={referralCode}/>}
        </div>
    );
};