import React from "react";
import { gofundme, storeToParkingHero } from "../../common/constant";
import { Navbar } from "../../components/navbar";
import './style.scss'
import { FacebookShareButton } from "react-share";

export const SupportPage: React.FC = () => {

    return (
        <div className="support">
            <Navbar noAds={true} />
            <div className="supportPage">
                <div className="supportContent">
                    <h5 className="support-heading">SUPPORT PARKING HERO</h5>
                    <div className="support-container">
                        <p className="h4">Support Parking Hero By Following The Links Below:</p>
                        <div className="cont">
                            <p className="one">1. Spread The Word By&nbsp;
                                <FacebookShareButton
                                    url={"https://lbparkinghero.com"}
                                >  <u> Sharing </u>
                                </FacebookShareButton>
                                &nbsp;On Social media</p>
                            <p className="two">2. Donate Via:&nbsp; <u onClick={() => { window.open(gofundme) }}>Gofundme</u></p>
                            <p className="three">3. <u onClick={() => { window.open(storeToParkingHero) }}>Shop</u>: &nbsp; Parking Hero Apparel</p>
                        </div>
                    </div>
                </div>
            </div>
        </div >
    )

}
