import React, { useState } from "react";
import "./styles.scss";
import { AuthService } from "../../services/auth.service";
import { STATUS } from "../../common/constant";
import { registerSchema, registerValidations } from "./validators";
import { useFormik } from 'formik';
import TextFieldLogin from "../TextFieldLogin";
import { MobileField } from "../MobileField";
import Loader from "../Loader";

type Tprop = {
    show: boolean
    setShow: (open: boolean) => void
    email: any
    setlogin:(open:boolean)=>void
  
}

export const RegisterBox: React.FC<Tprop> = ({show, setShow,email,setlogin}) => {
    const authenticationService = new AuthService();
    const[loader,setLoader]=useState<boolean>(false)
  
    const registerSubmit=async(val:any)=>{
        const payload:any=val
        val.phone_number = val.countryCode+val.phoneNumber.toString()
        val.zip_code = val.zip_code.toString()
        val.email=email
    try{
     
        setLoader(true)
    const registerStat=await authenticationService.SocialRegister(payload)
    if(registerStat.status===STATUS.SUCCESS){
        setlogin(true)
        setLoader(false)
        setShow(false)
        // handleLogout()
    }
    else{
        if(registerStat.message === "Hero Name already exists for another user. Kindly enter unique hero name to proceed."){
            registerFormik.setFieldError('hero_name',registerStat.message)
        }else{
            registerFormik.setFieldError('phoneNumber',registerStat.message)
        }
       setLoader(false)
    }}
    catch(err:any){

    }
    }

    const registerFormik = useFormik({ initialValues: registerSchema, validationSchema: registerValidations, onSubmit: registerSubmit });
    return (
        <div className={`dialog-box dialogBox`}>
            <div className="box loginBox">
            <form onSubmit={registerFormik.handleSubmit} >
            <TextFieldLogin
                        label='Hero name'
                        formik={{ ...registerFormik.getFieldProps('hero_name') }}
                        error={registerFormik.touched.hero_name && registerFormik.errors.hero_name}
                        errorText={registerFormik.errors.hero_name}
                    />
                    <MobileField
                        label='Phone'
                        formikCode={{ ...registerFormik.getFieldProps('countryCode') }}
                        formikNumber={{ ...registerFormik.getFieldProps('phoneNumber') }}
                        error={registerFormik.touched.phoneNumber && registerFormik.errors.phoneNumber}
                        errorText={registerFormik.errors.phoneNumber}
                    />
                    <TextFieldLogin
                        label='Zipcode'
                        type={"number"}
                        formik={{ ...registerFormik.getFieldProps('zip_code') }}
                        error={registerFormik.touched.zip_code && registerFormik.errors.zip_code}
                        errorText={registerFormik.errors.zip_code}
                    />
                    <button type='button' onClick={() => setShow(false)}>Cancel</button>
                    <button type='submit'>Register</button>
                    </form>
               </div>
               {loader && <Loader />}
        </div>
    );
};