import { applyMiddleware, createStore } from "redux";
import thunk from "redux-thunk";
import { composeWithDevTools } from "redux-devtools-extension";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { encryptTransform } from 'redux-persist-transform-encrypt';
import { RootReducer } from "./Reducer";

const persistConfig = {
    key: "Parking Hero",
    storage,
    transforms: [
        encryptTransform({
          secretKey: "redux_secret_code_1234",
          onError: function (error) {
          },
        }),
      ],
};

// Persist is used for storing the value even after the page re-renders.

const persistedReducer = persistReducer(persistConfig, RootReducer);
const store: any = createStore(persistedReducer, composeWithDevTools(applyMiddleware(thunk)));
const persistor: any = persistStore(store);

export { store, persistor };