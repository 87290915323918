import React, { useEffect, useRef, useState } from "react";
import editIcon from "../../assets/icons/edit.png"
import brandLogo from "../../assets/parkingHeroLogo.png";
import userProfileimg from "../../assets/icons/user.png";
import burgerMenu from "../../assets/icons/icons8-menu-120.png"
import adimg from "../../assets/icons/adIMG.png"
import Settings from "../../assets/icons/setting.png"
import comments from "../../assets/icons/chat.png"
import "./styles.scss";
import { Link } from "react-router-dom";
import { MenuListComponent } from "../menuList";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/Reducer";
import { HeroStatMenuList } from "../heroStatMenuList";
import { MessageDialog } from "../messageDialog/messageDialog";
import { MESSAGE_BOX_HEADER } from "../../common/constant";
import { AuthService } from "../../services/auth.service";

type Tprop = {
    noAds?: boolean;
    editButton?: boolean;
    handleEdit?: () => void;
    Admin?: boolean
    account?:boolean
    trueEdit?:boolean
}

export const Navbar: React.FC<Tprop> = ({ noAds, editButton, Admin ,account, trueEdit}) => {
    const token: any = useSelector<RootState>((state) => state?.authData?.authData?.token?.access);
    const [show, setShow] = useState<any>(false)
    const [showMessageDilog, setShowMessageDilog] = useState<any>(false)
    const anchorRef = useRef<any>(null);
    const [show2, setShow2] = useState<any>(false)
    const anchorRef2 = useRef<any>(null);
    const anchorRef3 = useRef<any>(null);
    const anchorRef4 = useRef<any>(null);
    const [show3, setShow3] = useState<any>(false)
    const [show4, setShow4] = useState<any>(false)
    const userData: any = useSelector<RootState>((state) => state?.authData?.authData)
    const message = `"Welcome ${userData?.hero_name} ! You can use the buttons below me to Access your QR Code, Referral Code, Check Your Messages, and see your offers"`
    const [messageForward, setMessageForward] = useState<string>("")
    const authenticationService = new AuthService();
    const [adImg, setadImg] = useState<any>()
    const [adLink, setadLink] = useState<any>()

    const getAdd = async () => {
        try {
            const { data }: any = await authenticationService.adminAdvertisement();
            setadImg(data.advertisement_details.advertisement);
            setadLink(() => {
                let url = data.advertisement_details.link || "";
                let newLink = url;
                if (url.includes("https://")) {
                    newLink = url.replace("https://", "");
                } else if (url.includes("http://")) {
                    newLink = url.replace("http://", "");
                }
                return newLink
            });
        } catch (error) {  
        }
    }
    
    useEffect(() => {
        getAdd(); // eslint-disable-next-line
    }, [])
     
    
    return (
        <div className="navbar .no-print">
            <div className="head-container flex flex-row">
                <div className="logo-wrapper basis-1/4 flex flex-row" >
                    <div className="logo" ref={anchorRef3} onMouseEnter={() => setShow3(true)} onMouseLeave={() => setShow3(false)}>
                   { !account ? <Link to={'/'}><img src={brandLogo} alt="logo" /></Link> : <div><img src={brandLogo} alt="logo" /></div>}
                    </div>
                    {account && <HeroStatMenuList show={show3} logo={true} setShow={setShow3} position={"left top"} anchorRef={anchorRef3} message={message} />}
                </div>
                {noAds ? <div className="ad-container basis-2/4 flex flex-row"></div> :
                    <div className="ad-container basis-2/4 flex flex-row">
                        <img src={adImg ? adImg : adimg} className="adimg" alt="adimg" onClick={() => adLink && window.open(`https://${adLink}`, '_blank')} style={{cursor: "pointer"}} />
                    </div>}

                {!Admin && <div className="end-icons basis-1/4 flex flex-row-reverse">
                    <button>
                        <img src={burgerMenu} width={40} height={40} onClick={() => setShow(!show)} ref={anchorRef} alt="burger" />
                        <MenuListComponent show={show} setShow={setShow} anchorRef={anchorRef} profile="menu" />
                    </button>
                    {token && <button>
                        <img src={userProfileimg} width={40} height={40} onClick={() => setShow2(!show2)} ref={anchorRef2} alt="profile" />
                        <MenuListComponent show={show2} setShow={setShow2} anchorRef={anchorRef2} profile={window.location.pathname !== '/profile' ? "profile" : "Woprofile"} />
                    </button>}
                </div>}
                {Admin && <div className="end-icon basis-1/4 flex flex-row-reverse">
                    <button>
                        <img src={comments} width={40} height={40} onClick={() => {setShow(!show); setShowMessageDilog(true)}} ref={anchorRef} alt="comments" />
                    </button>
                    <button>
                        <img src={Settings} width={40} height={40} onClick={() => setShow2(!show2)} ref={anchorRef2} alt="settings" />
                        <MenuListComponent show={show2} setShow={setShow2} anchorRef={anchorRef2} profile="settings" />
                    </button>
                    <button>
                        <img src={userProfileimg}  width={40} height={40} className="imagInvert" onClick={() => setShow4(!show4)} ref={anchorRef4} alt="admin" />
                        <MenuListComponent show={show4} setShow={setShow4} anchorRef={anchorRef4} profile={window.location.pathname !== '/profile' ? "profile" : "Woprofile"} />
                    </button>
                </div>}
                {showMessageDilog && <MessageDialog broadcast={true} displayContent={MESSAGE_BOX_HEADER.message2} setSendMessageDialog={setShowMessageDilog} setMessageForward={setMessageForward} messageForward={messageForward}/>}
            </div>
        </div>
    )
}