import React, { useEffect, useState } from "react";
import "./styles.scss"
import downBtn from "../../assets/icons/up-arrow.png"
import { AuthService } from "../../services/auth.service";


type Tprop = {
  isOpen: boolean
  setIsOpen: (open: boolean) => void
  selectedOption: any
  setSelectedOption: (open: any) => void
  options: any
  values?: boolean
  setSelectedValue?: any
  setapiFile?: any
  setLoader?: (open: boolean) => void
}

export const Dropdown: React.FC<Tprop> = ({ isOpen, setIsOpen, selectedOption, setSelectedOption, options, values, setSelectedValue, setapiFile, setLoader }) => {
  const toggling = () => setIsOpen(!isOpen);
  const authenticationService = new AuthService();
  const [previousValue, setPreviousValue] = useState<any>();



  const onOptionClicked = (value: any) => () => {
    setSelectedOption(value);
    setIsOpen(false);
  };
  const onOptionClickedNew = (option: any) => () => {
    setSelectedOption(option.message);
    setIsOpen(false);
    setSelectedValue(option.value);
    setPreviousValue(option.value);
  };
  const previousData = async () => {
    setLoader && setLoader(true)
    const previousUploadData: any = await authenticationService.Offers();
    setLoader && setLoader(false);
    if (previousValue === 1) {
      setapiFile(previousUploadData.data[1]);
      setLoader && setLoader(false);
    }
    else if (previousValue === 5) {
      setapiFile(previousUploadData.data[2]);
      setLoader && setLoader(false);
    }
    else if (previousValue === 10) {
      setapiFile(previousUploadData.data[3]);
      setLoader && setLoader(false);
    }
    else if (previousValue === 15) {
      setapiFile(previousUploadData.data[4]);
      setLoader && setLoader(false);

    }


  }

  useEffect(() => {
    previousData();
  }, [selectedOption])


  return (
    <div className="main">
      <div className="drop-down-container1">
        <div className="dropdown-top1">
          <div className="drop-down-header1" onClick={toggling}>
            {selectedOption || "Choose"}
          </div>
          <div className="icon-div" onClick={toggling}>
            <img
              src={downBtn}
              style={isOpen ? { transform: "rotate(0deg)" } : { transform: "rotate(180deg)" }}
              alt="not-found"
              className={isOpen ? "downArrow-img" : "down-btn-img"}
            />
          </div>
        </div>

        {isOpen && (
          <div>
            <ul className="drop-down-list1">{!values ?
              options.map((option: any) => (
                <li className="list-item1" onClick={onOptionClicked(option)} key={Math.random()}>
                  {option}
                </li>
              ))
              :
              options.map((option: any) => (
                <li className="list-item1" onClick={onOptionClickedNew(option)} key={Math.random()}>
                  {option.message}
                </li>
              ))
            }
            </ul>
          </div>
        )}
      </div >
    </div >
  );
}