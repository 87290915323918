import React, { useState } from 'react'
import styles from "./styles.module.scss";
import { VisibilityOffOutlined, VisibilityOutlined } from '@mui/icons-material';

type textFieldProps = {
  label?: string,
  formik?: any,
  errorText?: any
  error?: any,
  showTick?: boolean,
  hint?: string,
  pattern?: any
} & React.InputHTMLAttributes<HTMLInputElement>

const TextFieldLogin: React.FC<textFieldProps> = ({ label, type, pattern, formik, error, hint, errorText, showTick, ...props }) => {
  const [showPassword, setShowPassword] = useState<boolean>(false)
    const togglePasswordVisiblity = () => {
        setShowPassword(!showPassword);
    };

  return (
    <div className={styles.textField}>
      <div className={styles.textFieldContainer}>
        {label && <h4 className={styles.lableField}>{label}</h4>}
        <div className={styles.inputWrap}>
          <div className={styles.visible}>
          <input type={(type === "password" && !showPassword) ? "password" : "text"} {...props} {...formik} className={styles.inputField} />
          {type === "password" && <span>
              {showPassword ? (<VisibilityOutlined  onClick={togglePasswordVisiblity} />) : (<VisibilityOffOutlined onClick={togglePasswordVisiblity} />)}
          </span>}
          </div>
          {error && <p className={styles.error}>{errorText}</p>}
        </div>
      </div>
    </div>
  )
}

export default TextFieldLogin