import React from "react";
import "./styles2.scss";
import { useNavigate } from "react-router-dom";
import closeIcon from "../../assets/icons/x-circle.svg"

type Tprop = {
    show: boolean
    setShow: (open: boolean) => void
}

export const RefEmailDialogBox: React.FC<Tprop> = ({ show, setShow }) => {

    const message = "Referral Code has been sent to the recipient(s)"
    const navigate = useNavigate()
    
    const handleOk = () => {
        setShow(false)
        navigate("/account")
    }

    return (
        <div className="forgot2-box-emailref">
            <div className="box2">
                <div className="close">
                    <div className="dialog-btn-close" onClick={() => { setShow(false) }}>
                    <img src={ closeIcon } alt="not-found" />
                    </div>
                </div>
                <p className="text">{message}</p>
                <button className="dialog-btn" onClick={handleOk}>OK</button>
            </div>
        </div>
    );
};