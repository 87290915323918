/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Navbar } from "../../components/navbar";
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import "./styles.scss";
import Loader from "../../components/Loader";
import { Dropdown } from "../../components/customDropdown";
import { timeForAction_OPTIONS,STATUS } from "../../common/constant";
import { TFADialogModal } from "../../components/TFAmodal";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/Reducer";
import { useParams } from "react-router-dom";
import { AuthService } from "../../services/auth.service";
import { ErrorDialog } from "../../components/errorDialog";
import closeIcon from "../../assets/icons/x-circle.svg"
import { SuccessDialogBox } from "../../components/successDialog";
import { BaseService } from "../../services/base.service";

export const TimeForActionPage: React.FC = () => {
    const options = [
        timeForAction_OPTIONS.message1,
        timeForAction_OPTIONS.message2,
        timeForAction_OPTIONS.message3,
        timeForAction_OPTIONS.message4,
        // timeForAction_OPTIONS.message5,
        timeForAction_OPTIONS.message6,
        // timeForAction_OPTIONS.message7,
        // timeForAction_OPTIONS.message8,
        timeForAction_OPTIONS.message9,
    ];
    const authenticationService = new AuthService();
    const params: any = useParams();
    const qrUserId = params?.qrUserId
    // const [username, setUsername] = useState<string>('')
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const [checked, setChecked] = useState<boolean>(false)
    const [selectedOption, setSelectedOption] = useState<string>("");
    const token = useSelector<RootState>((state) => state?.authData?.authData?.token?.access);
    const [show, setShow] = useState<boolean>(false)
    const [sucessDialog, setSucessDialog] = useState<boolean>(false)
    const [loader, setLoader] = useState<boolean>(false);
    const [errorDialog, setErrorDialog] = useState(false)
    const [error, setError] = useState(false)
    const [message, setMessage] = useState<string>('')
    const [file, setFile] = useState<any>()
    const [comments, setComments] = useState<string>('')
    const [location, setLocation] = useState<any>()
    const [submitError, setSubmitError] = useState(false)
    const userData: any = useSelector<RootState>((state) => state?.authData?.authData)
    const baseSercvice = new BaseService();
    const handleCheck = (event: any) => {
        setChecked(event.target.checked)
        setSubmitError(!event.target.checked) 
    }

    const getIp = async () => {
        const ip: any = await baseSercvice.getIp()
        if (ip !== undefined) {
            setLocation(ip.city)
        }
        else {
            console.log("can't get ip of this device")
            // getLocation()
            setLocation("");
        }
    }
    const isCredential = "True"
    const getProfileDeatails = async (): Promise<void> => {
        try {
            setLoader(true)
            const profileResponse: any = await authenticationService.ProfileGet(qrUserId, isCredential);
            setLoader(false)
            if (profileResponse.status === STATUS.SUCCESS) {
                // setUsername(profileResponse.data.hero_name)
                setLoader(false)
            }
            else {
                setErrorDialog(true)
                setMessage(profileResponse.message)
                setLoader(false)
            }
        } catch (error) {

        }
    }

    const handleFile = (e: any) => {
        let selFile = e?.target?.files[0];
        if (selFile && (selFile.type.split("/")[0] === 'image' || selFile.type.split("/")[0] === 'video')) {
            const files = (e.target as HTMLInputElement).files

            if (files && files.length > 0) {
                setFile(files[0])
            }
        } else {
            setError(true);
            setTimeout(() => {
                setError(false)
            }, 3000)
        }
    }

    const handleSubmit = async () => {
        try {
            if(checked){
                if (!selectedOption.includes("*")) {
                    setFile("")
                    setComments("")
                }

                let formData = new FormData();
                formData.append("attachments", file || '');
                formData.append("incident_details", selectedOption.replace('*', ''));
                formData.append("comments", comments || '');
                formData.append("reported_by_id", userData.user_id);
                formData.append("victim_user_id", qrUserId);
                formData.append("location", location);
                setLoader(true)
                const timeForActionResponse: any = await authenticationService.timeForAction(formData);
                setLoader(false)
                if (timeForActionResponse.status === STATUS.SUCCESS) {
                    setFile("")
                    setComments("")
                    setSelectedOption("")
                    setChecked(false)
                    setSucessDialog(true)
                    setMessage(timeForAction_OPTIONS.message10,)
                }
                else {
                    setErrorDialog(true)
                    setMessage(timeForActionResponse.message)
                }
        }else{
            setSubmitError(true)  
        }

        } catch (error) {

        }
    }


    useEffect(() => {
        // getLocation()
        if (token) {
            setShow(false)
            getProfileDeatails()
            getIp()
            return
        }

        setShow(true)
    }, [])

    return (
        <>
            <Navbar noAds={true} />
            <div className="time-for-action-page">
                <div className="heading">
                    <h1>TIME FOR ACTION {token ? userData?.hero_name : ""}</h1>
                </div>
                <div className="space"></div>
                <div className="description">
                    <p>Select An incident From the Below Dropdown</p>
                </div>
                <div className="space"></div>
                <div className="dropdown">
                    <Dropdown isOpen={isOpen} setIsOpen={setIsOpen} selectedOption={selectedOption} setSelectedOption={setSelectedOption} options={options} />
                </div>
                <div className="space"></div>
                <div className="check">
                    <fieldset disabled={!selectedOption} >
                        <FormControlLabel
                            control={
                                <Checkbox
                                    sx={{
                                        color: "white",
                                        "&.Mui-checked": {
                                            color: "white",
                                        },
                                    }}
                                    checked={checked}
                                    onChange={(event) => { handleCheck(event) }}

                                />
                            }
                            label="Confirm"
                            sx={{
                                color:"white",
                            }}
                        />
                    </fieldset><br />

                    <div className="hidden-container">
                        {!!selectedOption && selectedOption.includes("*") ?
                            <div className="container important">
                                <div className="attachments">
                                    {!file ?  <div className="button">
                                            <button
                                                onClick={() => {
                                                    document.getElementById('upload')?.click()
                                                }}
                                                className="btn"
                                            >
                                                <p>{"Upload Photo"}</p>
                                            </button>
                                            <input type="file" id="upload" className="upload" accept="image/*" onChange={(e) => handleFile(e)} required />
                                            {error && <div className="error">Please add only Photos</div>}
                                        </div>
                                       
                                     :
                                        <div className="after-attach">
                                            <p className="file-name">{file?.name}</p>
                                            <img className="close-icon" src={closeIcon} alt={"not found"} onClick={() => {
                                                setFile(null)
                                            }} />
                                        </div>
                                    }
                                    <div className="text">
                                        {!file && <p>Please add a photo and or comment that’s helpful to your neighbor.</p>}
                                    </div>

                                </div>
                                <div className="comments">
                                    <textarea className="comment-ele" name="comments" id="comments" cols={30} rows={10} placeholder="Insert comments here." onChange={(e) => {
                                        setComments(e.target.value)
                                    }} ></textarea>
                                </div>
                                <div className="button-ele">
                                    <button
                                        className= "btn"
                                        // disabled={file && checked ? false : true}
                                        style={ {cursor: "pointer"}}
                                        onClick={handleSubmit}
                                    >
                                        Submit
                                    </button>
                                    {submitError && <div className="error">Please confirm you want to submit this incident</div>}
                                </div>
                            </div> 
                            :
                            <>
                            <button
                                className= "white-btn"
                                // disabled={checked}
                                onClick={handleSubmit}
                            >
                                Submit
                            </button> 
                            {submitError && <div className="error">Please confirm you want to submit this incident</div>}
                            </>
                        }
                    </div>
                    <div className="space"></div>
                    <div className="space"></div>
                    {sucessDialog && <div className="description">
                        <p className="description-text">Your Work here is Done</p>
                        <p className="description-text">Check your account later for status Report</p>
                        <p className="description-text">Thank You!!</p>
                    </div>}
                </div>
                <div className="space"></div>
                {show && <TFADialogModal show={show} setShow={setShow} userId={qrUserId} />}
                {sucessDialog && <SuccessDialogBox show={sucessDialog} message={message} setShow={setSucessDialog} logout={false} navigator={"account"} />}
                {errorDialog && <ErrorDialog show={errorDialog} setShow={setErrorDialog} message={message} />}
                {loader && <Loader />}
            </div>
        </>
    )
}
