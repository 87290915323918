import React from "react";
import "./styles.scss";
import {faQrcode, faCommentDots} from "@fortawesome/free-solid-svg-icons";
import Icon from "../Icon";
import ToggleButton from "../ToggleButton";
import { useNavigate } from "react-router-dom";
import { Tooltip } from "@mui/material";


type Tprop = {
  user: any;
  onClick?: any;
  setShowQR?:any,
  setSendMessageDialog?:any,
  isNoRecords?:boolean,
};

const UserDetailsLog: React.FC<Tprop> = ({
  user,
  onClick,
  setShowQR,
  setSendMessageDialog,
  isNoRecords
}) => {
     const navigate = useNavigate()

  return (
    <div className="user-logCard" onClick={() => onClick()}>
        {isNoRecords ? 
         <div className="logCard-noRecord">
         <p>No Records</p>
      </div> :
        <>
         <div className="logCard-row">
         <p>Hero Name:</p>
        <p className="logCard-value" onClick={() => { navigate("/admin/userManagement", { state: user }) }}>{user.hero_name}</p>
      </div>
      <div className="logCard-row">
        <p>Email id:</p>
          <p className="logCard-value" onClick={() => { navigate("/admin/userManagement", { state: user }) }}>{user.email_id}</p>
      </div>
      <div className="logCard-row">
        <p>QR Code:</p>
        <p className="logCard-value" onClick={() => setShowQR(true)}>
         <Icon icon={faQrcode} />
        </p>
      </div>
      <div className="logCard-row">
        <p>Phone:</p>
          <p className="logCard-value" onClick={() => { navigate("/admin/userManagement", { state: user }) }}> {user.phone_number}</p>
      </div>
      <div className="logCard-row">
        <p>Zip Code:</p>
        <p className="logCard-value" onClick={() => { navigate("/admin/userManagement", { state: user }) }}>
         {user.zip_code}
        </p>
      </div>
      <div className="logCard-row">
        <p>Last Logged On:</p>
        <p className="logCard-value" onClick={() => { navigate("/admin/userManagement", { state: user }) }}>
        {new Date(user.last_logged_on).toLocaleDateString()}
        </p>
      </div>
      <div className="logCard-row">
        <p>Send Message:</p>
         <Tooltip className="tooltip" title="Click on this button to send Individual text message to Heroes" placement="bottom-end" arrow >
        <p className="logCard-value" onClick={() => setSendMessageDialog(true)}>
        <Icon icon={faCommentDots} />
        </p>
        </Tooltip>
      </div>
      <div className="logCard-row">
        <p>Block:</p>
        <p className="logCard-value">
        <ToggleButton value={user.blocked} heroName={user.hero_name} userId={user.user_id} />
        </p>
      </div>
       <div className="logCard-row">
        <p>Opt Out of Sale:</p>
        <p className="logCard-value">
        {user.sell ? "YES" : "NO"}
        </p>
      </div>
      <div className="logCard-row">
        <p>License Plate:</p>
        <p className="logCard-value" onClick={() => { navigate("/admin/userManagement", { state: user }) }}>
         {user.license_plate || '-'}
        </p>
      </div>
        </>}
    </div>
  );
};

export default UserDetailsLog;
