import React from "react";
import brandLogo from "../../assets/parkingHeroLogo.svg";
import "./styles.scss";
import { useLocation, useNavigate} from "react-router-dom";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/Reducer";

export const IncidentDetailsPage: React.FC = () => {
    const location: any = useLocation();
    const navigate: any = useNavigate();
    const userData: any = useSelector<RootState>((state) => state?.authData?.authData)
    return (
        <>
            <div className="incident-details-page">
                <div className="logo-wrapper">
                    <img src={brandLogo} alt="" onClick={()=>navigate('/')} />
                </div>
                <br />
                <div className="heading">
                    <h1 className="heading-text">Incident Details</h1>
                </div>

                <div className="space"></div>

                <div className="main-container">
                    <p className="white-line"></p>

                    <div className="field-container">
                        <div className="label basis-1/2">
                            <p className="label-text">Date:</p>
                        </div>
                        <div className="value basis-1/2">
                            <p className="value-text">
                                {" "}
                                {new Date(
                                    location.state?.time
                                ).toLocaleDateString()}
                            </p>
                        </div>
                    </div>

                    <p className="white-line"></p>

                    <div className="field-container">
                        <div className="label basis-1/2">
                            <p className="label-text">Time:</p>
                        </div>
                        <div className="value basis-1/2">
                            <p className="value-text">
                                {new Date(location.state?.time)
                                    .toLocaleTimeString()
                                    .replace(/(.*)\D\d+/, "$1")}
                            </p>
                        </div>
                    </div>

                    <p className="white-line"></p>

                    <div className="field-container">
                        <div className="label basis-1/2">
                            <p className="label-text">Incident Type:</p>
                        </div>
                        <div className="value basis-1/2">
                            <p className="value-text">
                                {location.state?.incident_details.toString()}
                            </p>
                        </div>
                    </div>

                    <p className="white-line"></p>

                    <div className="field-container">
                        <div className="label basis-1/2">
                            <p className="label-text">Location:</p>
                        </div>
                        <div className="value basis-1/2">
                            <p className="value-text">
                                {location.state?.location}
                            </p>
                        </div>
                    </div>

                    <p className="white-line"></p>

                    <div className="field-container">
                        <div className="label basis-1/2">
                            <p className="label-text">Status:</p>
                        </div>
                        <div className="value basis-1/2">
                            <p className="value-text">
                                {location.state?.status.replace("_"," ")}
                            </p>
                        </div>
                    </div>

                    <p className="white-line"></p>

                    <div className="field-container">
                        <div className="label basis-1/2">
                            <p className="label-text">Attachments:</p>
                        </div>
                        <div className="value basis-1/2">
                            <p className="value-text1">
                            {location.state?.attachments ? location.state?.attachments?.includes("videos") ? 
                            <video src={location.state.attachments} className="attachment-img"/>
                            : <img src={location.state.attachments} className="attachment-img" alt="attachment_img"></img> : "No attachments"}
                            </p>
                        </div>
                    </div>

                    <p className="white-line"></p>

                    <div className="field-container">
                        <div className="label basis-1/2">
                            <p className="label-text">
                                Incident Reported by You:
                            </p>
                        </div>
                        <div className="value basis-1/2">
                            <p className="value-text firstCapse">
                                {location.state?.reported_by_id === userData?.hero_name ? "Yes" : "No"}
                            </p>
                        </div>
                    </div>

                    <p className="white-line"></p>

                    <div className="space"></div>
                </div>
            </div>
        </>
    );
};
