/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { STATUS } from "../../common/constant";
import { AuthService } from "../../services/auth.service";
import "./styles.scss";
import { Navbar } from "../../components/navbar";
import { FAQContainer } from "../../components/faqContainer/index";
import {
    FAQAddDialogBox,
    FAQDialogBoxResult,
} from "../../components/faqDialogBox";
import Loader from "../../components/Loader";
import googleIMG from "../../assets/icons/googleAD.png";

type Tprops = {
    role?: string;
};

export const FAQPage: React.FC<Tprops> = ({ role }) => {
    const [data, setData] = useState<Array<any>>([]);
    const [showDialog, setshowDialog] = useState<boolean>(false);
    const [result, setResult] = useState<boolean>(false);
    const [state, setState] = useState<string>("");
    const authenticationService = new AuthService();
    const [loader, setLoader] = useState<boolean>(false);

    const faqGet = async () => {
        try {
            setLoader(true);
            const faqResponse: any = await authenticationService.FaqContent();
            setLoader(false);
            if (faqResponse.status === STATUS.SUCCESS) {
                setData(faqResponse.data.FAQ);
            }
        } catch (error) {}
    };

    useEffect(() => {
        faqGet();
    }, []);

    return (
        <>
            {role === "admin" ? <Navbar noAds Admin={role === "admin"} /> : <Navbar />}
            <div className={role === "admin" ? "faq-page admin" : "faq-page"}>
                <div className="faq-content">
                    <h5 className="faq-heading">FAQ</h5>
                    <div className="faq-container">
                        {data?.map((element: any, index: number) => (
                            <FAQContainer
                                role={role === "admin" ? role : ""}
                                key={index}
                                title={element.question}
                                description={element.answer}
                                faqID={element.id}
                                setLoader={setLoader}
                                apiRerender={() => faqGet()}
                            />
                        ))}
                    </div>
                </div>
                {role === "admin" ? (
                    <div className="add-faq-btn">
                        <button
                            className="btn"
                            onClick={() => {
                                setState("add");
                                setshowDialog(true);
                            }}>
                            Add FAQ
                        </button>
                    </div>
                ) : (
                    <></>
                )}
                <br />
                <br />
                {showDialog && (
                    <FAQAddDialogBox
                        setShow={setshowDialog}
                        setLoader={setLoader}
                        apiRerender={() => faqGet()}
                    />
                )}
                {result && (
                    <FAQDialogBoxResult
                        status={state}
                        setShow={setResult}
                    />
                )}
                {loader && <Loader />}
            </div>
            {role === "admin" ? (
                <></>
            ) : (
                <div className="navbar .no-print">
                    <div className="head-container flex flex-row">
                        <div className="logo-wrapper basis-1/4 flex flex-row"></div>
                        <div className="ad-container basis-2/4 flex flex-row">
                            <img
                                className="googleAdimg"
                                src={googleIMG}
                                alt="GoogleAD"
                            />
                        </div>
                        <div className="end-icons basis-1/4 flex flex-row-reverse"></div>
                    </div>
                </div>
            )}
        </>
    );
};
