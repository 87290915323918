import * as React from 'react';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Grow from '@mui/material/Grow';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import MenuList from '@mui/material/MenuList';
import Stack from '@mui/material/Stack';
import { useRef, useEffect } from 'react';
import "./styles.scss"

type Tprops = {
  show: boolean
  setShow: any
  anchorRef: any
  message: string
  position?: string
  logo?: boolean
}

export const HeroStatMenuList: React.FC<Tprops> = ({ logo, show, setShow, anchorRef, message, position }) => {

  const handleClose = (event: Event | React.SyntheticEvent) => {
    if (
      anchorRef.current &&
      anchorRef.current.contains(event.target as HTMLElement)
    ) {
      return;
    }

    setShow(false);
  };


  // return focus to the button when we transitioned from !open -> open
  const prevOpen = useRef(show);
  useEffect(() => {
    if (prevOpen.current === true && show === false) {
      anchorRef.current!.focus();
    }

    prevOpen.current = show;
  }, [anchorRef, show]);


  return (
    <Stack direction="row" spacing={2}>
      <div className='tooltipAlign'>
        <Popper
          open={show}
          anchorEl={anchorRef.current}
          role={undefined}
          placement="right-end"
          style={logo ? { maxWidth: "300px", paddingLeft: "50px" } : { maxWidth: "250px" }}
          transition
          disablePortal
        >
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{
                transformOrigin: position ? position : 'left bottom', border: "1px solid black", borderRadius: "8px", padding: "1px 20px"
              }}
            >
              <Paper>
                <ClickAwayListener onClickAway={handleClose}>
                  <MenuList
                    autoFocusItem={show}
                    id="composition-menu"
                    aria-labelledby="composition-button"
                    className={logo ? 'bubble-msg' : ''}
                  >
                    {message}
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </div>
    </Stack>
  );
}