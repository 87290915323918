import { useSelector } from "react-redux";
import { RootState } from "../redux/Reducer";
import { PrivateRoutes } from "./privateRoutes";
import { PublicRoutes } from "./publicRoutes";

export const RootRoutes = () => {
    const token = useSelector<RootState>((state) => state?.authData?.authData?.token?.access);

    return (
        <>
            {token ? <PrivateRoutes /> : <PublicRoutes />}
            <div className="footer no-print">
                All rights reserved by Parking Hero, LLC
            </div>
        </>
    );
};
