/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import "./style.scss";
import closeIcon from "../../assets/icons/x-circle.svg"
import { useNavigate } from "react-router-dom";
import { AuthService } from "../../services/auth.service";
import { STATUS, Email_not_verified, Mobile_not_verified, not_verified } from "../../common/constant";
import Loader from "../../components/Loader";
import checkedBox from "../../assets/svg/checkedBox.svg"
import { useDispatch } from "react-redux";
import { setAuthData } from "../../redux/Actions/actions";

type Tprop = {
    contentToDisplay: any,
    show: boolean
    email: string
    loginFormik?: any
    setShow: (open: boolean) => void
    firstTimeLogin?: boolean,
    setFirstTimeLogin: (open: boolean) => void,
    userName?: string
    loginDetails: any
}
export const AccountVerifyDialogBox: React.FC<Tprop> = ({ loginDetails, show, setShow, loginFormik, contentToDisplay, email, firstTimeLogin, setFirstTimeLogin, userName }) => {
    const authenticationService = new AuthService();
    const dispatch = useDispatch()
    const [loader, setLoader] = useState<boolean>(false);
    const [emailClose, setEmailClose] = useState<boolean>(contentToDisplay?.email ? true : false)
    const [mobileClose, setMobileClose] = useState<boolean>(contentToDisplay?.mobile ? true : false)
    const navigate = useNavigate()

    const close = () => {
        setShow(false);
        setFirstTimeLogin(false)
        navigate("/login")
        dispatch(setAuthData(loginDetails));
        loginFormik?.reset();
    }

    async function ResendVerifyEmailAPI() {
        const verifyEmailData: any = {
            email: email
        }
        setLoader(true)
        const ResendverifyEmailResponse: any = await authenticationService.EmailResendVerification(verifyEmailData);
        setLoader(false)
        if (ResendverifyEmailResponse.status === STATUS.SUCCESS) {
            setEmailClose(false)
            if (!mobileClose) {
                close();
            }
        } else {
            console.log("resend email verification failed")
        }
    }

    async function ResendVerifyMobileAPI() {
        const verifyEmailData: any = {
            email: email
        }
        setLoader(true)
        const ResendverifyMobileResponse: any = await authenticationService.MobileResendVerification(verifyEmailData);
        setLoader(false)
        if (ResendverifyMobileResponse.status === STATUS.SUCCESS) {

            setMobileClose(false)
            if (!emailClose) {
                close();
            }
        }
    }

    useEffect(() => {
        if (contentToDisplay?.email_mobile === true) {
            setEmailClose(true)
            setMobileClose(true)
        }
    }, [])

    return (
        <div className="AccountVerificationdialog-box">
            <div className="box">
                <div className="close">
                    <div className="dialog-btn-close" onClick={() => close()}>
                        <img src={closeIcon} alt="not-found" />
                    </div>
                </div>
                {firstTimeLogin ?
                    <div className="dialog_content_first_time">
                        <p className="content">Welcome ___{userName}___!</p>
                        <div className="verification-content">
                            <p className="content"><img className="check-box" src={checkedBox} alt={"not found"} />SMS verified</p>
                            <p className="content"><img className="check-box" src={checkedBox} alt={"not found"} />E-mail verified</p>
                        </div>
                    </div>
                    :
                    <div className="dialog_content">
                        {contentToDisplay?.email &&
                            <div>
                                <p>{Email_not_verified.content}</p>
                                <p className="linkcontent" onClick={ResendVerifyEmailAPI}>{Email_not_verified.linkContent}</p>
                            </div>}
                        {contentToDisplay?.mobile &&
                            <div>
                                <p>{Mobile_not_verified.content}</p>
                                <p className="linkcontent" onClick={ResendVerifyMobileAPI}>{Mobile_not_verified.linkContent}</p>
                            </div>}
                        {contentToDisplay?.email_mobile &&
                            <div>
                                <p >{not_verified.content}</p>
                                {emailClose && <p className="linkcontent" onClick={ResendVerifyEmailAPI}>{not_verified.linkContent}</p>}
                                {mobileClose && <p className="linkcontent" onClick={ResendVerifyMobileAPI}>{not_verified.MobileLinkContent}</p>}
                            </div>}
                    </div>}
            </div>
            {loader && <Loader />}
        </div>
    );
};