import React from "react";

import "./styles.scss"

import downBtn from "../../assets/icons/up-arrow.png"

type Tprop = {
    isOpen: boolean
    setIsOpen: (open: boolean) => void
    selectedOption: any
    setSelectedOption: (open: any) => void
    options: any
}

export const Dropdown:React.FC<Tprop> = ({isOpen ,setIsOpen, selectedOption, setSelectedOption, options}) => {
  const toggling = () => setIsOpen(!isOpen);

  const onOptionClicked = (value:any) => () => {
    setSelectedOption(value);
    setIsOpen(false);
  };

  return (
    <div className="main">
      <div className="drop-down-container">
        <div className="dropdown-top" onClick={toggling}>
            <div className="drop-down-header basis-3/4" >
            {selectedOption || "Choose"}
            </div>
            <button className="down-btn basis-1/4" >
                <img 
                src={downBtn} 
                alt="not-found"
                className={isOpen ? "turn-up": "down-btn-img"} 
                />
            </button>
        </div>
        
        {isOpen && (
          <div>
            <ul className="drop-down-list">
              {options.map((option:any) => (
                <li className="list-item" onClick={onOptionClicked(option)} key={Math.random()}>
                  {option}
                </li>
              ))}
            </ul>
          </div>
        )}
      </div >
    </div >
  );
}
