import React from "react";
import "./style.scss";
import completedIcom from "../../assets/icons/completed.png"
import closeIcon from "../../assets/icons/x-circle.svg"
import { useNavigate } from "react-router-dom";
import { setAuthData } from "../../redux/Actions/actions";
import { useDispatch } from "react-redux";

type Tprop = {
    show: boolean
    setShow: (open: boolean) => void
    message?: string
    status?: boolean
    logout?: boolean
    navigator?: string
}

export const SuccessDialogBox: React.FC<Tprop> = ({ show, message, logout, status, setShow, navigator }) => {

    const navigate = useNavigate()
    const dispatch = useDispatch();
    
    return (
        <div className="success-dialogbox">
            <div className="box">
                <div className="close">
                    <div className="dialog-btn-close" onClick={() => {
                        if (logout) {

                            dispatch(setAuthData(undefined));
                            localStorage.clear()
                            navigate("/login")
                        }
                        if (navigator) {
                            navigate(`/${navigator}`);
                        }
                        setShow(false)
                    }}>
                        <img src={closeIcon} alt="not-found" />
                    </div>
                </div>
                <img className="dialog-heading-icon" src={completedIcom} alt={"not found"} />
                <p className="text">{message}</p>
            </div>
        </div>
    );
};
