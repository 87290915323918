import React, { useState } from "react";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import "./styles.scss";
import DeleteIcon from "../../assets/icons/delete-ic.png";
import EditIcon from "../../assets/icons/edit.png";
import { FAQAddDialogBox, FAQDialogBox } from "../faqDialogBox";

type Tprop = {
    role?: string;
    title: string;
    description: string;
    faqID: number;
    setLoader?: any;
    apiRerender?: any;
};

export const FAQContainer: React.FC<Tprop> = ({ title , description , role , faqID , setLoader, apiRerender }) => {
    const [show, setShow] = useState<any>(false);
    const [showDialog, setshowDialog] = useState<boolean>(false);
    const [showEditDialog, setshowEdiDialog] = useState<boolean>(false);

    return (
        <div className={role === "admin" ? "faq-box-con admin-con" : "faq-box-con"}>
            <div className="faq-con">
                <h6 className="title" onClick={() => setShow(!show)}>
                    {title}{" "}
                    <KeyboardArrowDownIcon
                        style={
                            show
                                ? { transform: "rotate(180deg)" }
                                : { transform: "rotate(0deg)" }
                        }
                    />{" "}
                </h6>
                <h6
                    className="description"
                    style={
                        show
                            ? { height: "auto", display: "flex" }
                            : { height: "0px", display: "none" }
                    }>
                    {description}
                </h6>
            </div>
            {role === "admin" && (
                <div className={show ? "admin-tools active" : "admin-tools"}>
                    <img
                        className="delete-ic"
                        onClick={() => setshowDialog(true)}
                        src={DeleteIcon}
                        alt="not found"
                    />
                    <img
                        className="edit-ic"
                        onClick={() => setshowEdiDialog(true)}
                        src={EditIcon}
                        alt="not found"
                    />
                </div>
            )}
            {showDialog && (
                <FAQDialogBox
                    setShow={setshowDialog}
                    faqID={faqID}
                    setLoader={setLoader}
                    apiRerender={apiRerender}
                />
            )}
            {showEditDialog && (
                <FAQAddDialogBox
                    state={"edit"}
                    setShow={setshowEdiDialog}
                    editData={{ title: title, description: description }}
                    faqID={faqID}
                    setLoader={setLoader}
                    apiRerender={apiRerender}
                />
            )}
        </div>
    );
};
