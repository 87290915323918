import React from "react";
import "./styles.scss";
import closeIcon from "../../assets/icons/x-circle.svg"

type Tprop = {
    show: boolean
    setShow: (open: boolean) => void
    img: any
    videoSrc: any
    setImg: (img: any) => void
    setVideoSrc: (video: any) => void
}

export const ImgModalBox: React.FC<Tprop> = ({ show, setShow, img, videoSrc, setVideoSrc, setImg}) => {

    return (
        <div className="imgModal">
        <div className='modal-backdrop' onClick={() => {setShow(false); setVideoSrc(null); setImg(null)}}></div>
          <div className="modal">
            <div className="close">
              <div className="dialog-btn-close" onClick={() => { setShow(false); setVideoSrc(null); setImg(null) }}>
                <img src={ closeIcon } alt="not-found" />
              </div>
            </div>
            <div className="img-div">
           { videoSrc ? <video src={videoSrc} controls={true}/> : <img src={img} alt="Attachment not provided" />}
            </div>
          </div>
        </div>
    );
};