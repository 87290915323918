import React from "react";
import "./styles.scss";
import completedIcom from "../../assets/icons/completed.png"
import closeIcon from "../../assets/icons/x-circle.svg"
import { Link, useNavigate } from "react-router-dom";
import popUpImage from "../../assets/popUpImageInSignUp.svg"
import { AuthService } from "../../services/auth.service";
import { DIALOG_BOX_CONTENT } from "../../common/constant";

type Tprop = {
    status: string
    show: boolean
    setShow: (open: boolean) => void
    errorMessage?: string
    userId?: any
    email?: any
    sl?:boolean
    setResendPopup?:(open: boolean) => void
}

export const DialogBox: React.FC<Tprop> = ({ status, show, setShow, userId, errorMessage, email,sl,setResendPopup }) => {
    const authenticationService = new AuthService();
    const navigate = useNavigate()
    const close = () => {
        setShow(false);
        if (status === "waiting") {
            navigate(userId)
        }
    }

    const resendEmail= () =>{
        
        authenticationService.EmailResendVerification({ email })
        // setShow(false);
        // setResendPopup && setResendPopup(true);
        
    }

    return (
        <div className={`dialog-box ${status === "waiting" ? "wainting-dialog-box" : ""}`}>
            <div className="box">
                <div className="close">
                    <div className="dialog-btn-close" onClick={() => close()}>
                        <img src={closeIcon} alt="not-found" />
                    </div>
                </div>
                {status === "waiting" ? <div className="waiting-in-signup"><img className="pop-up-image" src={popUpImage} alt={"not dound"} /> </div> : status === "success" && <img className="dialog-heading-icon" src={completedIcom} alt={"not found"} />}
                <div className="chat-con">
                    <p className="text">
                        {status === "success" ? DIALOG_BOX_CONTENT.message3 :
                            status === "waiting" ?
                                <div>
                                    <p className="dialog-heading">{DIALOG_BOX_CONTENT.heading}</p>
                                    <div style={{ marginBottom: "10px" }} dangerouslySetInnerHTML={{ __html: DIALOG_BOX_CONTENT.message2 }}></div>
                                    <span>Didn't receive ? &nbsp;</span> <br />
                                    {!sl && <span className="linkcontent" onClick={() => {resendEmail() }}>Resend E-mail</span>}<br className="breakELe" />
                                    <span className="linkcontent" onClick={() => { authenticationService.MobileResendVerification({ email }) }}>Resend SMS</span>
                                </div>
                                :
                                status === "error" ? errorMessage || DIALOG_BOX_CONTENT.message1 : "something went wrong"
                        }

                        {status === "success" ? <Link to="/account" className="dialog-link">{DIALOG_BOX_CONTENT.link2}</Link>
                            :
                            status === "waiting" ? <button onClick={() => {
                                navigate('/login', { state: { qrUserId: userId } })
                            }}><p className="dialog-link" >{DIALOG_BOX_CONTENT.link1}</p></button>
                                : ""}
                    </p>
                </div>

                {status === "error" && <div className="dialog-btn" onClick={() => { setShow(false) }}>OK</div>}
            </div>
        </div>
    );
};