import * as Yup from 'yup'
import { emailRegex } from '../../common/constant'

export const editProfileSchema = (profileData: any) => {
  const data = {
    // name: "",
    // zipcode: ""
    name: `${profileData?.hero_name}`,
    license_plate: `${profileData?.license_plate}`,
    phone: `${profileData?.phone_number}`,
    email: `${profileData?.email}`,
    zipcode: `${profileData?.zip_code}`
  }
  return data
}

const heroNameRegex = /^\S*$/

export const profileValidater = {

  PROFILEVALIDATER: Yup.object().shape({
    name: Yup.string().trim().required('Please enter your name').matches(heroNameRegex, "Please don't enter space").max(30, "Name must be maximum 30 characters").min(3, "Name must be minimum 3 characters "),
    zipcode: Yup.string().trim().required('Please enter zipcode').min(5, "Zip code length must be greater than 4"),
    phone: Yup.string().trim().required('Please enter mobile number').max(12, "Please enter valid number ").min(12, "Please enter valid number "),
    license_plate: Yup.string().trim().min(7, 'Need at least 7 characters'),
    email: Yup.string().required("Please enter valid email").lowercase().max(100, "can exceed more than 100 characters")
      .test('test-name', 'Please enter valid email',
        function (value: any) {
          let isValidEmail = emailRegex.test(value);
          if (!isValidEmail) {
            return false;
          }
          return true;
        }),
  }),
}
