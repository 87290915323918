import React from 'react'
import styles from "./styles.module.scss";

type textFieldProps = {
  label?: string,
  formikNumber?: any,
  formikCode?: any,
  errorText?: any
  error?: any,
  showTick?: boolean,
  hint?: string,
} & React.InputHTMLAttributes<HTMLInputElement>

export const MobileField: React.FC<textFieldProps> = ({ label, type, formikNumber, formikCode, error, hint, errorText, showTick, ...props }) => {
  return (
    <div className={styles.textField}>
      <div className={styles.textFieldContainer}>
        {label && <h4 className={styles.lableField}>{label}</h4>}
        <div className={styles.inputWrap}>
          <div className={styles.mobileInput}>
            <input type={type} disabled {...props} {...formikCode} className={styles.CountryCode} />
            <input type="number" {...props} {...formikNumber} className={styles.inputField} />
          </div>
          {error && <p className={styles.error}>{errorText}</p>}
        </div>
      </div>
    </div>
  )
}
